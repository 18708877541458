import seremyFire from 'seremy-firebase-data';
import firebase from "firebase/app";

/**
 * Richiama una function per creare un numero di ordine univoco
 * @returns {Promise<Number>} promise operazione creazione numero ordine
 */
export const generateUniqueOrderNumber = () => {
  const httpsCallable = seremyFire.firebase
    .app()
    .functions('europe-west1')
    .httpsCallable('getUniqueOrderNumber');
  return httpsCallable();
};

/**
 * Richiama una function per l'esecuzione del pagamento
 * @param {String} accountId id dell'account su cui eseguire il pagamento
 * @param {String} orderId id dell'ordine da pagare
 * @param {Boolean} useNewCard true se richiesto di utilizzare una nuova carta
 * @returns {Promise<Object>} promise operazione di pagamento. Ritorna un oggetto indicante se
 * l'operazione ha avuto successo: es { success: false, error: { code "invalid", message?: "Messaggio"}, clientSecret: String}
 */
export const createOrderPaymentIntent = (accountId, orderId, useNewCard) => {
  const httpsCallable = seremyFire.firebase
    .app()
    .functions('europe-west1')
    .httpsCallable('createOrderPaymentIntent');
  return httpsCallable({
    accountId,
    orderId,
    useNewCard,
  });
};

//NEW CALLABLE

export const addProducts = (orderId, products) => {
  const httpsCallable = seremyFire.firebase
    .app()
    .functions('europe-west1')
    .httpsCallable('order-addProducts');
  return httpsCallable({
    order: orderId,
    products
  });
};

/**

 */
export const edit = (orderId, checkout, products, recipient, referral) => {
  const httpsCallable = firebase.app()
    .functions("europe-west1")
    .httpsCallable('order-edit');
  let params = {
    order: orderId,
    checkout: checkout,
    from: "web",
    referral: referral
  }
  if(products)  params = {...params, products}
  if(recipient)  params = {...params, recipient}
console.log("PARAMS", params)
  return httpsCallable(params);
};

export const close = (orderId) => {
  const httpsCallable = firebase.app()
    .functions("europe-west1")
    .httpsCallable('order-close');

  return httpsCallable({});

};

export const setDuration = (orderId, duration) => {
  const httpsCallable = seremyFire.firebase
    .app()
    .functions('europe-west1')
    .httpsCallable('order-setDuration');
  return httpsCallable({
    duration,
    order: orderId
  });
};

export const setProducts = (orderId, products) => {
  const httpsCallable = seremyFire.firebase
    .app()
    .functions('europe-west1')
    .httpsCallable('order-setProducts');
  return httpsCallable({
    order: orderId,
    products
  });
};

export const stepOrder = (step, orderId) => {
  console.log("CALL_STEP_TEST", orderId);
  /*const httpsCallable = seremyFire.firebase
    .app()
    .functions('europe-west1')
    .httpsCallable('order-step')
    console.log("CALLABLE", httpsCallable);*/
    const httpsCallable = firebase.app().functions("europe-west1").httpsCallable('order-step')

  return httpsCallable({
    "step": step,
    "order": orderId,
    "from":"web"
  }).then((result) => {
    console.log("res:",result.data.output);
  }).catch((error) => {
    console.log(`error: ${JSON.stringify(error.message)}`);
  });
};

export const setShippingAddress = (orderId, recipient) => {
  const httpsCallable = seremyFire.firebase
    .app()
    .functions('europe-west1')
    .httpsCallable('order-shippingAddress');
  return httpsCallable({
    order: orderId,
    recipient
  });
};
