import {
	REQUEST_FETCH_USER_DATA,
	RECEIVE_FETCH_USER_DATA,
	ERROR_FETCH_USER_DATA,
	RESET_USER_DATA,
	UPDATE_STORED_USER_DATA,
} from "../actions/types";

import Logger from "utils/logger";
import { currentUser } from "firebaseManager";

const initialState = {
	isFetching: false,
	user: null, // Oggetto DocumentSnapshot
	account: null,
	error: null,
};

const userData = (state = initialState, action) => {
	switch (action.type) {
		case REQUEST_FETCH_USER_DATA:
			return {
				...state,
				isFetching: true,
				user: null,
				account: null,
				error: null,
			};
		case RECEIVE_FETCH_USER_DATA:
			return {
				...state,
				isFetching: false,
				user: action.user,
				account: action.account,
				error: null,
			};
		case ERROR_FETCH_USER_DATA:
			const firebaseUser = currentUser();
			Logger.error(
				Logger.code.USER_DATA_ACTIONS_GET_USER,
				action.error,
				firebaseUser ? firebaseUser.uid : 0
			);
			return {
				...state,
				isFetching: false,
				user: null,
				account: null,
				error: action.error,
			};
		case UPDATE_STORED_USER_DATA:
			return {
				...state,
				account: action.account || state.account,
				user: action.user || state.user,
			};
		case RESET_USER_DATA:
			return initialState;
		default:
			return state;
	}
};

export default userData;
