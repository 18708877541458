import {
	getResources,
	getResource,
	updateResource,
	addResource,
	deleteResource,
	unassignWearableFromResource,
	getResourceGeofences,
	getResourcesQuery,
} from "firebaseManager";

import { getAllAlarms } from "./alarmsActions";
import { getAlarmsSnapshot } from "actions/alarmsActions";
import { getWearableDevicesSnapshot } from "actions/wearableDeviceActions";
// import { saveSelectedResourceId, getSelectedResourceId } from '../local';

import Logger from "utils/logger";
import { isNullOrUndefined, isEmptyObjectOrNaN } from "utils/common";
import Strings from "styles/strings";
import {
	REQUEST_FETCH_RESOURCES,
	RECEIVE_FETCH_RESOURCES,
	ERROR_FETCH_RESOURCES,
	RESET_RESOURCES,
	SET_SELECTED_RESOURCE,
	UPDATE_STORED_RESOURCE,
	ADD_STORED_RESOURCE,
	DELETE_STORED_RESOURCE,
	RECEIVE_RESOURCES_INDICATORS,
	SET_SELECTED_EDIT_RESOURCE,
	CLEAR_SELECTED_EDIT_RESOURCE,
} from "./types";

var moment = require("moment");

const clearSelectedEditResourceAction = () => ({
	type: CLEAR_SELECTED_EDIT_RESOURCE,
});

const setSelectedEditResourceAction = (resource) => ({
	type: SET_SELECTED_EDIT_RESOURCE,
	resource,
});

export const setSelectedEditResource = (resource) => {
	return setSelectedEditResourceAction(resource);
};

export const clearSelectedEditResource = () => {
	return clearSelectedEditResourceAction();
};

const requestFetchResources = () => ({
	type: REQUEST_FETCH_RESOURCES,
});

const receiveFetchResources = (resources, selectedId) => ({
	type: RECEIVE_FETCH_RESOURCES,
	resources,
	selectedId,
});

const receiveResourcesIndicators = (
	indicators,
	batteryStatuses,
	proximitiesDaily
) => ({
	type: RECEIVE_RESOURCES_INDICATORS,
	indicators,
	batteryStatuses,
	proximitiesDaily,
});

const errorFetchResources = (error) => ({
	type: ERROR_FETCH_RESOURCES,
	error,
});

export const setSelectedResource = (resource) => ({
	type: SET_SELECTED_RESOURCE,
	resource,
});

export const resetResources = () => ({
	type: RESET_RESOURCES,
});

export const updateStoredResource = (resource) => ({
	type: UPDATE_STORED_RESOURCE,
	resource,
});

export const addStoredResource = (resource) => ({
	type: ADD_STORED_RESOURCE,
	resource,
});

export const deleteStoredResource = (resource) => ({
	type: DELETE_STORED_RESOURCE,
	resource,
});

export const getResourcesSnapshot = async (dispatch, account, getState) => {
	const query = getResourcesQuery(account);
	const res = query.onSnapshot(
		async (querySnapshot) => {
			const currentResources = getState().resources.resources;
			const user = getState().userData.user;
			const visibleResources = await user.ref
				.collection("visibleResources")
				.get();
			var filteredResources = querySnapshot.docs;
			if (
				visibleResources &&
				!visibleResources.empty &&
				visibleResources.docs &&
				visibleResources.docs.length > 0 &&
				user.data().accountRole == "guest"
			) {
				filteredResources = filterResources(
					querySnapshot.docs,
					visibleResources
				);
			}
			if (filteredResources) {
				if (filteredResources.length == currentResources.length) {
					filteredResources.forEach((element) => {
						dispatch(updateStoredResource(element));
					});
				} else {
					dispatch(fetchResources());
				}
			}
		},
		function (error) {
			//...
		}
	);
};

const filterResources = (resources, visibleResources) => {
	var filteredResources = [];
	resources.map(async (element) => {
		visibleResources.docs.map((resource) => {
			if (resource.data().resource.path === element.ref.path) {
				filteredResources.push(element);
			}
		});
	});
	return filteredResources;
};
/**
 * Legge la lista delle risorse associate all'account dell'utente
 * salvando il risulatato sullo store di redux
 */
export const fetchResources = () => async (dispatch, getState) => {
	const { user, account } = getState().userData;
	dispatch(requestFetchResources());
	if (
		user === null ||
		user === undefined ||
		account === null ||
		account === undefined
	) {
		dispatch(errorFetchResources(Strings().messageInvalidAuth));
		return;
	}
	try {
		const limit = 30;
		var fetchedResources = await getResources(account);
		const visibleResources = await user.ref
			.collection("visibleResources")
			.get();
		var filteredResources = fetchedResources.docs;
		if (
			visibleResources &&
			!visibleResources.empty &&
			visibleResources.docs &&
			visibleResources.docs.length > 0 &&
			user.data().accountRole == "guest"
		) {
			filteredResources = filterResources(
				fetchedResources.docs,
				visibleResources
			);
		}

		//FILTRO!!

		let selectedId = "";
		dispatch(getAllAlarms(dispatch, filteredResources));
		getAlarmsSnapshot(dispatch, filteredResources);
		getWearableDevicesSnapshot(dispatch, account);

		if (filteredResources && filteredResources.length > 0) {
			var resourcesIndicators = [];
			var deviceBatteryStatuses = [];
			var proximitiesDaily = [];
			for (var i = 0; i < filteredResources.length; i++) {
				const doc = filteredResources[i];
				const indicatorValuesDocs = await doc.ref
					.collection("indicatorValues")
					.where("day", ">=", moment().subtract(limit, "days").toDate())
					.limit(1)
					.orderBy("day", "desc")
					.get();
				if (indicatorValuesDocs && indicatorValuesDocs.docs[0]) {
					var indicatorValues = indicatorValuesDocs.docs[0].data();
					indicatorValues.resourceId = doc.id;
					resourcesIndicators.push(indicatorValues);
				}

				const deviceBatteryStatusDocs = await doc.ref
					.collection("deviceBatteryStatus")
					.where("timestamp", ">=", moment().subtract(limit, "days").toDate())
					.limit(1)
					.orderBy("timestamp", "desc")
					.get();
				if (deviceBatteryStatusDocs && deviceBatteryStatusDocs.docs[0]) {
					var deviceBatteryStatus = deviceBatteryStatusDocs.docs[0].data();
					deviceBatteryStatus.resourceId = doc.id;
					deviceBatteryStatuses.push(deviceBatteryStatus);
				}

				const proximitiesDailyDocs = await doc.ref
					.collection("proximitiesDaily")
					.where("day", ">=", moment().subtract(limit, "days").toDate())
					.where("day", "<=", moment().endOf("day").toDate())
					.limit(1)
					.orderBy("day", "desc")
					.get();
				if (proximitiesDailyDocs && proximitiesDailyDocs.docs[0]) {
					var proximity = proximitiesDailyDocs.docs[0].data();
					proximity.resourceId = doc.id;
					proximitiesDaily.push(proximity);
				}
			}
			dispatch(
				receiveResourcesIndicators(
					resourcesIndicators,
					deviceBatteryStatuses,
					proximitiesDaily
				)
			);
		}
		dispatch(receiveFetchResources(filteredResources, selectedId));
	} catch (e) {
		Logger.error(
			Logger.code.RESOURCES_ACTIONS_FETCH_RESOURCES,
			e.message,
			user.id,
			e
		);
		// dispatch(
		// 	errorFetchResources(Strings().messageErrorFetchResources + user.id)
		// );
	}
};

/**
 * Imposta una risorsa come selezionata
 * @param {DocumentSnapshot} resource risorsa
 */
export const selectResource = (resource) => async (dispatch) => {
	try {
		// await saveSelectedResourceId(resource.id);
	} catch (e) {
		// ignoro il possibile errore in quanto non è un salvataggio importante
	}
	dispatch(setSelectedResource(resource));
};

/**
 * Legge le informazioni di una risorsa
 * @param {String} resourceId id risorsa
 * @return {Promise<DocumentSnapshot>} promise oggetto risorsa
 */
export const fetchResource = (resourceId) => async (dispatch, getState) => {
	const { account } = getState().userData;
	getResource(account, resourceId);
};

/**
 * Aggiorna le informazioni della risorsa e ritorna il documento aggionato
 * @param {DocumentSnapshot} account account
 * @param {DocumentSnapshot} resource oggetto wrapper della risorsa
 * @param {Object} fields oggetto con i campi della risorsa
 * @returns {Promise<DocumentSnapshot>} promise della risorsa aggiornata
 */
export const updateResourceDataAsync = async (account, resource, fields) => {
	await updateResource(resource, fields);
	return getResource(account, resource.id);
};

/**
 * Aggiunge all'account una risorsa e ritorna il documento appena creato
 * @param {DocumentSnapshot} account account
 * @param {String} name nome
 * @param {String} address indirizzo
 * @param {String} phoneNumber numero di telefono
 * @param {String} pictureUrl url dell'immagine profilo
 * @returns {Promise<DocumentSnapshot>} promise della risorsa creata
 */
export const addResourceAsync = async (account, fields) => {
	const docRef = await addResource(account, fields);
	return getResource(account, docRef.id);
};

/**
 * Determina se è possibile modificare la frequenza di aggiornamento
 * (se ci sono geofence settate e se non è ancora stata settata)
 * @param {DocumentSnapshot} resource risorsa
 * @returns {Boolean}
 */
export const shouldDisablePositionFrequencyEdit = async (resource) => {
	return false;
	/*
	// il primo set è consentito anche con geofence già impostate
	if (isNullOrUndefined(resource.get("positionUpdateFrequency"))) {
		return false;
	}
	const resourceGeofences = await getResourceGeofences(resource);
	let geofenceSet = false;
	resourceGeofences.forEach((geofence) => {
		if (!isEmptyObjectOrNaN(geofence.data())) {
			geofenceSet = true;
		}
	});
	return geofenceSet;
	*/
};

/**
 * Esegue l'operazione di upload dell'immagine di profilo della risorsa
 * Viene creato il nome dell'immagine che viene caricata
 * @param {Object} resourceWrapper oggetto wrapper della risorsa
 * @param {String} picturePath percorso dell'immagine da caricare
 * @param {Function} onStateChange callback cambiamento di stato upload.
 * Viene passato come parametro un oggetto snapshot
 * @param {Function} onSuccess callback caricamento completato.
 * Viene passato come parametro il file caricamto
 * @param {Function} onError callback errore caricamento. Viene passato come parametro l'errore
 * @returns {Function} funzione per eseguire l'unsubscribe dell'operazione
 */
// export const uploadResourcePicture = (
//   resourceWrapper,
//   picturePath,
//   onStateChange,
//   onSuccess,
//   onError,
// ) => {
//   const extenstion = picturePath.split('.').pop();
//   const pictureName = `${resourceWrapper.id}-${new Date().getTime()}.${extenstion}`;
//   return listenUploadResourcePicture(pictureName, picturePath,
//    onStateChange, onSuccess, onError);
// };

/**
 * Cancella l'immagine di una risorsa
 * @param {String} url url dell'immagine
 * @returns {Promise<Void>} promise cancellazione
 */
// export const deleteResourcePicture = (url) => deletePicture(url);

/**
 * Cancella una risorsa e, se necessario, rimuove l'associazione tra
 * dispositivo e risorsa stessa
 * @param {DocumentSnapshot} resource risorsa
 * @param {DocumentSnapshot} wearableDevice dispositivo assegnato alla risorsa (se presente)
 * @returns {Promise<Void|DocumentSnapshot>} promise cancellazione. Se viene indicato il dispositivo
 * di cui rimuovere l'associazione ritorna il dispositivo stesso aggiornato
 */
export const deleteResourceAndUnassignWearableDeviceAsync = async (
	resource,
	wearableDevice = undefined
) => {
	let updatedWearableDevice;
	if (wearableDevice) {
		await unassignWearableFromResource(wearableDevice);
		updatedWearableDevice = await wearableDevice.ref.get();
	}
	await deleteResource(resource);
	return updatedWearableDevice;
};
