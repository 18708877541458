import {
	REQUEST_FETCH_ALL_PAYMENTS,
	RECEIVE_FETCH_ALL_PAYMENTS,
	ERROR_FETCH_ALL_PAYMENTS,
	SET_SELECTED_PAYMENTS,
	CLEAR_SELECTED_PAYMENTS,
	CLEAR_ALL_PAYMENTS,
} from "../actions/types";

const initialState = {
	payments: [],
	selectedPayment: null,
	isFetching: false,
	error: null,
};

const beacons = (
	state = Object.assign({}, initialState, { error: false }),
	action
) => {
	switch (action.type) {
		case SET_SELECTED_PAYMENTS: {
			return {
				...state,
				selectedPayment: action.payment,
			};
		}
		case CLEAR_ALL_PAYMENTS: {
			return initialState;
		}
		case CLEAR_SELECTED_PAYMENTS: {
			return {
				...state,
				selectedPayment: null,
			};
		}
		case REQUEST_FETCH_ALL_PAYMENTS:
			return {
				...state,
				payments: [],
				isFetching: true,
				error: null,
			};
		case RECEIVE_FETCH_ALL_PAYMENTS:
			var selectedPayment = state.selectedPayment;
			if (state.selectedPayment) {
				action.payments.docs.forEach((obj) => {
					if (obj.id == selectedPayment.id) {
						selectedPayment = obj;
					}
				});
			}
			return {
				...state,
				selectedPayment: selectedPayment,
				payments: action.payments,
				isFetching: false,
				error: null,
			};
		case ERROR_FETCH_ALL_PAYMENTS:
			return {
				...state,
				isFetching: false,
				error: action.error,
				payments: null,
			};
		default:
			return state;
	}
};

export default beacons;
