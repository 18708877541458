import seremyFire from 'seremy-firebase-data';
/**
 * Richiama la funzione per leggere le informazioni della carta
 * inserita dall'utente legata all'account
 * @param {String} customerId id del customer di stripe
 * @returns {Promise<Object>} promise operazione lettura info della carta
 */
export const fetchCustomerCard = (customerId) => {
  const httpsCallable = seremyFire.firebase
    .app()
    .functions('europe-west1')
    .httpsCallable('fetchCustomerCard');
  return httpsCallable({
    customerId,
  });
};

/**
 * Salva il metodo di pagamento del customer legato all'account
 * dell'utente
 * @param {String} accountId id del account
 * @param {String} paymentMethodId id metodo di pagamento inserito dall'utente
 * @returns {Promise<Object>} promise operazione di sostituzione della carta. Ritorna le info della carta aggiunto
 */
export const saveCustomerCard = (accountId, paymentMethodId) => {
  const httpsCallable = seremyFire.firebase
    .app()
    .functions('europe-west1')
    .httpsCallable('saveCustomerCard');
  return httpsCallable({
    accountId,
    paymentMethodId,
  });
};
