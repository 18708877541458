import React from "react";
import moment from "moment";
import "moment/locale/it";

import Strings from "styles/strings";

/**
 * Formatta una data
 * @param {Date} date data da formattare
 * @param {String} format template del formatto
 * @return data formattata
 */
export const secondsToWeek = (seconds) => {
	if (seconds) {
		//		moment().locale("it");
		return moment.unix(seconds).format("W");
	}
	return undefined;
};

/**
 * Formatta una data
 * @param {Date} date data da formattare
 * @param {String} format template del formatto
 * @return data formattata
 */
export const secondsToDateShort = (seconds) => {
	if (seconds) {
		//		moment().locale("it");
		return moment.unix(seconds).format("DD/MM");
	}
	return undefined;
};
/**
 * Formatta una data
 * @param {Date} date data da formattare
 * @param {String} format template del formatto
 * @return data formattata
 */
export const secondsToDate = (seconds) => {
	if (seconds) {
		//		moment().locale("it");
		return moment.unix(seconds).format("DD/MM/YYYY");
	}
	return undefined;
};

export const secondsToDateTime = (seconds) => {
	if (seconds) {
		//		moment().locale("it");
		return moment.unix(seconds).format("DD/MM/YYYY HH:mm");
	}
	return undefined;
};

export const secondsToDateIso = (seconds) => {
	if (seconds) {
		//		moment().locale("it");
		return moment.unix(seconds).format("YYYY-MM-DD");
	}
	return undefined;
};

/**
 * Formatta una data
 * @param {Date} date data da formattare
 * @param {String} format template del formatto
 * @return data formattata
 */
export const formatDate = (date, format = "HH:mm DD MMM YYYY") => {
	if (date) {
		moment().locale("it");
		return moment(date).format(format);
	}
	return undefined;
};

/**
 * Formatta una data nel seguente template:
 * es: 13:45 del 11 Gen 2019
 * @param {Date} date data da formattare
 */
export const hourDateFormat = (date) => {
	if (date) {
		return `${formatDate(date, "HH:mm")} ${Strings().labelDateOf} ${formatDate(
			date,
			"DD MMM YYYY"
		)}`;
	}
	return undefined;
};

/**
 * Formatta una data nel seguente template:
 * es: 11 Gen 2019 alle 13:45
 * @param {Date} date data da formattare
 */
export const dateHourFormat = (date) => {
	if (date) {
		return `${formatDate(date, "DD MMM YYYY")} ${
			Strings().labelDateAt
		} ${formatDate(date, "HH:mm")}`;
	}
	return undefined;
};

/**
 * Aggiunge alla data un numero indicato di giorni
 * @param {Date} date data
 * @param {Number} days numero di giorni da aggiungere (Numero negativo per rimuovere)
 * @returns {Date} data modificata
 */
export const addDays = (date, days) => moment(date).add(days, "days").toDate();

/**
 * Aggiunge alla data un numero indicato di minuti
 * @param {Date} date data
 * @param {Number} numDays numero di minuti da aggiungere (Numero negativo per rimuovere)
 * @returns {Date} data modificata
 */
export const addMinutes = (date, minutes) =>
	moment(date).add(minutes, "minutes").toDate();

/**
 * Verifica se 2 date sono lo stesso giorno
 * @param {Date} date1 data 1
 * @param {Date} date2 data 2
 * @returns {Boolean} true se date stesso giorno
 */
export const sameDay = (date1, date2) =>
	moment(date1).isSame(moment(date2), "day") &&
	moment(date1).isSame(moment(date2), "month") &&
	moment(date1).isSame(moment(date2), "year");

/**
 * Verifica se una data è il giorno corrente
 * @param {Date} date data da verificare
 * @returns {Boolean} true se data corrente
 */
export const isToday = (date) => {
	const momentDate = moment(date);
	const today = new Date();
	return (
		momentDate.isSame(today, "day") &&
		momentDate.isSame(today, "month") &&
		momentDate.isSame(today, "year")
	);
};

/**
 * Verifica se una data corrisponde al giorno precedente
 * a quello corrente
 * @param {Date} date data da verificare
 * @returns {Boolean} true se la data uguale al giorno precedente
 */
export const isYesterday = (date) => {
	const momentDate = moment(date);
	const yesterday = addDays(new Date(), -1);
	return (
		momentDate.isSame(yesterday, "day") &&
		momentDate.isSame(yesterday, "month") &&
		momentDate.isSame(yesterday, "year")
	);
};

/**
 * Verifica se un data è compresta tra due date limiti
 * @param {Date} date data da verificare
 * @param {Date} dateMin data come limite inferiore
 * @param {Date} dateMax data come limite superiore
 * @returns {Boolean} true data compresa tra intervallo
 */
export const dateBetween = (date, dateMin, dateMax) => {
	const momentDate = moment(date);
	const momentDateMin = moment(dateMin);
	const momentDateMax = moment(dateMax);
	return (
		momentDate.isSameOrAfter(momentDateMin) &&
		momentDate.isSameOrBefore(momentDateMax)
	);
};

/**
 * Verifica se una data è precedente a o uguale alla seconda data
 * @param {Date} date1 data da verificare
 * @param {Date} date2 data come limite superiore
 * @returns {Boolean} true data precedente alla seconda data
 */
export const dateSameOrBefore = (date1, date2) => {
	const momentDate1 = moment(date1);
	const momentDate2 = moment(date2);
	return momentDate1.isSameOrBefore(momentDate2);
};

/**
 * Formatta la data in una stringa
 * es Oggi alle 15:00
 * @param {Date} date data da formattare
 */
export const readableDate = (date) => {
	if (date) {
		const hour = formatDate(date, "HH:mm");
		let fullDate;
		if (isToday(date)) {
			fullDate = Strings().labelToday;
		} else if (isYesterday(date)) {
			fullDate = Strings().labelYesterday;
		} else {
			fullDate = formatDate(date, "DD MMM YYYY");
		}
		return `${fullDate} ${Strings().labelDateAt} ${hour}`;
	}
	return "";
};

/**
 * Determina se una variabile è assegnata
 * @param {Any} value valore
 * @returns {Boolean} true se non assegnata
 */
export const isNullOrUndefined = (value) =>
	value === undefined || value === null;

export const isNullOrUndefinedOrEmpty = (value) =>
	value === undefined || value === null || value === "";

/**
 * Determina se un oggetto è vuoto o non assegnato
 * @param {Object} obj oggetto
 * @returns {Boolean} true se oggetto vuoto o non assegnato
 */
export const isEmptyObjectOrNaN = (obj) =>
	isNullOrUndefined(obj) ||
	(Object.keys(obj).length === 0 && obj.constructor === Object);

/**
 * Arrotonda un numero a massimo 2 cifre decimali
 * @param {Number} num numero
 * @returns {String} stringa numero arrotondato
 */
export const roundToTwoDecimals = (num) => +`${Math.round(`${num}e+2`)}e-2`;

/**
 * Ritorna la stringa indicata in cui vengono sostituite le chiavi
 * segnaposto con i valori indicati.
 * es. utilizzo stringTemplate`testo con ${0}'('segnaposto')
 * nota: sostituire carettere ' con `
 * @param {String} strings
 * @param  {...any} keys chiavi
 * @returns testo completo
 */
export const stringTemplate = (strings, ...keys) => (...values) => {
	const dict = values[values.length - 1] || {};
	const result = [strings[0]];
	keys.forEach((key, i) => {
		const value = Number.isInteger(key) ? values[key] : dict[key];
		result.push(value, strings[i + 1]);
	});
	return result.join("");
};

export const inArray = (needle, haystack) => {
	var length = haystack.length;
	for (var i = 0; i < length; i++) {
		if (haystack[i] == needle) return true;
	}
	return false;
};

export function useToggle(initialValue = false) {
	const [value, setValue] = React.useState(initialValue);
	const toggle = React.useCallback(() => {
		setValue((v) => !v);
	}, []);
	return [value, toggle];
}

export function simulateMouseClick(element) {
	const mouseClickEvents = ["mousedown", "click", "mouseup"];
	if (element) {
		mouseClickEvents.forEach((mouseEventType) =>
			element.dispatchEvent(
				new MouseEvent(mouseEventType, {
					view: window,
					bubbles: true,
					cancelable: true,
					buttons: 1,
				})
			)
		);
	}
}
