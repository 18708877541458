import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import seremyFire from "seremy-firebase-data";

const firebaseConfig =
	process.env.REACT_APP_FIREBASE_PROJECT === "dev"
		? // false
		  // ? //		? // true

		  {
				apiKey: "AIzaSyD8pILWxKJXzIOJB1rEk0LFKvMSCanQmIs",
				authDomain: "seremy-dev-eu.firebaseapp.com",
				databaseURL: "https://seremy-dev-eu.firebaseio.com",
				projectId: "seremy-dev-eu",
				storageBucket: "seremy-dev-eu.appspot.com",
				messagingSenderId: "826177077201",
				appId: "1:826177077201:web:b43f31afc0057bf3",
		  }
		: {
				apiKey: "AIzaSyCY6ZOYVlQxVM80FytHg_DWfP8RQCM60k8",
				authDomain: "seremy-pro.firebaseapp.com",
				databaseURL: "https://seremy-pro.firebaseio.com",
				projectId: "seremy-pro",
				storageBucket: "seremy-pro.appspot.com",
				messagingSenderId: "729229490055",
				appId: "1:729229490055:web:d1aa5783820c53282c8522",
		  };

firebase.initializeApp(firebaseConfig);
firebase.auth().languageCode = "it";
seremyFire.init(firebase);

export const {
	linkUserWithEmailProviderAsync,
	signInWithEmailAndPassword,
	onAuthStateChanged,
	currentUser,
	signOut,
	sendPasswordResetEmail,
} = seremyFire.auth;
export { registerUser, signinWithPhoneNumber, differentGetUser } from "./auth";

export const { getAccount, updateAccount } = seremyFire.account;
export { fetchCustomerCard, saveCustomerCard } from "./account";

export const { updateUser, getUser, getUserByRef, isGuest, removeGuestUser } = seremyFire.user;

export const {
	getResources,
	getResource,
	updateResource,
	addResource,
	deleteResource,
	getResourcesQuery,
} = seremyFire.resource;

export const {
	getWearableDevices,
	assignWearableToResource,
	unassignWearableFromResource,
} = seremyFire.wearable;

export const {
	getIndicatorDefs,
	getLatestIndicatorValues,
	getDayStepsPerHour,
	getStepsPerDay,
	getBpmsPerDay,
	getSleepTimePerDay,
} = seremyFire.indicators;

export const { getProducts } = seremyFire.product;

export const { getResourceGeofences } = seremyFire.geofence;

export const {
	getResourceIndoorGeofencesQuery,
	getResourceIndoorGeofences,
	deleteGeofence,
	addGeofence,
	updateGeofence,
	getAccountBeacons,
} = seremyFire.indoorGeofence;

export const {
	getOrdersQuery,
	getOrders,
	getOrder,
	getLastDraftOrder,
	getLastCompetedOrder,
	addOrder,
	updateOrder,
	abortOrder,
	getCartConfiguration,
} = seremyFire.order;
export { generateUniqueOrderNumber, createOrderPaymentIntent } from "./order";

export const { getSubscriptionsQuery, getSubscriptions, getSubscription } =
	seremyFire.subscription;

export { createSetupIntent } from "./payment";

export { saveLog } from "./log";

export const {
	getPendingInvitationForUser,
	acceptInvitation,
	refuseInvitation,
	getInvitationsByPhoneNumber,
	canSendInvitationsToPhoneNumberAsync,
	canSendInvitationsAsync,
} = seremyFire.invitation;
