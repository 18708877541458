/* eslint react/no-children-prop: 0 */

import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { isNullOrUndefined } from "utils/common";
import { Item, Link, Text, Icon, IconSecondary } from "./style";
import Badge from "@material-ui/core/Badge";

const TYPE_LINK = "link";
const TYPE_BUTTON = "button";
const TYPE_EXTERNAL_URL = "external_url";

const ItemComponent = ({
	text,
	icon: I,
	to,
	activeOnlyWhenExact,
	overrideSelectedColor,
	onClick,
	type,
	iconSecondary: IS,
	notifications,
	allActiveAlarms,
	badgeValue,
	showBadge,
}) => {
	switch (type) {
		case TYPE_LINK:
			return (
				<Route
					path={to}
					exact={activeOnlyWhenExact}
					children={({ match, history }) => (
						<Item
							button
							key={text}
							onClick={() => {
								onClick();
								setTimeout(() => history.push(to), 300);
							}}
							className={match ? "selected" : ""}
						>
							{I &&
								(showBadge ? (
									<Badge badgeContent={badgeValue} color="secondary">
										<Icon
											className={
												overrideSelectedColor.icon
													? "overrideselectedcolor"
													: ""
											}
										>
											{<I />}
										</Icon>
									</Badge>
								) : (
									<Icon
										className={
											overrideSelectedColor.icon ? "overrideselectedcolor" : ""
										}
									>
										{<I />}
									</Icon>
								))}
							<Text
								className={
									overrideSelectedColor.text ? "overrideselectedcolor" : ""
								}
								primary={text}
							/>
							{IS && <IconSecondary>{<IS />}</IconSecondary>}
						</Item>
					)}
				/>
			);
		case TYPE_EXTERNAL_URL:
			return (
				<Link href={to} target="_blanck">
					<Item
						button
						key={text}
						onClick={() => {
							onClick();
						}}
					>
						{I && (
							<Icon
								className={
									overrideSelectedColor.icon ? "overrideselectedcolor" : ""
								}
							>
								{<I />}
							</Icon>
						)}
						<Text
							className={
								overrideSelectedColor.text ? "overrideselectedcolor" : ""
							}
							primary={text}
						/>
						{IS && <IconSecondary>{<IS />}</IconSecondary>}
					</Item>
				</Link>
			);
		case TYPE_BUTTON:
			return (
				<Item
					button
					key={text}
					onClick={() => {
						onClick();
					}}
				>
					{I && (
						<Icon
							className={
								overrideSelectedColor.icon ? "overrideselectedcolor" : ""
							}
						>
							{<I />}
						</Icon>
					)}
					<Text
						className={
							overrideSelectedColor.text ? "overrideselectedcolor" : ""
						}
						primary={text}
					/>
					{IS && <IconSecondary>{<IS />}</IconSecondary>}
				</Item>
			);
		default:
			return null;
	}
};

ItemComponent.propTypes = {
	text: PropTypes.string.isRequired,
	icon: PropTypes.shape(),
	// eslint-disable-next-line
	to: (props, propName, componentName) => {
		if (
			(props[propName] === TYPE_LINK ||
				props[propName] === TYPE_EXTERNAL_URL) &&
			(isNullOrUndefined(props[propName]) || props[propName] === "")
		) {
			return new Error(
				`Invalid prop \`${propName}\` supplied to` +
					` \`${componentName}\`. Items of type "link" should have set the "to" prop`
			);
		}
	},
	activeOnlyWhenExact: PropTypes.bool,
	overrideSelectedColor: PropTypes.shape({
		icon: PropTypes.bool.isRequired,
		text: PropTypes.bool.isRequired,
	}),
	onClick: PropTypes.func.isRequired,
	type: PropTypes.oneOf([TYPE_LINK, TYPE_BUTTON, TYPE_EXTERNAL_URL]),
	iconSecondary: PropTypes.shape(),
};

ItemComponent.defaultProps = {
	icon: undefined,
	to: "",
	activeOnlyWhenExact: false,
	overrideSelectedColor: {
		icon: true,
		text: true,
	},
	type: TYPE_LINK,
	iconSecondary: undefined,
};

export default ItemComponent;
