export const PRIVACY_URL = 'https://www.seremy.it/privacy-policy';
export const CONDITIONS_OF_USE = 'https://www.seremy.it/termini-condizioni';
export const HELP_URL = 'https://www.seremy.it/contatti';

export const SITE_URL = 'https://www.seremy.it';
export const DOWNLOAD_APP_URL = 'https://seremy.page.link/app';

export const STRIPE_API_PUBLIC_KEY = process.env.REACT_APP_FIREBASE_PROJECT === 'dev'
  ? 'pk_test_RCf7h0IXkmleiKEDsxq06bXf00HLJjeVZE' // Sviluppo
  : 'pk_live_5yLHGHVZ3HmfkCgDhdcCjl2700294qJyXf'; // Produzione

export const MIN_PASSWORD_LENGTH = 6; // Minimo numero di caretteri accettati da firebase
