import firebase from "firebase/app";

import {
	getPendingInvitationForUser,
	acceptInvitation,
	refuseInvitation,
} from "firebaseManager";

import {
	REQUEST_FETCH_ALL_INVITATIONS,
	RECEIVE_FETCH_ALL_INVITATIONS,
	ERROR_FETCH_ALL_INVITATIONS,
	SET_PENDING_INVITATION,
	CLEAR_PENDING_INVITATION,
	SET_PROCESSED_INVITATION,
	RESET_INVITATIONS,
	HIDE_INVITATION_LOADER,
	SHOW_INVITATION_LOADER,
} from "actions/types";
import { setTimeout } from "core-js";

const showInvitationLoader = () => ({
	type: SHOW_INVITATION_LOADER,
});

const hideInvitationLoader = () => ({
	type: HIDE_INVITATION_LOADER,
});

const requestFetchInvitations = () => ({
	type: REQUEST_FETCH_ALL_INVITATIONS,
});

export const receiveFetchInvitations = (invitations) => ({
	type: RECEIVE_FETCH_ALL_INVITATIONS,
	invitations,
});

export const errorFetchInvitations = (error) => ({
	type: ERROR_FETCH_ALL_INVITATIONS,
	error,
});

export const setPendingInvitation = (invitation) => ({
	type: SET_PENDING_INVITATION,
	invitation,
});

export const clearPendingInvitation = () => ({
	type: CLEAR_PENDING_INVITATION,
});

export const reserInvitations = () => ({
	type: RESET_INVITATIONS,
});

export const setProcessedPendingInvitation = () => ({
	type: SET_PROCESSED_INVITATION,
});

function getInvitations(dispatch, account) {
	firebase
		.firestore()
		.collection("invitations")
		.where("account", "==", account.ref)
		.orderBy("createdAt", "desc")
		.onSnapshot(
			(querySnapshot) => {
				dispatch(receiveFetchInvitations(querySnapshot.docs));
			},
			function (error) {
				dispatch(errorFetchInvitations(error));
			}
		);
}

export const requestInvitations = (dispatch, account) => {
	getInvitations(dispatch, account);
	return requestFetchInvitations({});
};

function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export const acceptInvitationAsync = (invitation, user) => async (dispatch) => {
	try {
		dispatch(showInvitationLoader());
		await acceptInvitation(invitation, user);
		await sleep(2000);
		dispatch(setProcessedPendingInvitation());
		dispatch(hideInvitationLoader());
	} catch (error) {}
};

export const acceptInvitationAction = async (dispatch, invitation, user) => {
	await acceptInvitation(invitation, user);
	dispatch(setProcessedPendingInvitation());
};

export const refuseInvitationAction = async (dispatch, invitation) => {
	await refuseInvitation(invitation);
	dispatch(clearPendingInvitation());
};

export const checkPendingInvitation = async (dispatch, user) => {
	const pendingInvitation = await getPendingInvitationForUser(user);
	if (
		pendingInvitation &&
		pendingInvitation.docs &&
		pendingInvitation.docs[0]
	) {
		var invitation = pendingInvitation.docs[0];
		invitation.accountObj = await invitation.data().account.get();

		dispatch(setPendingInvitation(invitation));
	}
};
