import { REQUEST_FETCH_INDICATORS } from "../actions/types";
import { RECEIVE_FETCH_INDICATORS } from "../actions/types";
import { ERROR_FETCH_INDICATORS } from "../actions/types";

const initialState = {
	isFetching: false,
	error: null,
	userDataIndicators: null, // Oggetto DocumentSnapshot
	userDataSteps: null,
	userDataBpms: null,
	userDataBodyTemp: null,
	userDataSpO2: null,
	userDataSleepTime: null,
	userDataDeviceBattery: null,
	userDataDeviceStatusChangeEvents: null,
	proximitiesDaily: null,
	proximitiesHourly: null,
};

const userDataIndicators = (
	state = Object.assign({}, initialState, { error: false }),
	action
) => {
	switch (action.type) {
		case REQUEST_FETCH_INDICATORS:
			return {
				...state,
				isFetching: true,
				error: null,
				userDataIndicators: null,
				userDataSteps: null,
				userDataBpms: null,
				userDataBodyTemp: null,
				userDataSpO2: null,
				userDataSleepTime: null,
				userDataDeviceStatusChangeEvents: null,
				userDataDeviceBattery: null,
				proximitiesDaily: null,
				proximitiesHourly: null,
			};
		case RECEIVE_FETCH_INDICATORS:
			return {
				...state,
				isFetching: false,
				error: null,
				userDataIndicators: action.indicators,
				userDataSteps: action.steps,
				userDataBpms: action.bpms,
				userDataBodyTemp: action.bodyTemp,
				userDataSpO2: action.spO2,
				userDataSleepTime: action.sleepTime,
				userDataDeviceStatusChangeEvents: action.deviceStatusChangeEvents,
				userDataDeviceBattery: action.deviceBattery,
				proximitiesDaily: action.proximitiesDaily,
				proximitiesHourly: action.proximitiesHourly,
			};
		case ERROR_FETCH_INDICATORS:
			return {
				...state,
				isFetching: false,
				error: action.error,
				userDataIndicators: null,
				userDataSteps: null,
				userDataBpms: null,
				userDataBodyTemp: null,
				userDataSpO2: null,
				userDataSleepTime: null,
				userDataDeviceStatusChangeEvents: null,
				userDataDeviceBattery: null,
				proximitiesDaily: null,
				proximitiesHourly: null,
			};
		default:
			return state;
	}
};

export default userDataIndicators;
