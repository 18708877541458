import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  body {
    font-family: 'Heebo', sans-serif !important;
    padding: 0;
    margin: 0
  }
  div {
    box-sizing: border-box;
  }
  p {
    font-family: 'Heebo', sans-serif !important;
    padding: 0;
    margin: 0;
  }
  input, label {
    font-family: 'Heebo', sans-serif !important;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Heebo', sans-serif !important;
  }
  .grecaptcha-badge {
    position: absolute !important;
    bottom: 0 !important;
    right: 0 !important;
    width: 70px !important;
    overflow: hidden;
    box-shadow: none !important;
  }
`;
