export default () => ({
	// Drawer
	drawerItemDashboard: "Dashboard",
	drawerItemResources: "Persone",
	drawerItemWearableDevices: "Dispositivi",
	drawerItemNewOrderStarter: "Nuovo Ordine",
	drawerItemOrders: "Ordini",
	drawerItemAccessories: "Accessori",
	drawerItemNewAccessoryOrder: "Ordina accessori",

	drawerItemGuests: "Persone",
	drawerItemAlarms: "Allarmi",
	drawerItemAccount: "Account",

	dialogTitleLogout: "Log out",
	dialogTitleLogoutText: "Sei sicuro di voler uscire?",

	// Menu
	drawerAccountMenu: "Account",
	drawerAccountMenuLogout: "Esci",
	drawerHelp: "Richiedi assistenza",
	// Appbar Titles
	appBarTitleDashboard: "Dashboard",
	appBarTitleResources: "Persone",
	appBarTitleResource: "Persona",
	appBarTitleResourceNew: "Crea persona",
	appBarTitleWearableDevices: "Dispositivi",
	appBarTitleOrders: "Ordini",
	appBarTitleOrder: "Ordine",
	appBarTitleNewOrderStarter: "Nuovo Ordine",
	appBarTitleWearebleDevices: "Dispositivi",
	appBarTitleAccessories: "Accessori",
	appBarTitleMenu: "Menu",

	appBarTitleShop: "Negozio",
	appBarTitleWearableDevice: "Prodotto",
	appBarTitleBundle: "Piano",
	appBarTitleChart: "Carrello",
	appBarTitleBundleDuration: "Durata piano",

	appBarTitleGuests: "Persone",
	appBarTitleAlarms: "Allarmi",
	appBarTitleAccount: "Account",
	appBarTitleProfile: "Profilo utente",
	appBarTitleBeacon: "Sensori",
	appBarTitleBeaconPosition: "Sensore",
	appBarTitleSubscriptions: "Abbonamenti",
	appBarTitleSubscription: "Abbonamento",
	appBarTitlePayments: "Pagamenti",
	appBarTitlePayment: "Pagamento",
	appBarTitleInvitations: "Accesso altri familiari o utenti secondari",

	// Buttons
	buttonRetry: "Riprova",
	buttonNext: "Avanti",
	buttonLogin: "Accedi",
	buttonRegister: "Prosegui",
	buttonPasswordRecovery: "Recupera password",
	buttonGoToRegister: "Non hai un account? Registrati",
	buttonGoToLogin: "Hai già un account? Accedi",
	buttonGoBackToLogin: "Torna al login",
	buttonGoBack: "Torna indietro",
	buttonGoToRecoveryPassword: "Password dimenticata?",
	buttonReloadPage: "Ricarica pagina",
	buttonAdd: "Aggiungi",
	buttonContinue: "Continua",
	buttonRemove: "Rimuovi",
	buttonBack: "Indietro",
	buttonPay: "Acquista",
	buttonChange: "Modifica",
	buttonInsert: "Inserisci",
	buttonSelect: "Seleziona",
	buttonSave: "Salva",
	buttonDelete: "Elimina",
	buttonAbort: "Annulla",
	buttonClose: "Chiudi",
	buttonBackToMainPage: "Vai alla pagina principale",
	buttonNeedHelp: "Bisogno di aiuto? Contattaci",
	buttonBackOrderList: "Torna alla lista di ordini",
	buttonReload: "Ricarica",
	buttonHelp: "Richiedi assistenza",
	buttonDownloadApp: "Scarica APP",
	buttonUpdateCreditCard: "Aggiorna dati carta di credito",
	buttonBuy: "Acquista",
	buttonCheckout: "Vai alla cassa",
	buttonChangePlan: "Cambia piano",
	buttonContinueShopping: "Chiudi anteprima ordine",
	buttonGoToShop: "Torna allo shop",

	// Labels
	labelRegistrationDescription:
		"Acquista subito o gestisci il tuo Bracciale Smart Seremy",
	labelRegistrationFormDescription:
		"Inserire i dati di chi riceverà gli allarmi sul proprio smartphone (Caregiver).",
	labelRegistrationFormDescriptionSubtitle:
		"Si potranno poi aggiungere anche altri famigliari (altri Caregiver).",
	labelName: "Nome e Cognome",
	labelFirstName: "Nome",
	labelLastName: "Cognome",
	labelEmail: "Email",
	labelEmailVerification: "Reinserisci email",
	labelPhoneNumber: "Numero telefono",
	labelPassword: "Password",
	labelFullName: "Nome Cognome",
	labelAddress: "Indirizzo",
	labelAddress2: "Indirizzo 2",
	labelAddressStreet: "Via e numero civico",
	labelAddressMore: "Scala, piano, interno ecc.(opzionale)",
	labelCart: "Carrello",
	labelCity: "Città",
	labelProvince: "Provincia",
	labelPostalCode: "CAP",
	labelReadAndAccept: "Ho letto e accetto ",
	labelAnd: "e ",
	labelPrivacy: "l'informativa sulla privacy ",
	labelConditionsOfUse: "le condizioni d'uso",
	labelVerificationCode: "Codice di verifica",
	labelLoginModePhone: "Telefono",
	labelLoginModeEmail: "Email",
	labelDurationPerMonth: "/ mese",
	labelDurationPerYear: "/ anno",
	labelColors: "Colore",
	labelShowDetails: "Visualizza dettagli",
	labelEuro: "€",
	labelVATIncluded: "IVA inclusa",
	labelOrderPreview: "Anteprima ordine",
	labelQta: "Qtà",
	labelPartialTotalPrice: "Totale parziale",
	labelTotalPrice: "Totale",
	labelShippingPrice: "Spese di spedizione",
	labelTotalSubscription: "Totale sottoscrizioni",
	labelCreditCard: "Carta di credito",
	labelSepa: "Addebito SEPA su C/C",
	labelInsertCreditCard: "Inserisci informazioni carta di credito",
	labelInsertIban: "IBAN del conto corrente",
	labelIbanOwner: "Titolare del conto corrente",
	labelPaymentMethod: "Metodo di pagamento",
	labelPageNotFound: "La pagina ricercata non è stata trovata",
	labelCardNextUsage:
		"Il metodo di pagamento scelto verrà utilizzato per eseguire i pagamenti automatici dei prossimi rinnovi dell'abbonamento.",
	labelShippingInfo: "Dati spedizione",
	labelExtimatedShippingTime: "Tempi di spedizione stimati:",
	labelCardShippingTimeDetails: "2gg",
	labelSepaShippingTimeDetails:
		"8gg (causa tempi di verifica con istituto bancario)",
	labelSepaMandateText: `La sottoscrizione del presente mandato autorizza (A) Seremy e
		Stripe, il nostro servizio di pagamento, a richiedere alla banca
		del debitore l’addebito del suo conto e (B) la banca del
		debitore di procedere a tale addebito conformemente alle
		disposizioni impartite da Seremy. E' altresì diritto del
		debitore richiedere un rimborso alla propria banca rispettanto i
		termini e le condizioni del contratto con la propria banca. Il
		rimborso dev'essere richiesto entro 8 settimane a partire dalla
		data di addebito del conto. I termini e le condizioni sono
		riportati in un documento che si può richiedere alla propria
		banca. Con questa sottoscrizione si accetta inoltre di ricevere
		notifiche riguardanti gli addebiti futuri che verranno inviati
		entro 2 giorni dalla data di addebito effettiva.`,
	labelContinueReading: "Continua a leggere",
	labelOrderSummary: "Riepilogo ordine",
	labelGratis: "Gratuite",
	labelDescription: "Descrizione",
	labelNumber: "Numero",
	labelCreatedAt: "Creato il",
	labelSentAt: "Inviato il",
	labelStatus: "Stato",
	labelVerificationCodeDescription:
		"Inserisci il codice di verifica che ti abbiamo inviato via sms. La ricezione del sms potrebbe richiedere qualche minuto",
	labelOrderNumber: "Ordine n°",
	labelOrderProducts: "Prodotti",
	labelNoBeacon: "Fuori portata",
	labelTill: "Fino a/al ",
	labelAt: "Alle",
	labelFrom: "Dalle",
	labelHistory: "Cronologia",
	labelWithoutName: "Senza nome",
	labelSevenDaysBeacon: "Ultimi 7 giorni",
	labelConnected: "Connesso a ",
	labelGeofenceTitle: "Allarme 'Fuori zona' ripetuto ogni:",
	labelRadius: "Raggio",
	labelEmptyGeofence: "Zona vuota",
	labelGeofenceName: "Nome zona",
	labelNotes: "Note",
	labelNotesPlaceholder: "Inserisci le note di spegnimento",
	// Label date
	labelDateOf: "del",
	labelDateAt: "alle",
	labelToday: "Oggi",
	labelYesterday: "Ieri",
	labelAcceptedCards:
		"Puoi usare qualsiasi carta di credito o carta ricaricabile",
	labelHardwareAccessories: "Accessori e ricambi",
	labelSubscriptionAccessories: "Abbonamenti aggiuntivi",
	labelLinkToAccessories1: "Sono disponibili ",
	labelLinkToAccessories2: "accessori e abbonamenti aggiuntivi ",
	labelLinkToAccessories3:
		"per aggiungere nuove funzionalità e personalizzare il tuo bracciale.",
	labelAccessoryCategory: "Categorie accessori",
	// Messages
	messageNameRequired: "Inserisci il tuo nome",
	messageFirstNameRequired: "Inserisci il tuo nome",
	messageLastNameRequired: "Inserisci il tuo cognome",
	messagePasswordRequired: "Inserisci una password",
	messageWeakPassword: "Password troppo debole. Inseriscine un'altra",
	messageEmailRequired: "Inserisci la tua mail",
	messageEmailVerificationRequired: "Inserisci nuovamente la tua mail",
	messageEmailInvalid: "Mail non valida",
	messageEmailVerificationError: "I due indirizzi email devono coincidere",
	messageResourceNameRequired: "Inserisci il nome della persona",
	messagePhoneNumberRequired: "Inserisci il tuo numero di telefono",
	messagePhoneNumberInvalid: "Numero di telefono non valido",
	messageResourcePhoneNumberRequired: "Inserisci il numero di telefono",
	messageResourceAddressRequired: "Inserisci l'indirizzo",
	messageInvalidHour: "Ora in formato non valido",
	messageResourceNameTooLong: "Il nome inserito è troppo lungo (max 100 caratteri).",
	messagePhoneNumberNeedPrefix:
		"Aggiungi il prefisso del numero di telefono. Es. +39",
	messageCityRequired: "Inserisci la città",
	messageProvinceRequired: "Inserisci la provincia",
	messagePostalCodeRequired: "Inserisci il CAP",
	messageNoProductSelected: "Nessun prodotto selezionato",
	messageNoSubscriptionSelected: "Nessuna sottoscrizione selezionata",
	messageOrderUpdated: "Ordine aggiornato",
	messageAcceptPrivacy: "Necessario accettare le condizioni sulla privacy",
	messagePaymentMethodUpdated: "Metodo di pagamento aggiornato",
	messagePaymentMethodRequired: "Inserisci un metodo di pagamento",
	messagePasswordRecoveryMailSent:
		"Abbiamo appena spedito una mail con le indicazioni su come modificare la password",
	messageSuccessfulSave: "Salvataggio completato",
	messageFailedSave: "Errore durante il salvataggio",
	messageSuccessfulDelete: "Cancellazione completata",
	messageFailedDelete: "Errore durante la cancellazione",
	// Message Errors
	messageErrorLoading: "Errore durante il caricamento",
	messageErrorTimeout:
		"Il caricamento è lento, verifica la connessione la tua internet",
	// Auth
	messageErrorLinkAccount: "Errore durante l'autenticazione. Riprova!",
	messageErrorInvalidVerificationCode: "Codice non valido",
	messageErrorMissingVerificationCode: "Inserisci il codice di verifica",
	messageErrorExipiredVerificationCode:
		"Codice scaduto. Riprova ad eseguire l'accesso",
	messageErrorSignInTooManyRequest:
		"Sono stati eseguiti troppi tentativi di accesso. Riprova più tardi",
	messageErrorSignInWithPhoneNumber: "Errore durante l'accesso. Riprova!",
	messageErrorSignInWithEmail: "Errore durante l'accesso. Riprova!",
	messageErrorInvalidEmail: "Email non valida",
	messageErrorUserDisabled: "Questo utente è stato disabilitato",
	messageErrorUserNotFound: "Utente non trovato. Esegui la registrazione",
	messageErrorWrongPassword: "Password non valida",
	messageErrorCaptchaCheckFail: "Verifica captcha fallita",
	messageErrorInInvalidPhoneNumber: "Numero di telefono non valido",
	messageErrorLinkEmailAlreadyInUse: "Email utilizzata da un altro utente",
	messageErrorWeakPassword:
		"Password troppo debole. Iserisci almeno 6 caratteri",
	messageErrorRegistrationNotAuthorized:
		"Errore autorizzazione registrazione. Riprova",
	messageVerificationCodeRequired: "Inserisci il codice arrivato tramite SMS",
	messageErrorVerificationCode: "Errore durante la verifica del codice",
	messageErrorAuth: "Si è verificato un errore. Riprova",
	messageErrorRegister: "Errore durante la registrazione",
	messageErrorUserExists: "Utente già registrato. Prova ad eseguire l'accesso",
	messageErrorUserNotExists:
		"Utente non trovato. Prova ad eseguire la registrazione",
	messageErrorCheckUserExists:
		"Errore durante la verifica dell'account. Riprova",
	messageErrorInitUserData: "Errore durante la lettura dei tuoi dati",
	messageErrorFetchUserData:
		"Si è verificato un errore durante la lettura dei tuoi dati",
	messageErrorFetchAccount:
		"Si è verificato un errore durante la lettura dei dati dell'account. Riprova ad accedere",
	messageErrorAccountNotExists:
		"Account non trovato. Prova ad eseguire la registrazione",
	messageErrorScreenCatch: "Errore durante il caricamento del componente",
	messageErrorSignInInvalidEmail: "Email non valida",
	messageErrorSignInProviderAlreadyLinked:
		"Utente in uso. Prova ad eseguire il login",
	messageErrorCredentialAreadyInUse:
		"Utente già registrato. Prova ad eseguire il login",
	messageErrorSignInUserDisabled: "Questo utente è stato disabilitato",
	messageErrorSignInUserNotFound: "Utente non trovato. Registrati per entrare",
	messageErrorSignInWrongPassword: "Password non valida",
	messageErrorSignInCaptchaCheckFail: "Verifica captcha fallita",
	messageErrorSignInInvalidPhoneNumber: "Numero di telefono non valido",
	messageErrorSignInEmailInUse: "Email utilizzata da un'altro utente",
	messageErrorPasswordRecovery:
		"Errore durante il recupero della password. Riprova",
	// ----
	messageErrorFetchResources: "Errore durante la lettura delle persone",
	messageInvalidAuth:
		"Impossibile recuperare le informazioni del tuo account. Riesegui il login",
	messageErrorFetchConfiguration:
		"Errore durante la lettura dati di configurazione",
	messageErrorFetchWearableDevices: "Errore durante la lettura dei dispositivi",
	messageErrorFetchSubscriptions: "Errore durante la lettura degli abbonamenti",
	messageErrorOrderSelectDeviceToContinue:
		"Seleziona un dispositivo per continuare",
	messageErrorCreateOrder: "Errore durante la creazione dell'ordine",
	messageErrorSaveOrderProduct: "Errore durante il salvataggio dell' ordine",
	messageErrorSaveRecipientInfo:
		"Errore durante il salvataggio dei dati spedizione",
	messageErrorFetchAccountCreditCard:
		"Errore durante la lettura del metodo di pagamento",
	messageErrorReplaceAccountCredictCard:
		"Errore durante l'impostazione del metodo di pagamento",
	messageErrorSetupStripeCreditCard:
		"Errore inizializzazione metodo di pagamento",
	messageErrorCannotPay: "Errore durante il pagamento. Riprova",
	messageErrorFetchOrders: "Errore durante la lettura degli ordini",
	messageErrorShowOrders: "Errore durante la visualizzazione degli ordini",
	messageNoOrders: "Non hai effettuato ancora nessun ordine",
	messageOrderNotFound: "Ordine non trovato",
	messageErrorFetchOrder: "Errore durante la lettura dell'ordine.",

	messageUserUpdated: "Utente aggiornato",
	messageErrorUserUpdated: "Errore durante il salvataggio dei dati utente",
	messageLoadUserUpdated: "Errore durante la lettura dei dati utente",

	messageResourceUpdated: "Dati persona aggiornati",
	messageResourceDeleted: "Dati persona cancellati",
	messageErrorResourceUpdated: "Errore durante il salvataggio dei dati persona",
	messageLoadResourceUpdated: "Errore durante la lettura dei dati persona",
	messageResourceIndoor: "Per questa configurazione è necessaria la Funzione Localizzazione Indoor",
	messageNoBeaconFound: "Non hai ancora acquistato un Sensore di presenza",
	messageIndoorTrackingInactive:
		"Per poter visualizzare questa schermata è necessario avere un piano di protezione che prevede la rilevazione completa della posizione",
	messageIndoorTrackingNotSelected:
		"Per poter utilizzare i Sensori di presenza è necessario avere un piano di protezione che prevede la rilevazione completa della posizione",
	messageErrorMultipleSubscription:
		"Sono stati inseriti due o più piani di protezione differenti e NON COMPATIBILI tra loro. Rimuovere i piani indesiderati per procedere con l'ordine.",
	messageErrorSensorAccessory:
		"Per poter utilizzare i sensori è necessario avere un piano di protezione che prevede l'utilizzo dei sensori. Rimuovere i sensori o cambiare il piano di protezione per procedere con l'ordine.",
	messageErrorQtaSubscriptionDevice:
		"La quantità dei bracciali non coincide con le quantità dei piani inseriti nel carrello.",
	dialogTitleDeleteResource: "Cancellazione persona",
	dialogTitleDeleteText:
		"Tutti i dati persona andranno persi, sei sicuro di voler continuare?",
	resourcesEditDeleteText:
		"ATTENZIONE. La cancellazione dei dati personali comporta la perdita di tutti i dati raccolti.",
	resourcesEditDeleteText2:
		"Al termine dell'operazione il dispositivo sarà disponibile per essere asssociato ad altre persone.",

	dialogTitleDeviceResource: "Associazione dispositivo",
	dialogTitleDeviceText: "Sei sicuro di voler continuare?",

	// Dialog
	dialogConfirm: "Conferma",
	dialogUndo: "Annulla",
	dialogOk: "Ok",
	dialogContinue: "Continua",
	dialogNewOrder: "Crea nuovo",
	dialogTitleContinueLastOrder: "Vuoi completare l’ordine pendente?",
	dialogDescriptionContinueLastOrder:
		"Premi “CONTINUA” per completare l’ordine pendente.",
	dialogNoInvitationTitle: "Acquista pacchetto utenti",
	dialogNoInvitationBody:
		"Per condividere l’accesso a Seremy con altri familiari o utenti secondari, occorre acquistare un piano di protezione superiore.",
	// Geofence Status
	geofenceStatusRequestActivation: "INVIATA RICHIESTA DI ATTIVAZIONE",
	geofenceStatusSendingActivation: "IN ATTESA DELL' ATTIVAZIONE",
	geofenceStatusActive: "ATTIVA",
	geofenceStatusRequestDeactivation: "INVIATA RICHIESTA DI DISATTIVAZIONE",
	geofenceStatusSendingDeactivation: "IN ATTESA DELLA DISATTIVAZIONE",
	geofenceStatusNotActive: "NON ATTIVA",
	// Order Status
	orderStatusDraft: "BOZZA",
	orderStatusPaymentInProgress: "PAGAMENTO IN CORSO",
	orderStatusPaidWaitingDisputes: "VALIDAZIONE PAGAMENTO",
	orderStatusPaid: "PAGATO",
	orderStatusClose: "CHIUSO",
	orderStatusAborted: "ANNULLATO",
	orderStatusCompleted: "COMPLETATO",
	// First device order starter
	shopOrderStarterStepDevice: "DISPOSITIVO",
	shopOrderStarterStepBundle: "PIANO",
	shopOrderStarterStepChart: "CARRELLO",
	shopOrderStarterStepBundleDuration: "DURATA",
	shopOrderStarterStepRecipient: "SPEDIZIONE",
	shopOrderStarterStepCheckout: "CHECKOUT",

	// Order Starter
	orderStarterStepDevice: "DISPOSITIVO",
	orderStarterStepSubscription: "PIANO",
	orderStarterStepRecipient: "SPEDIZIONE",
	orderStarterStepUsers: "UTENTI",
	orderStarterStepCharger: "RICARICA",
	orderStarterStepCheckout: "CHECKOUT",
	orderStarterStepSubscriptionTitle: "Scegli Abbonamento",
	orderStarterStepBundleTitle: "Scegli la protezione",
	orderStarterStepBundleDurationTitle: "Durata del piano",
	orderStarterStepBundleDescription:
		"Scegli la protezione più adatta alle tue esigenze. Ogni piano include sempre l’utilizzo illimitato del bracciale, della SIM incorporata, della trasmissione dati, e della APP Seremy per Android e iOS.",
	orderStarterStepBundleDurationDescription:
		"Scegli la durata del piano più adatto alle tue esigenze. Il piano verrà rinnovato automaticamente al termine della durata.",
	orderStarterStepSubscriptionDescription:
		"Scegli il piano più adatto alle tue esigenze. L’abbonamento include l’utilizzo illimitato del bracciale, della SIM incorporata, della trasmissione dati, e della APP Seremy per Android e iOS.  L’abbonamento NON ha durata minima e puoi recedere in qualsiasi momento senza penali",
	orderStarterStepUsersTitle: "Numero utenti App Seremy",
	orderStarterStepUsersDescription:
		"Scegli quanti utenti potranno utilizzare l'APP Seremy. E' incluso l'utilizzo per utente singolo ma è possibile aggiungere un pacchetto di 5 utenti aggiuntivi con canone mensile o annuale.",
	orderStarterStepChargerTitle: "Basetta di ricarica",
	orderStarterStepChargerDescription:
		"Scegli la basetta di ricarica adatta alle tue esigenze",
	orderStarterStepRecipientInfoTitle: "Dati destinatario",
	orderStarterStepRecipientInfoDescription:
		"Inserisci i dati del destinatario a cui verrà inviato il dispositivo",
	orderStarterStepCheckoutTitle: "Checkout",
	orderStarterStepPurchaseCompleteTitle: "Ordine completato con successo",
	orderStarterStepPurchaseCompleteDescription:
		"Ti terremo informato sullo stato di evasione dell’ordine",
	orderStarterStepBundleInfo:
		"(*) pagamenti periodici iva inclusa. I prezzi indicati corrispondono al costo del piano per un singolo dispositivo. Il piano si rinnova automaticamente. Il piano NON ha una durata minima e puoi recedere in qualsiasi momento senza penali.",
	orderStarterStepBundleDurationInfo:
		"(*) pagamenti periodici iva inclusa. I prezzi indicati corrispondono al costo unitario dei servizi. Il piano si rinnova automaticamente. Il piano NON ha una durata minima e puoi recedere in qualsiasi momento senza penali.",
		// Payment errors
	paymentMessageMissingParameter: "Impossibile eseguire questa operazione",
	paymentMessageAccountNotFound:
		"Account non trovato. Riesegui il login per continuare.",
	paymentMessageOrderNotFound:
		"Informazioni ordine non trovate. Esci e prova a creare un nuovo ordine",
	paymentMessagePaymentNotFound:
		"Informazioni sul pagamento da eseguire non trovate",
	paymentMessageSibscriptionNotFound:
		"Informazioni della sottoscrizione non travate",
	paymentMessageErrorReadCard:
		"Errore durante il recupero dei dati della tua carta",
	paymentMessageErrorSaveCard: "Errore durante l'impostazione della carta",
	paymentMessageErrorCustomerNoPaymentMethodFound:
		"Metodo di pagamento non trovato",
	paymentMessageInsertPaymentMethod:
		"Inserisci i dati della tua carta di credito",
	paymentMessageOrderStatusInvalidForCharge:
		"Stato dell'ordine non valido. Verifica che non sia già pagato o annullato",
	paymentMessageSubscriptionStatusInvalidForCharge:
		"Stato dell'abbonamento non valido. Verifica che non sia disabilitato o pagamento pendente",
	paymentMessageErrorSaveCustomerStripeAccount:
		"Errore durante il salvataggio dei dati nel tuo account",
	// Payment Stripe error
	stripeMessagePaymentIntentAuthenticationFailure:
		"Autenticazione carta fallita. Riprova o inserisci un'altra carta",
	stripeMessagePaymentIntentAttemptFailed:
		"L'ultimo tentativo di pagamento non è andato a buon fine. Provare con un altro metodo di pagamento",
	stripeMessagePaymentIntentMandateInvalid:
		"Il mandato SEPA utilizzato non è più valido per effettuare il pagamento",
	stripeMessagePaymentIntentFailed:
		"Il pagamento non è andato a buon fine. Riprovare con un'altro metodo di pagamento",
	stripeMessageAuthenticationRequired:
		"Necessario eseguire l'autenticazione per completare il pagamento",
	stripeMessageMissing: "Inserire una carta di credito valida",
	stripeUnexpectedState:
		"Non è più possibile eseguire questa operazione. Ricaricare la pagina",
	stripeMessageAccountAlreadyExists:
		"Email utilizzata da un'altro account. Modifica la mail dal profilo per continuare",
	stripeMessageCardDeclined: "Carta rifiutata. Prova ad inserirne un'altra",
	stripeMessageCardNotSupported:
		"Questa carta non supporta questo tipo di acquisto. Riprova con un'altra carta",
	stripeMessageCardVelocityExceeded:
		"Limite di spesa della carta raggiunto. Riprova con un'altra carta",
	stripeMessageCurrencyNotSupported:
		"La carta non supporta questa moneta. Riprova con un'altra carta",
	stripeMessageCardRefused: "La carta è stata rifiutata. Riprova con un'altra carta",
	stripeMessageDuplicateTransaction:
		"Rilevata una transazione duplicata. Verifica che l'ordine sia stato completato o riprova più tardi",
	stripeMessageExpiredCard: "La carta è scaduta. Riprova con un'altra",
	stripeMessageIncorrectNumber:
		"Numero della carta non è corretto. Modifica o riprova con un'altra carta",
	stripeMessageIncompleteZip:
		"Il CAP insetito non è corretto. Verifica il CAP e riprova.",
	stripeMessageIncorrectCvc:
		"CVC non è corretto o mancante. Modifica o riprova con un'altra carta",
	stripeMessageInsufficientFounds:
		"Fondi non sufficienti per conpletare la transazione. Riprova con un'altra carta",
	stripeMessageInvalidAccount:
		"Transazione fallita. Verifica che la carta funzioni correttamente e riprova",
	stripeMessageInvalidAmount:
		"Transazione fallita. Verifica che la carta possa eseguire transazioni di questo importo",
	stripeMessageIncompleteExpiry:
		"Data di scadenza della carta non è valida. Modifica o riprova con un'altra carta",
	stripeMessageInvalidExpiryYear:
		"Anno di scadanza della carta non è valido. Modifica o riprova con un'altra carta",
	stripeMessageIssuerNotAvailable:
		"Il servizio del emittente della carta non è raggiungibile. Riprova più tardi",
	stripeMessageNewAccountInformationAvailable:
		"La carta è stata rifiutata. Riprova con un'altra carta",
	stripeMessageProcessingError:
		"Errore momentaneo dell'elaborazione della transazione. Riprova.",
	stripeMessageRestrictedCard:
		"La carta è stata rifiutata. Riprova con un'altra carta",
	stripeMessageTryAgainLater:
		"Errore momentaneo dell'elaborazione della transazione. Riprova.",
	stripeMessageWithdrawalCountLimitExceeded:
		"Limite di spesa della carta raggiunto. Riprova con un'altra carta",
	stripeMessageInvalidCardType:
		"Tipo di carta non valida. Riprova con un' altra carta",
	stripeMessageRateLimit:
		"Servizio momentaneamente non disponibile. Riprova più tardi",
	stripeMessageErrorCharge: "Impossibile completare la transazione",

	homeWellnessLevel: "Livello di benessere",
	homeWellnessLevelLowDescription: "BASSO",
	homeWellnessLevelGoodVoteDescription: "BUONO",
	homeWellnessLevelGreatVoteDescription: "OTTIMO",

	resourceDevice: "Dispositivo",
	notAvaiable: "Non disponibile",
	notAvaiableShort: "N.D.",
	birthDateLabel: "Data di nascita",
	gender: "Genere",
	genderFemale: "Femminile",
	genderMale: "Maschile",

	WellnessHistoryChartTitle: "Andamento livello di benessere",
	HistogramChartLast30Days: "Ultimi 30 giorni",
	HistogramChartLastWeek: "Ultima settimana",
	HistogramChartDay: "Giorno",
	HistogramChartValue: "Valore",
	HistogramChartNoData: "Dati non sufficienti",
	HistogramChartWaiting: "Attendere...",
	HistogramChartError: "Errore nel caricamento dati...",

	LastPositionTitle: "Ultima posizione rilevata",

	noAlarmsFound: "Nessun allarme presente",

	alarmDescription: "Allarmi",
	alarmUnknownDescription: "Allarme sconosciuto",
	alarmPanicButtonDescription: "SOS",
	alarmFallDescription: "Caduta rilevata",
	alarmGeofenceCrossed: "Fuori zona",
	alarmHeartLowBpm: "Battito cardiaco basso",
	alarmHeartHighBpm: "Battito cardiaco alto",
	alarmBodyTempLow: "Temperatura bassa",
	alarmBodyTempHigh: "Temperatura alta",
	alarmSpO2Low: "Ossigenazione bassa",
	alarmSpO2High: "Ossigenazione alta",
	alarmBatteryLevel: "Esaurimento batteria dispositivo",
	alarmNotReachable: "Dispositivo non raggiungibile",
	alarmConnectionInterrupted: "Connessione con il dispositivo interrotta",
	alarmPosition: "Posizione",
	alarmTest: "Allarme di test",
	alarmOutRange: "Fuori zona interna",
	alarmIndoorGeofence: "Zona interna",
	addressNotAvaiable: "Indirizzo non disponibile",
	clearedAt: "Data e ora di spegnimento",
	clearedBy: "Operatore",
	clearedNotes: "Note",
	clear: "Spegni",
	alarmListDividerActive: "Allarmi attivi",
	alarmListDividerNotActive: "Allarmi spenti",
	alarmSosExercise: "Esercitazione SOS",
	alarmLongImmobility: "Immobilità o inutilizzo bracciale",

	mobileResourceTabulator_alarms: "Allarmi",
	mobileResourceTabulator_position: "Posizione",
	mobileResourceTabulator_activity: "Attivita'",
	mobileResourceTabulator_main: "Benessere",
	mobileResourceTabulator_cardio: "Cardio",
	mobileResourceTabulator_bodyTemp: "Temperatura",
	mobileResourceTabulator_spO2: "Ossigenazione",
	mobileResourceTabulator_sleep: "Sonno",
	mobileResourceTabulator_device: "Dispositivo",
	mobileResourceTabulator_security: "Sicurezza",

	activityLevelChartTitle: "Livello di attivita'",
	cardioLevelChartTitle: "Livello di attivita cardiaca",
	bodyTempLevelChartTitle: "Temperatura corporea",
	spO2LevelChartTitle: "Livello di ossigenazione",
	dailyStepsChartTitle: "Numero di passi giornalieri",
	weeklyStepsChartTitle: "Numero di passi settimanali",
	batteryChartChartTitle: "Livello batteria",
	week: "Sett.",
	activityLevelGaugeLevel: "Media",
	avgBpmChartTitle: "Battiti medi",
	minBpmChartTitle: "Battiti minimi",
	bpmChartTitleLabel: "Battiti al minuto (bpm)",
	maxBpmChartTitle: "Battiti massimi",
	avgBodyTempChartTitle: "Temperatura media",
	minBodyTempChartTitle: "Temperatura minima",
	bodyTempChartTitleLabel: "Temperatura (°C)",
	maxBodyTempChartTitle: "Temperatura massima",
	avgSpO2ChartTitle: "Ossigenazione media",
	minSpO2ChartTitle: "Ossigenazione minima",
	spO2ChartTitleLabel: "Ossigenazione (SpO2%)",
	maxSpO2ChartTitle: "Ossigenazione massima",
	weeklySleepChartTitle: "Ore di sonno ultima settimana",
	sleepClockChartTitle: "Ore di sonno odierne",
	sleepClockZero: "00:00",
	sleepClockSix: "06:00",
	sleepClockTwelve: "12:00",
	sleepClockEigthteen: "18:00",
	sleepClockTotal: "Totale",
	deviceStatusTitle: "Stato del dispositivo",
	deviceStatus: "Stato corrente",
	deviceSerialNumber: "Numero dispositivo",

	deviceStatusUnknown: "Stato sconosciuto",
	deviceStatusBooted: "Accensione",
	deviceStatusWristOn: "Indossato",
	deviceStatusWristOff: "Non indossato",
	deviceStatusChargeOn: "In carica",
	deviceStatusChargeOff: "Alimentato a batteria",

	lastEventChange: "Ultimo cambio di stato",
	deviceChengeStatusHistoryTitle: "Storico",
	deviceChengeStatusHistorySubTitle: "(ultimi 10 cambi di stato)",

	totalAlarms: "Totale Allarmi",
	totalAlarms_ALARM_TYPE_FALL_DETECTED: "Allarmi Caduta",
	totalAlarms_ALARM_TYPE_GEOFENCE_CROSSED: "Allarmi Fuori zona",
	totalAlarms_ALARM_TYPE_PANIC_BUTTON: "Allarmi SOS",
	totalAlarms_OTHER: "Altri allarmi",

	resourceListHeader_alarms: "Allarmi",
	resourceListHeader_sleep: "Sonno",
	resourceListHeader_activity: "Attivita'",
	resourceListHeader_cardio: "Cardio",
	resourceListHeader_bodyTemp: "Temperatura",
	resourceListHeader_spO2: "Ossigenazione",
	resourceListHeader_beacon: "Beacon",
	resourceListHeader_security: "Sicurezza",
	resourceListHeader_contactTracing: "Contatti",
	onlyWithActiveAlarms: "Solo con allarmi attivi",

	orderBy: "Ordina per ",
	clearAlarm: "Spegni allarme",
	clearedAlarm: "Allarme spento",
	positionNotAvaiable: "Posizione non disponibile",
	addResource: "Associa persona",
	removeResource: "Rimuovi associazione a persona",

	onlyNotAssociated: "Solo dispositivi non associati",
	securityIndexChartTitle: "Livello di sicurezza aziendale",
	weekProximityLevelChartTitle: "Livello di prossimità settimanale",
	dayProximityLevelChartTitle: "Livello di prossimità giornaliero",
	securityDashboardLabel: "Media % 7gg",
	weekProximityDashboardLabel: "Minuti",
	noData: "Nessun dato disponibile",
	contactTracingLegend: "Minuti/Persona",
	contactTracing: "Rintracciabilità contatti",
	range1: "Range 1",
	range2: "Range 2",
	start: "Inizio",
	end: "Fine",
	proximityLevel: "Livello di prossimità",
	securityIndexLabel: "Indice percentuale",
	proximityLevelChartLabel: "Minuti",

	profileLink: "Profilo utente",
	beaconsLink: "Sensori di presenza",
	beaconName: "Nome sensore",
	beaconAsk: "Vuoi spostare il sensore in questo punto?",
	newMarkerPosition: "Nuova posizione",

	resourcesEditLink: "Persone",
	resourcesEditCreateNew: "Nuova persona",
	resourcesEditUploadImage: "Carica immagine",
	resourcesEditDevice: "Dispositivo",
	reasourceEditNoDeviceAvaiable: "Nessun dispositivo disponibile",
	reasourceEditSelectDevice: "Seleziona",

	reasourceEditTabResourceData: "Dati personali",
	reasourceEditTabDevice: "Dispositivo",
	reasourceEditTabGeofences: "Zone di guardia",
	reasourceEditTabInternalGeofences: "Zone di guardia interne",
	reasourceEditTabPosition: "Monitoraggio posizione",
	reasourceEditTabDelete: "Rimuovi",

	labelUpdateFrequencyPosition: "Velocità di aggiornamento della posizione",

	reasourceEditTabCardioFrequency: "Monitoraggio battito cardiaco",
	labelUpdateFrequencyCardio: "Frequenza rilevazione battito cardiaco",
	labelMinutes: "Minuti",
	
	cardioMonitorNever: "Mai",
	cardioMonitorNeverDesc: "Nessuna rilevazione. Durata batteria massima.",
	cardioMonitor10Minutes: "10",
	cardioMonitor10MinutesDesc: "Rilevazioni ogni 10 minuti. Durata batteria minima.",
	cardioMonitor60Minutes: "60",
	cardioMonitor60MinutesDesc: "Rilevazioni ogni 60 minuti. Durata batteria minima.",
	cardioMonitor120Minutes: "120",
	cardioMonitor120MinutesDesc: "Rilevazioni ogni 2 ore. Durata batteria media.",

	subscriptionsLink: "Abbonamenti",
	subscriptionsNextPaymentAt: "Prossimo rinnovo il",
	subscriptionsNextPayment: "Prossimo rinnovo",
	subscriptionsPrice: "Costo",
	subscriptionsEuroMonth: "€ / mese",
	subscriptionsEuroYear: "€ / anno",
	subscriptionsStatus: "Stato",
	subscriptionsStatus_active: "Attivo",
	subscriptionsStatus_expired: "Scaduto",
	subscriptionsStart: "Data attivazione",
	subscriptionsDevices: "Numero dispositivi",
	noSubcriptions: "Nessun abbonamento presente",
	noPayments: "Nessun pagamento presente",

	paymentLink: "Pagamenti",
	paymentPaidAt: "Effettuato il",
	paymentAmount: "Importo",
	paymentStatus_success: "Completato",
	paymentStatus_error: "In errore",
	paymentStatus_aborted: "Annullato",
	paymentStatus: "Stato",
	paymentShowOrder: "Vedi ordine",
	paymentShowSubscription: "Vedi abbonamento",

	creditCardUpdated: "Metodo di pagamento aggiornato con successo",
	creditCardError: "Errore nell'aggiornamento del metodo di pagamento",

	administrationPanel: "Amministrazione",
	configurationsPanel: "Configurazioni",
	otherPanel: "Altro",

	labelPrivacyWebLink: "Privacy policy di Seremy website & store",
	labelPrivacyAppLink: "Privacy policy di Seremy App",
	termsAndConditionWebLink: "Termini e condizioni di utilizzo",
	contactUsWebLink: "Richiedi assistenza",

	assistancePanel: "Assistenza tecnica",

	positionMonitorNoMonitor: "Salvavita",
	positionMonitorLowFrequency: "Localizzazione",
	positionMonitorHighFrequency: "Inseguimento",
	positionMonitorNoMonitorDesc:
		"Manda la posizione solo in caso di allarme. Durata batteria massima.",
	positionMonitorLowFrequencyDesc:
		"Manda la posizione all'aperto ogni 15 min. Durata batteria media.",
	positionMonitorHighFrequencyDesc:
		"Manda la posizione all'aperto ogni 5 min. Durata batteria minima. (Richiede Localizzazione Indoor)",
	positionMonitorNoteLabel: "Nota",
	positionMonitorNote:
		'In presenza di almeno una zona di guardia la velocità di aggiornamento è impostata su "Alta" come valore predefinito e non può essere modificata. Per poter modificare nuovamente la velocità di aggiornamento occorre rimuovere tutte le zone di guardia impostate.',

	invitationsLink: "Accesso altri familiari/utenti secondari",
	noInvitations: "Nessun invito presente",
	invitationsCreatedAt: "Data e ora",

	invitationStatusToSend: "Invio in corso",
	invitationStatusPending: "In attesa di risposta",
	invitationStatusAccepted: "Accettato",
	invitationStatusRejected: "Rifiutato",
	invitationCreateNew: "Invia un nuovo invito",
	invitationSent: "Invito spedito",
	invitationResend: "Reinvia",
	invitationDescription:
		"Invia il permesso  ad un familiare o un utente secondario di visualizzare i dati del tuo account Seremy",

	noIndoorGeofence: "Nessuna zona di guardia interna configurata",
	newIndoorGeofence: "Nuova zona di guardia interna",
	labelIndoorGeofenceAlwaysOn: "Sempre attiva",
	messageErrorFetchResourceIndoorGeofences:
		"Errore durante la lettura delle zone di guardia interne",
	messageIndoorGeofenceDeletedSuccessfully:
		"Zona di guardia interna eliminata correttamente",
	messageErrorDeleteIndoorGeofence:
		"Errore durante la cancellazione della zona interna",
	messageErrorShowResourceIndoorGeofences:
		"Errore visualizzazione delle zone di guardia interne",
	indoorGeofenceEventTypeIn: "Entra",
	indoorGeofenceEventTypeOut: "Esce",
	labelBeacon: "Zona interna",
	labelEventType: "Tipo di evento",
	messageBeaconRequired: "Selezionare una zona",
	messageEventTypeRequired: "Selezionare un tipo di evento",
	messageTimeRequired: "Inserisci l'ora",
	messageErrorTimesNotSubsequent:
		"L'ora di inizio dev'essere precedente all'ora di fine",
	messageErrorSaveGeofence: "Errore durante il salvataggio della zona",
	messageGeofenceSaved: "Zona di guardia salvata",
	messageUserCannotBeInvited: "Non è possibile invitare questo utente",
	pendingInvitationTitle: "Invito a condividere l'account",
	pendingInvitationText:
		"Sei stato invitato a condividere l'account di {accountName}. Vuoi accettare l'invito?",
	pendingInvitationText2:
		"In caso accettassi ti verrà richiesto di effettuare nuovamente il login",

	pendingInvitationAccept: "Accetta",
	pendingInvitationReject: "Rifiuta",

	referralsLink: "Consiglia Seremy ad un amico",
	appBarTitleReferrals: "Consiglia Seremy ad un amico",
	referralCreateNew: "Consiglia Seremy ad nuovo un amico",
	referralSent: "Grazie per averci aiutato a diffondere Seremy!",
	referralSendAt: "Inviato il",
	referralStatusNew: "Invio in corso...",
	referralStatusOpen: "Inviato",
	referralStatusRejected: "Rifiutato",
	referralStatusClosed: "Utilizzato",
	referralStatusApproved: "Accettato",
	referralError_NAME_INVALID: "ERRORE: Nome non valido",
	referralError_PHONENUMBER_INVALID: "ERRORE: Numero di telefono non valido",
	referralError_PHONENUMBER_ALREADY_PRESENT: "ERRORE: Contatto già presente",
	referralError_ACCOUNT_ALREADY_PRESENT: "ERRORE: Contatto già cliente Seremy",
	'referralError_referral/already-exists': "ERRORE: La persona scelta ha già ricevuto un invito",
	earningTitle:
		"Grazie per essere un ambasciatore Seremy! In totale hai guadagnato",
	pendingReferralTitle: "Congratulazioni!",
	pendingReferralDescription:
		"Hai diritto ad uno sconto per l'acquisto di Seremy di {referred_earning}€ per il tuo primo acquisto!",
	pendingReferralDescriptionPerc:
		"Hai diritto ad uno sconto per l'acquisto di Seremy del {referred_earning_perc}% per il tuo primo acquisto!",
	noReferrals: "Nessun contatto presente",
	orderReferralLabel: "Sconto benvenuto",
	orderSummaryReferralLabel: "Sconto benvenuto di",
	orderSummaryReferralPercLabel: "Sconto benvenuto del",
	month: "mese",
	year: "anno",

	notificationsLink: "Notifiche",
	appBarTitleNotifications: "Notifiche",
	notificationShowAlarm: "Vedi allarme",
	notificationShowPayment: "Vedi pagamento",

	usersLink: "Gestione permessi utenti",
	appBarTitleUsers: "Gestione permessi utenti",
	userOwner: "Amministratore",
	userGuest: "Utente secondario",
	userEmail: "Email",
	userPhoneNumber: "Numero di telefono",
	accountRole: "Ruolo",
	accountVisibility: "Persone visibili",
	accountVisibilityAll: "Tutte",
	accountVisibilityPartial: "Parziale",

	userToOwnerTitle: "Modifica ruolo",
	userToOwnerDesc:
		'Una volta modificato il ruolo dell\'utente secondario in Amministratore non sarà più possibile assegnare nuovamente il ruolo "Utente secondario"',

	audioBlockedTitle: "Allarme audio bloccato dal browser",
	audioBlockedContent:
		"Il browser ha bloccato l'esecuzione del suono di allarme alla ricezione di una notifica.",
	audioBlockedContentSafari:
		'Se state utilizzando Safari, è sufficiente dal menu "Safari" aprire le preferenze dell\'applicazione, selezionare' +
		' il tabulatore "Siti Web" e seguire la voce "Riproduzione Automatica", infine modificare ' +
		'la voce corrispondente al sito seremy.it in "Sempre"',
	promotionalCode: "Aggiungi un codice sconto promozionale",
	promotionaCodeError: "Codice promozionale non valido",
	promotionaCodePendingError:
		"Occorre verificare il Codice promozionale prima di proseguire",
	browserNotSupported:
		"Per motivi di sicurezza, il portale Seremy non supporta il browser Microsoft Internet Explorer.",
	browserNotSupported_2:
		"Per continuare è necessario utilizzare uno dei seguenti browser:",
	beaconMapLoading: "Attendere...",
	beaconMapSearch: "Ricerca indizzo",
	drawerItemMenu: "Menu",
	drawerItemReport: "Report",
	appBarTitleReports: "Report",
	pageReportTitle: "Esportazione Dati Battiti/Passi/Sonno",
	sleepReportTitle: "Esportazione Dati Sonno",
	reportSonno: "Sonno",
	reportCardio: "Battiti",
	reportActivity: "Passi",
	reportFromDate: "Dalla data",
	reportToDate: "Alla data",
	reportResources: "Persone",
	reportCalculate: "Calcola",
	reportAll: "Tutti",
	reportNotFound: "Report non trovato",
	mustSelectAtLeastOneResource: "Occorre selezionare almeno una persona",
	toLongPeriod: "Periodo massimo 30 giorni",
	fromAfterTo: "La data di inizio deve essere inferiore alla data di fine",

	reportDate: "Data",
	reportSleepStartPeriod: "Data e ora inizio",
	reportSleepEndPeriod: "Data e ora fine",
	reportSleepDeepSleep: "Totale sonno profondo",
	reportSleepLightSleep: "Totale sonno leggero",
	reportSleepTotalSleep: "Totale sonno",
	reportSleepShowIntervals: "Mostra intervalli singoli",
	reportNotResults: "Nessun risultato trovato",
	reportSleepDuration: "Durata",
	reportSleepDeep: "Sonno profondo",
	reportSleepLight: "Sonno leggero",
	reportSleepSleepType: "Tipo di sonno",

	reportCardioAvg: "Avg bpm",
	reportCardioMin: "Min bpm",
	reportCardioMax: "Max bpm",
	reportCardioTimeMax: "Data e ora max bpm",
	reportCardioTimeMin: "Data e ora min bpm",
	reportCardioBpms: "Bpm",
	reportCardioTimestamp: "Data e ora misurazione",
	reportCardioDuration: "Durata misurazione",

	reportStepStartHour: "Ora di inizio",
	reportStepEndHour: "Ora di fine",
	reportStepSteps: "Passi",
	reportStepTimestamp: "Data e ora misurazione",

	reportWillBeDownloaded:
		"Il report, in formato .csv, verrà scaricato automaticamente sul tuo dispositivo al termine del calcolo, nella sezione Download",
	menuCardNewOrderTitle: "Nuovo ordine",
	menuCardNewOrderContent: "Ordina online un nuovo bracciale Seremy",
	menuCardNewShopTitle: "Negozio Seremy",
	menuCardNewShopContent: "Ordina online sul nuovo Negozio Seremy",
	menuCardNewShopAction: "CLICCA QUI",
	menuCardReferralTitle: "Consiglia Seremy",
	menuCardReferralContent: "Consiglia Seremy ad un amico e risparmia!",
	menuCardReportTitle: "Report",
	menuCardReportContent: "Estrai e analizza i dati dei tuoi bracciali",
	menuShopWearableDevices: "Bracciali",
	menuShopSensors: "Sensori",
	menuShopAccessories: "Accessori",
	menuShopAdditionalServices: "Servizi Aggiuntivi",

	menuShopSensorsWithPremiumBundleBefore: "Hai scelto un piano che prevede l'utilizzo di sensori. Se desideri proseguire con l'ordine senza l'aggiunta di ulteriori accessori",
	menuShopSensorsWithPremiumBundleAfter: "premere il tasto 'continua' in fondo alla pagina.",

	errorRemoveGuest: "Errore nel tentativo di rimuovere l'utente secondario",
	removeGuest: "Utente secondario rimosso",
	dialogTitleRemoveGuest: "Rimozione utente secondario",
	dialogDescriptionRemoveGuest: "Sei sicuro di voler rimuovere l'utente secondario?",

	effectsTitle: {
		"users": "N. utenti secondari",
		"gps": "Frequenza localizzazione",
		"indoor": "Localizzazione interna",
		"device-beacon": "Gestione sensori svenimento",
		"device-gasDetector": "Gestione sensori rilevamento gas",
		"device-smokeDetector": "Gestione sensori rilevamento fumo",
		"cardio": "Frequenza battito cardiaco",
	},
	effectsGroup: {
		"cardio": "Rilevazione battito",
		"device": "Utilizzo sensori",
		"gps": "Rilevazione posizione",
		"users": "Utenti app",
	},
	bundlesTitle:{
		"B_SYRUS_BASE":"Base",
		"B_SYRUS_STANDARD":"Standard",
		"B_SYRUS_PREMIUM":"Premium",
	},
	bundlesDescription:{
		"B_SYRUS_BASE":{
			"cardio": {
				"title":"base",
				"description":"ogni 30 minuti"
			},
			"gps": {
				"title":"base",
				"description":"ogni 15 minuti"
			},
			"users": {
				"title":"1 utente app",
				"description":"Solo un utente può visualizzare i dati delle persone monitorate."
			},
		},
		"B_SYRUS_STANDARD":{
			"cardio": {
				"title":"completa",
				"description":"ogni 10 minuti."
			},
			"gps": {
				"title":"base",
				"description":"ogni 15 minuti."
			},
			"users": {
				"title":"5 utenti app",
				"description":"Fino a 5 utenti possono visualizzare i dati delle persone monitorate."
			},
		},
		"B_SYRUS_PREMIUM":{
			"cardio": {
				"title":"completa",
				"description":"ogni 10 minuti."
			},
			"gps": {
				"title":"completa",
				"description":[
					"ogni 5 minuti.",
					"Rileva la posizione della persona monitorata dentro la casa con l'utilizzo dei sensori di presenza acquistati.",
				],
			},
			"sensors": {
				"title":"5 utenti app",
				"description":"Fino a 5 utenti possono"
			},
			"users": {
				"title":"Utenti app illimitati",
				"description":"Puoi invitare tutti gli utenti che vuoi a visualizzare i dati delle persone monitorate."
			},
		},
	}
});
