import React, { Component } from "react";
import { Container, ConfirmButton } from "./style";
import { connect } from "react-redux";
import strings from "styles/strings";
import { withRouter } from "react-router-dom";
import { models } from "seremy-firebase-data";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { translate } from "utils/language";

import {
	acceptReferralAction,
	refuseReferralAction,
} from "actions/referralsActions";

export const { Earnings } = models.referral;

class PendingReferralPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openDialog: true,
		};
		this.handleClickOpenDialog = this.handleClickOpenDialog.bind(this);
		this.handleClickCloseDialog = this.handleClickCloseDialog.bind(this);
		this.handleAccept = this.handleAccept.bind(this);
		this.handleReject = this.handleReject.bind(this);
	}

	handleClickOpenDialog = () => {
		this.setState({ openDialog: true });
	};

	handleClickCloseDialog = () => {
		this.setState({ openDialog: false });
	};

	handleAccept() {
		const { pendingReferral, dispatchAcceptReferralAction } = this.props;
		dispatchAcceptReferralAction(pendingReferral);
	}

	handleReject() {
		const { pendingReferral, dispatchRefuseReferralAction } = this.props;
		dispatchRefuseReferralAction(pendingReferral);
	}

	render() {
		const { pendingReferral } = this.props;
		if (pendingReferral.data().isPerc) {
			var description = translate("pendingReferralDescriptionPerc", [
				pendingReferral.data().referred_earning_perc,
			]);
		} else {
			var description = translate("pendingReferralDescription", [
				pendingReferral.data().referred_earning,
			]);
		}

		return (
			<Container>
				<Dialog
					open={this.state.openDialog}
					disableBackdropClick={true}
					disableEscapeKeyDown={true}
					onClose={this.handleClickCloseDialog}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<React.Fragment>
						<DialogTitle id="alert-dialog-title">
							{strings().orderReferralLabel}
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								{description}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<ConfirmButton
								type="secondary"
								onClick={() => this.handleReject()}
							>
								{strings().pendingInvitationReject}
							</ConfirmButton>
							<ConfirmButton
								type="primary"
								onClick={() => {
									this.handleAccept();
								}}
							>
								{strings().pendingInvitationAccept}
							</ConfirmButton>
						</DialogActions>
					</React.Fragment>
				</Dialog>
			</Container>
		);
	}
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
	dispatchAcceptReferralAction: (invitation) =>
		acceptReferralAction(dispatch, invitation),
	dispatchRefuseReferralAction: (invitation) =>
		refuseReferralAction(dispatch, invitation),
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(PendingReferralPage));
