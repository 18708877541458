import {
	REQUEST_FETCH_ALL_NOTIFICATIONS,
	RECEIVE_FETCH_ALL_NOTIFICATIONS,
	ERROR_FETCH_ALL_NOTIFICATIONS,
	CLEAR_ALL_NOTIFICATIONS,
	CLEAR_NOTIFICATION_SOUND,
	SET_NOTIFICATION_SOUND,
	SET_NOTIFICATION_ALERT,
	CLEAR_NOTIFICATION_ALERT,
} from "actions/types";

import moment from "moment";

export const clearAllNotficationsAction = () => ({
	type: CLEAR_ALL_NOTIFICATIONS,
});

export const clearNotificationSound = () => ({
	type: CLEAR_NOTIFICATION_SOUND,
});

export const setNotificationSound = () => ({
	type: SET_NOTIFICATION_SOUND,
});

export const clearNotificationAlert = () => ({
	type: CLEAR_NOTIFICATION_ALERT,
});

export const setNotificationAlert = () => ({
	type: SET_NOTIFICATION_ALERT,
});

const requestFetchNotificationsError = () => ({
	type: ERROR_FETCH_ALL_NOTIFICATIONS,
});

const requestFetchNotifications = () => ({
	type: REQUEST_FETCH_ALL_NOTIFICATIONS,
});

const receiveFetchNotifications = (notifications) => ({
	type: RECEIVE_FETCH_ALL_NOTIFICATIONS,
	notifications,
});

async function getNotifications(dispatch, account) {
	account.ref
		.collection("notifications")
		.where("createdAt", ">=", moment().subtract(5, "days").toDate())
		.orderBy("createdAt", "desc")
		.onSnapshot(
			(querySnapshot) => {
				dispatch(receiveFetchNotifications(querySnapshot));
			},
			function (error) {
				dispatch(requestFetchNotificationsError());
			}
		);
}

export const requestNotifications = (dispatch, account) => {
	getNotifications(dispatch, account);
	return requestFetchNotifications({});
};
