import React from 'react';
import PropTypes from 'prop-types';

import { isNullOrUndefined } from 'utils/common';

import { Container, Label, ActionButton } from './style';

const Message = ({ message, onActionClick, actionText }) => (
  <Container>
    <Label>{message}</Label>
    {!isNullOrUndefined(onActionClick) && (
      <ActionButton onClick={onActionClick}>{actionText}</ActionButton>
    )}
  </Container>
);

Message.propTypes = {
  message: PropTypes.string,
  onActionClick: PropTypes.func,
  actionText: PropTypes.string,
};

Message.defaultProps = {
  message: '',
  onActionClick: undefined,
  actionText: '',
};

export default Message;
