import { store } from "../index";
export const translate = (label, params = []) => {
	const webstrings = store.getState().common.webstrings;
	var string = webstrings[label];

	if (string && params.length > 0) {
		for (var i = 0; i < params.length; i++) {
			string = string.replace("{%" + i + "}", params[i]);
		}
	}
	return string;
};
