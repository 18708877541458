import {
	REQUEST_FETCH_RESOURCES,
	RECEIVE_FETCH_RESOURCES,
	ERROR_FETCH_RESOURCES,
	RESET_RESOURCES,
	SET_SELECTED_RESOURCE,
	UPDATE_STORED_RESOURCE,
	ADD_STORED_RESOURCE,
	DELETE_STORED_RESOURCE,
	RECEIVE_RESOURCES_INDICATORS,
	RECEIVE_DEVICE_BATTERY_STATUSES,
	SET_SELECTED_EDIT_RESOURCE,
	CLEAR_SELECTED_EDIT_RESOURCE,
} from "../actions/types";

const initialState = {
	isFetching: true,
	resources: [], // Lista oggetti DocumentSnapshot
	selected: null, // Oggetto DocumentSnapshot
	error: null,
	indicators: [],
	batteryStatuses: [],
	proximitiesDaily: [],
	editSelected: null,
};

/**
 * Reducer enità Resources;
 * persone a cui un dispositivo può essere collegato)
 */

const resources = (state = initialState, action) => {
	switch (action.type) {
		case SET_SELECTED_EDIT_RESOURCE: {
			return {
				...state,
				editSelected: action.resource,
			};
		}
		case CLEAR_SELECTED_EDIT_RESOURCE: {
			return {
				...state,
				editSelected: null,
			};
		}
		case RECEIVE_RESOURCES_INDICATORS: {
			return {
				...state,
				indicators: action.indicators,
				batteryStatuses: action.batteryStatuses,
				proximitiesDaily: action.proximitiesDaily,
			};
		}
		case RECEIVE_DEVICE_BATTERY_STATUSES: {
			return {
				...state,
				batteryStatuses: action.batteryStatuses,
			};
		}
		case REQUEST_FETCH_RESOURCES:
			return {
				...state,
				isFetching: true,
				error: null,
			};
		case RECEIVE_FETCH_RESOURCES:
			return {
				...state,
				isFetching: false,
				resources: action.resources,
				selected:
					action.resources.find((res) => res.id === action.selectedId) ||
					action.resources[0],
				error: null,
			};
		case ERROR_FETCH_RESOURCES:
			return {
				...state,
				isFetching: false,
				resources: [],
				selected: null,
				error: action.error,
			};
		case SET_SELECTED_RESOURCE:
			return {
				...state,
				selected: action.resource,
			};
		case UPDATE_STORED_RESOURCE:
			return {
				...state,
				resources: state.resources.map((resource) => {
					if (resource.id === action.resource.id) {
						return action.resource;
					}
					return resource;
				}),
				selected:
					state.selected && state.selected.id === action.resource.id
						? action.resource
						: state.selected,
				editSelected:
					state.editSelected && state.editSelected.id === action.resource.id
						? action.resource
						: state.editSelected,
			};
		case ADD_STORED_RESOURCE:
			return {
				...state,
				resources: [...state.resources, action.resource],
				selected: !state.selected ? action.resource : state.selected,
			};
		case DELETE_STORED_RESOURCE:
			return {
				...state,
				resources: state.resources.filter(
					(resource) => resource.id !== action.resource.id
				),
				selected:
					state.selected && state.selected.id === action.resource.id
						? state.resources.find(
								(resource) => resource.id !== action.resource.id
						  )
						: state.selected,
			};
		case RESET_RESOURCES:
			return initialState;
		default:
			return state;
	}
};

export default resources;
