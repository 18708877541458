import Logger from 'utils/logger';
import { getProducts } from 'firebaseManager';
import Strings from 'styles/strings';

import {
  REQUEST_FETCH_CONFIGURATION,
  RECEIVE_FETCH_CONFIGURATION,
  ERROR_FETCH_CONFIGURATION,
  RESET_CONFIGURATION,
} from './types';

import { fetchIndicatorDefs } from './indicatorsActions';
import { getShopProducts } from "./shopActions";

const requestFetchConfiguration = () => ({
  type: REQUEST_FETCH_CONFIGURATION,
});

const receiveFetchConfiguration = (indicatorDefs, products, shopProducts) => ({
  type: RECEIVE_FETCH_CONFIGURATION,
  indicatorDefs,
  products,
  shopProducts,
});

const errorFetchConfiguration = (error) => ({
  type: ERROR_FETCH_CONFIGURATION,
  error,
});

export const resetConfiguration = () => ({
  type: RESET_CONFIGURATION,
});

/**
 * Legge tutte le informazioni di configurazione
 */
export const fetchConfiguration = () => async (dispatch) => {
  dispatch(requestFetchConfiguration());
  try {
    const indicatorsDefsResult = await fetchIndicatorDefs();
    const productsResult = await getProducts();
    const shopProductsResult = await getShopProducts();
    dispatch(
      receiveFetchConfiguration(indicatorsDefsResult.docs, productsResult.docs, shopProductsResult.docs),
    );
  } catch (e) {
    Logger.error(
      Logger.code.CONFIGURATION_ACTIONS_FETCH_INDICATORS,
      e.message,
      '',
      e,
    );
    dispatch(errorFetchConfiguration(Strings().messageErrorFetchConfiguration));
  }
};
