import {
	REQUEST_FETCH_SUBSCRIPTIONS,
	RECEIVE_FETCH_SUBSCRIPTIONS,
	ERROR_FETCH_SUBSCRIPTIONS,
	RESET_SUBSCRIPTIONS,
	UPDATE_STORED_SUBSCRIPTIONS,
	CLEAR_SELECTED_SUBSCRIPTION,
	SET_SELECTED_SUBSCRIPTION,
} from "../actions/types";

const initialState = {
	isFetching: true,
	subscriptions: [], // Lista oggetti DocumentSnapshot
	error: null,
	selectedSubscription: null,
	completeSubscriptions: [],
};

const subscriptions = (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_SELECTED_SUBSCRIPTION:
			return {
				...state,
				selectedSubscription: null,
			};
		case SET_SELECTED_SUBSCRIPTION:
			return {
				...state,
				selectedSubscription: action.subscription,
			};
		case REQUEST_FETCH_SUBSCRIPTIONS:
			return {
				...state,
				isFetching: true,
				error: null,
			};
		case RECEIVE_FETCH_SUBSCRIPTIONS:
			return {
				...state,
				isFetching: false,
				subscriptions: action.subscriptions,
				completeSubscriptions: action.completeSubscriptions,
				error: null,
			};
		case ERROR_FETCH_SUBSCRIPTIONS:
			return {
				...state,
				isFetching: false,
				subscriptions: [],
				error: action.error,
			};
		case UPDATE_STORED_SUBSCRIPTIONS:
			return {
				...state,
				subscriptions: state.subscriptions.map((subscription) => {
					if (subscription.id === action.resource.id) {
						return action.subscription;
					}
					return subscription;
				}),
			};
		case RESET_SUBSCRIPTIONS:
			return initialState;
		default:
			return state;
	}
};

export default subscriptions;
