import strings from "styles/strings";

import { ReactComponent as DashboardIcon } from "assets/icons/dashboard.svg";
import { ReactComponent as AlarmIcon } from "assets/icons/bell.svg";
import { ReactComponent as MenuIcon } from "assets/icons/menu.svg";
import { ReactComponent as ResourcesIcon } from "assets/icons/users.svg";
import { ReactComponent as AccountIcon } from "assets/icons/account.svg";

import * as paths from "routes/paths";

export const drawerItems = [
	[
		{
			text: strings().drawerItemDashboard,
			icon: DashboardIcon,
			path: paths.DASHBOARD,
			exact: true,
			showBadge: false,
		},
		{
			text: strings().drawerItemAlarms,
			icon: AlarmIcon,
			path: paths.ALARMS,
			exact: true,
			showBadge: true,
			badgeValue: "allActiveAlarms",
		},
		{
			text: strings().drawerItemGuests,
			icon: ResourcesIcon,
			path: paths.RESOURCES,
			exact: true,
			showBadge: false,
		},
		{
			text: strings().drawerItemMenu,
			icon: MenuIcon,
			path: paths.MENU,
			exact: true,
			showBadge: false,
			badgeValue: "notifications",
		},
	],
];
