import Loadable from "react-loadable";

import Loading from "components/Loading";
import Page404 from "pages/404";

import * as paths from "./paths";
import {
	DevicesShop, 
	DeviceAccessoriesShop, 
	AdditionalServices 
} from "../pages/Shop/routes";

const Login = Loadable({
	loader: () => import("pages/Auth/Login"),
	loading: Loading,
	timeout: 10000,
});

const Register = Loadable({
	loader: () => import("pages/Auth/Register"),
	loading: Loading,
	timeout: 10000,
});

const PasswordRecovery = Loadable({
	loader: () => import("pages/Auth/PasswordRecovery"),
	loading: Loading,
	timeout: 10000,
});

// const Dashboard = Loadable({
//   loader: () => import('pages/Dashboard'),
//   loading: Loading,
//   timeout: 10000,
// });

const OrderStarter = Loadable({
	//loader: () => import("pages/OrderStarter"),
	loader: () => import("pages/Shop"),
	loading: Loading,
	timeout: 10000,
});

const Orders = Loadable({
	loader: () => import("pages/Orders"),
	loading: Loading,
	timeout: 10000,
});

const Order = Loadable({
	loader: () => import("pages/Order"),
	loading: Loading,
	timeout: 10000,
});

const OrderView = Loadable({
	loader: () => import("pages/OrderView"),
	loading: Loading,
	timeout: 10000,
});

const Dashboard = Loadable({
	loader: () => import("pages/Dashboard"),
	loading: Loading,
	timeout: 10000,
});

const WearableDevices = Loadable({
	loader: () => import("pages/WearableDevices"),
	loading: Loading,
	timeout: 10000,
});

const WearableDevice = Loadable({
	loader: () => import("pages/WearableDevice"),
	loading: Loading,
	timeout: 10000,
});

const Guests = Loadable({
	loader: () => import("pages/Guests"),
	loading: Loading,
	timeout: 10000,
});
const Alarms = Loadable({
	loader: () => import("pages/Alarms"),
	loading: Loading,
	timeout: 10000,
});

const Account = Loadable({
	loader: () => import("pages/Account"),
	loading: Loading,
	timeout: 10000,
});

const Menu = Loadable({
	loader: () => import("pages/Menu"),
	loading: Loading,
	timeout: 10000,
});

const Resources = Loadable({
	loader: () => import("pages/Resources"),
	loading: Loading,
	timeout: 10000,
});

const Resource = Loadable({
	loader: () => import("pages/Resource"),
	loading: Loading,
	timeout: 10000,
});

const ResourceTab = Loadable({
	loader: () => import("pages/Resource"),
	loading: Loading,
	timeout: 10000,
});

const Profile = Loadable({
	loader: () => import("pages/Profile"),
	loading: Loading,
	timeout: 10000,
});

const Beacons = Loadable({
	loader: () => import("pages/Beacons/Beacons"),
	loading: Loading,
	timeout: 10000,
});
const ResourcesEdit = Loadable({
	loader: () => import("pages/ResourcesEdit"),
	loading: Loading,
	timeout: 10000,
});
const Subscriptions = Loadable({
	loader: () => import("pages/Subscriptions"),
	loading: Loading,
	timeout: 10000,
});

const Payments = Loadable({
	loader: () => import("pages/Payments"),
	loading: Loading,
	timeout: 10000,
});

const Invitations = Loadable({
	loader: () => import("pages/Invitations"),
	loading: Loading,
	timeout: 10000,
});
/*const Accessories = Loadable({
	loader: () => import("pages/Accessories/Accessories"),
	loading: Loading,
	timeout: 10000,
});*/
const Referrals = Loadable({
	loader: () => import("pages/Referrals"),
	loading: Loading,
	timeout: 10000,
});
const Notifications = Loadable({
	loader: () => import("pages/Notifications"),
	loading: Loading,
	timeout: 10000,
});

const Users = Loadable({
	loader: () => import("pages/Users"),
	loading: Loading,
	timeout: 10000,
});

const Reports = Loadable({
	loader: () => import("pages/Reports"),
	loading: Loading,
	timeout: 10000,
});

const Shop = Loadable({
	loader: () => import("pages/Shop"),
	loading: Loading,
	timeout: 10000,
});

const WearableDeviceShop = Loadable({
	loader: () => import("pages/Shop/WearableDevicesShop"),
	loading: Loading,
	timeout: 10000,
});
/*
const DevicesShop = Loadable({
	loader: () => import("pages/Shop/DevicesShop"),
	loading: Loading,
	timeout: 10000,
});
*/
export const notAuthRedirect = paths.LOGIN;
export const authRedirect = paths.MAIN_PATH;
export const authRedirectEndUser = paths.MAIN_ENDUSER_PATH;

export const baseRoutes = [
	{
		path: paths.LOGIN,
		templatePath: paths.LOGIN_TEMPLATE,
		Component: Login,
		exact: true,
		strict: false,
		settings: {
			withDrawer: false,
			needAuth: false,
		},
	},
	{
		path: paths.REGISTER,
		templatePath: paths.REGISTER_TEMPLATE,
		Component: Register,
		exact: true,
		strict: false,
		settings: {
			withDrawer: false,
			needAuth: false,
		},
	},
	{
		path: paths.PASSWORD_RECOVERY,
		templatePath: paths.PASSWORD_RECOVERY_TEMPLATE,
		Component: PasswordRecovery,
		exact: true,
		strict: false,
		settings: {
			withDrawer: false,
			needAuth: false,
		},
	},
	{
		path: paths.ORDER_STARTER,
		templatePath: paths.ORDER_STARTER_TEMPLATE,
		exact: true,
		strict: false,
		Component: OrderStarter,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.ORDERS,
		templatePath: paths.ORDERS_TEMPLATE,
		exact: true,
		strict: false,
		Component: Orders,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.ORDER,
		templatePath: paths.ORDER_TEMPLATE,
		exact: true,
		strict: false,
		Component: Order,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.ACCOUNT,
		templatePath: paths.ACCOUNT_TEMPLATE,
		Component: Account,
		exact: true,
		strict: false,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.MENU,
		templatePath: paths.MENU_TEMPLATE,
		Component: Menu,
		exact: true,
		strict: false,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.PROFILE,
		templatePath: paths.PROFILE_TEMPLATE,
		exact: true,
		strict: false,
		Component: Profile,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.REFERRALS,
		templatePath: paths.REFERRALS_TEMPLATE,
		exact: true,
		strict: false,
		Component: Referrals,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.DEVICES_SHOP,
		templatePath: paths.DEVICES_SHOP_TEMPLATE,
		Component: DevicesShop,
		exact: true,
		strict: false,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.DEVICE_ACCESSORIES,
		templatePath: paths.DEVICE_ACCESSORIES_TEMPLATE,
		Component: DeviceAccessoriesShop,
		exact: true,
		strict: false,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.ADDITIONAL_SERVICES,
		templatePath: paths.ADDITIONAL_SERVICES_TEMPLATE,
		Component: AdditionalServices,
		exact: true,
		strict: false,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.SHOP,
		templatePath: paths.SHOP_TEMPLATE,
		exact: true,
		strict: false,
		Component: Shop,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.NEW_ORDER_BUNDLE,
		templatePath: paths.NEW_ORDER_BUNDLE_TEMPLATE,
		exact: true,
		strict: false,
		Component: WearableDeviceShop,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	/*{
		path: paths.ACCESSORIES_TEMPLATE,
		templatePath: paths.ACCESSORIES_TEMPLATE,
		exact: true,
		strict: false,
		Component: Accessories,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},*/
];

export const routesEndUser = [
	...baseRoutes,
	{
		path: "/*",
		exact: true,
		strict: false,
		Component: Page404,
		settings: {
			withDrawer: false,
			needAuth: true,
		},
	},
];

export const routes = [
	...baseRoutes,
	{
		path: paths.ORDERVIEW,
		templatePath: paths.ORDERVIEW_TEMPLATE,
		exact: true,
		strict: false,
		Component: OrderView,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.INVITATIONS,
		templatePath: paths.INVITATIONS_TEMPLATE,
		exact: true,
		strict: false,
		Component: Invitations,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.DASHBOARD,
		templatePath: paths.DASHBOARD_TEMPLATE,
		Component: Dashboard,
		exact: true,
		strict: false,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.WEARABLE_DEVICES,
		templatePath: paths.WEARABLE_DEVICES_TEMPLATE,
		Component: WearableDevices,
		exact: true,
		strict: false,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.WEARABLE_DEVICE,
		templatePath: paths.WEARABLE_DEVICE_TEMPLATE,
		Component: WearableDevice,
		exact: true,
		strict: false,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.GUESTS,
		templatePath: paths.GUESTS_TEMPLATE,
		Component: Guests,
		exact: true,
		strict: false,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.ALARMS,
		templatePath: paths.ALARMS_TEMPLATE,
		Component: Alarms,
		exact: true,
		strict: false,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.RESOURCES,
		templatePath: paths.RESOURCES_TEMPLATE,
		Component: Resources,
		exact: true,
		strict: false,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.RESOURCE,
		templatePath: paths.RESOURCE_TEMPLATE,
		Component: Resource,
		exact: true,
		strict: false,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.SUBSCRIPTIONS,
		templatePath: paths.SUBSCRIPTIONS_TEMPLATE,
		exact: true,
		strict: false,
		Component: Subscriptions,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.PAYMENTS,
		templatePath: paths.PAYMENTS_TEMPLATE,
		exact: true,
		strict: false,
		Component: Payments,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.RESOURCE_TAB,
		templatePath: paths.RESOURCE_TAB_TEMPLATE,
		exact: true,
		strict: false,
		Component: ResourceTab,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.BEACONS,
		templatePath: paths.BEACONS_TEMPLATE,
		exact: true,
		strict: false,
		Component: Beacons,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.NOTIFICATIONS,
		templatePath: paths.NOTIFICATIONS_TEMPLATE,
		exact: true,
		strict: false,
		Component: Notifications,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.USERS,
		templatePath: paths.USERS_TEMPLATE,
		exact: true,
		strict: false,
		Component: Users,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.RESOURCES_EDIT,
		templatePath: paths.RESOURCES_EDIT_TEMPLATE,
		exact: true,
		strict: false,
		Component: ResourcesEdit,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: paths.REPORTS,
		templatePath: paths.REPORTS_TEMPLATE,
		exact: true,
		strict: false,
		Component: Reports,
		settings: {
			withDrawer: true,
			needAuth: true,
		},
	},
	{
		path: "/*",
		exact: true,
		strict: false,
		Component: Page404,
		settings: {
			withDrawer: false,
			needAuth: true,
		},
	},
];
