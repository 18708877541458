import { isNullOrUndefined } from "utils/common";
import { orderStarterAdditionalParams } from "pages/OrderStarter/routes";
import {
	LOGIN,
	REGISTER,
	MAIN_ENDUSER_PATH,
	ORDER_STARTER,
} from "./paths";

const EXCLUDED_REDIRECT_PATHS = [LOGIN, REGISTER];

const URL_REDIRECT_TO_PARAM_NAME = "redirectTo";

export const appendAdditionalParamsIfFound = (
	location,
	path,
	paramNames = []
) => {
	let newPath = path;
	const urlParamsObj = new URLSearchParams(location.search);
	paramNames.forEach((paramName) => {
		if (urlParamsObj.get(paramName)) {
			newPath += `${
				newPath.includes("?") ? "&" : "?"
			}${paramName}=${urlParamsObj.get(paramName)}`;
		}
	});
	return newPath;
};

/**
 * Estrae il valore dal path del parametro redirectTo
 * Se non presente ritorna il MAIN_PATH
 * @param {Object} location oggetto locatioun react-router
 * @returns {String} path rendirizzamento
 */
export const extractRedirectToPath = (location) => {
	const params = new URLSearchParams(location.search);
	let newPath = params.get(URL_REDIRECT_TO_PARAM_NAME) || "/";

	if (newPath === ORDER_STARTER) {
		return appendAdditionalParamsIfFound(
			location,
			"/new-order-starter-kit?creationStep=device",
			orderStarterAdditionalParams
		);
	}
	return newPath;
};

export const exPath = (location, url) =>{
	const params = new URLSearchParams(url);
	let newPath = params.get(URL_REDIRECT_TO_PARAM_NAME) || "/";

	if (newPath === ORDER_STARTER) {
	
		const test =  appendAdditionalParamsIfFound(
			location,
			newPath,
			orderStarterAdditionalParams
		);

		return test;
	}

	return newPath;
}

/**
 * Crea un path in cui viene indicato il parametro redirectTo
 * nel nuovo path di cui si vuole rendirizzare solo se
 * il path di redirect non è stato escluso (Presente nella lista EXCLUDED_REDIRECT_PATHS)
 * @param {Object} location oggetto location react-router
 * @param {String} newPath nuovo path di rendirizzamento
 * @returns {String} nuovo path con parametro redirizzamento
 */
export const getPathWithRedirect = (location, newPath) => {
	const excluded = EXCLUDED_REDIRECT_PATHS.find(
		(path) => location.pathname.indexOf(path) !== -1
	);
	if (location.pathname == MAIN_ENDUSER_PATH && isNullOrUndefined(excluded)) {
		return `${newPath}?${URL_REDIRECT_TO_PARAM_NAME}=${location.pathname}`;
	}
	return newPath;
};

/**
 * Crea un path a cui vengono concatenati i parametri di ricerca
 * del path corrente
 * @param {Object} location oggetto location react-router
 * @param {String} newPath nuovo path di rendirizzamento
 * @returns {String} nuovo path con parametri
 */
export const getPathWithParams = (location, newPath) => {
	if (isNullOrUndefined(location.search) || location.search === "") {
		return newPath;
	}
	return `${newPath}${location.search}`;
};

/**
 * Legge il valore del parametro passato dalla query string del path corrente
 * @param {Object} location oggetto location react-router
 * @param {String} paramName nome del parametro GET
 * @returns {String} valore del creationStep
 */
export const getQueryParam = (location, paramName) => {
	const params = new URLSearchParams(location.search);
	return params.get(paramName);
};
