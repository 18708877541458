import styled from "styled-components";
import theme from "styles/theme";
import ButtonComponent from "components/Button";

export const AppContainer = styled.div`
	display: block;
	background-color: ${theme.windowBackgroundColor};
`;

export const FlexContainer = styled.div`
	display: flex;
	flex-direction: row;
	min-height: 100vh;
`;

export const ContentProgress = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const ConfirmButtonComponent = styled(ButtonComponent)``;
