import seremyFire from 'seremy-firebase-data';

export const createSetupIntent = () => {
  const httpsCallable = seremyFire.firebase
    .app()
    .functions('europe-west1')
    .httpsCallable('createSetupIntent');
  return httpsCallable();
};

/**
 * Richima una function che riesegue il pagamento della sottoscrizione
 * legata al pagamento stesso
 * @param {String} accountId id account
 * @param {String} paymentId id pagamento di cui eseguire il pagamento della sottoscrizione
 */
export const renewSubscriptionFromPayment = (accountId, paymentId) => {
  const httpsCallable = seremyFire.firebase
    .app()
    .functions('europe-west1')
    .httpsCallable('renewSubscriptionFromPayment');
  return httpsCallable({
    accountId,
    paymentId,
  });
};
