import {
	REQUEST_FETCH_ALL_BEACON,
	RECEIVE_FETCH_ALL_BEACON,
	SET_SELECTED_BEACON,
	CLEAR_SELECTED_BEACON,
	CLEAR_ALL_BEACONS,
} from "actions/types";

const setSelectedBeaconAction = (beacon) => ({
	type: SET_SELECTED_BEACON,
	beacon,
});

export const clearAllBeaconAction = () => ({
	type: CLEAR_ALL_BEACONS,
});

const clearSelectedBeaconAction = () => ({
	type: CLEAR_SELECTED_BEACON,
});

const requestFetchBeacons = () => ({
	type: REQUEST_FETCH_ALL_BEACON,
});

const receiveFetchBeacons = (beacons) => ({
	type: RECEIVE_FETCH_ALL_BEACON,
	beacons,
});

async function getBeacons(dispatch, account) {
	account.ref.collection("beacons").onSnapshot(
		(querySnapshot) => {
			dispatch(receiveFetchBeacons(querySnapshot));
		},
		function (error) {
			//...
		}
	);
}

export const setSelectedBeacon = (beacon) => {
	return setSelectedBeaconAction(beacon);
};

export const clearSelectedBeacon = () => {
	return clearSelectedBeaconAction();
};

export const requestBeacons = (dispatch, account) => {
	getBeacons(dispatch, account);
	return requestFetchBeacons({});
};
