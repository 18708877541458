import seremyFire, { models } from 'seremy-firebase-data';

import { isNullOrUndefined } from 'utils/common';
import { getAccountWrapperFromDocumentSnapshot } from './account';

export const { AccountRoles, UserBaseWrapper } = models.user;

/**
 * Crea un oggetto wrapper dell0oggetto document snapshot
 * @param {DocumentSnapshot} user oggetto document snapshot dell'utente
 * @param {DocumentSnapshot} account oggetto account a cui la risorsa è legata. Default undefined
 * @returns oggetto userWrapper
 */
export const getUserWrapperFromDocumentSnapshot = (
  user,
  account = undefined,
) => {
  const wrapper = models.user.getUserWrapperFromDocumentSnapshot(
    seremyFire,
    user,
  );
  if (isNullOrUndefined(isNullOrUndefined)) {
    return undefined;
  }
  return {
    ...wrapper,
    account: getAccountWrapperFromDocumentSnapshot(account),
  };
};
