/* eslint react/no-children-prop: 0 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { ButtonLink, Link } from './style';

class LinkButtonComponent extends PureComponent {
  renderLink = React.forwardRef((itemProps, ref) => (
    <RouterLink to={this.props.to} {...itemProps} innerRef={ref} />
  ));

  renderExternalLink = React.forwardRef((itemProps, ref) => (
    <Link href={this.props.to} target="_blanck" {...itemProps} ref={ref} />
  ));

  render() {
    const { children, external, ...otherProps } = this.props;
    const component = external ? this.renderExternalLink : this.renderLink;
    return (
      <ButtonLink {...otherProps} component={component}>
        {children}
      </ButtonLink>
    );
  }
}

LinkButtonComponent.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  external: PropTypes.bool,
};

LinkButtonComponent.defaultProps = {
  external: false,
};

export default LinkButtonComponent;
