import { saveLog } from "firebaseManager";
import { deviceDetect } from "react-device-detect";

export default {
	code: {
		DEVELOPMENT_ERROR: 0,
		ERROR_BOUNDARY_CATCH: 1,
		USER_DATA_ACTIONS_GET_USER: 600,
		USER_DATA_ACTIONS_GET_ACCOUNT: 601,
		USER_DATA_ACTIONS_REFRESH_ACCOUNT: 602,
		USER_DATA_ACTIONS_WHOLE_FETCH: 603,
		AUTH_ACTIONS_CLEAR_USER_INSTALLATION: 650,
		CONFIGURATION_ACTIONS_FETCH_INDICATORS: 700,
		RESOURCES_ACTIONS_FETCH_RESOURCES: 750,
		WEARABLE_DEVICE_ACTIONS_FETCH_WEARABLE_DEVICES: 800,
		SUBSCRIPTIONS_ACTIONS_FETCH_SUBSCRIPTIONS: 850,
		LOGIN_CONFIRM_VERIFICATION_CODE: 1000,
		LOGIN_IN_WITH_PHONE_NUMBER: 1001,
		LOGIN_WITH_EMAIL: 1002,
		LOGIN_IN_CATCH: 1099,
		REGISTER_REGISTER_USER: 1100,
		REGISTER_CONFIRM_VERIFICATION_CODE: 1101,
		REGISTER_WITH_PHONE_NUMBER: 1102,
		REGISTER_LINK_EMAIL_PROVIDER: 1103,
		REGISTER_REGISTER_USER_CATCH: 1104,
		REGISTER_SIGN_UP_CATCH: 1199,
		PASSWORD_RECOVERY_SEND_REQUEST: 1200,
		ORDER_FETCH_ORDER: 1300,
		ORDERS_SUBSCRIBE_FETCH_ORDERS: 1400,
		ORDERS_PREPROCESS_ORDERS: 1401,
		ORDER_STARTER_FETCH_LAST_DRAFT_ORDER: 1500,
		ORDER_STARTER_FETCH_ORDER: 1501,
		ORDER_STARTER_ADD_ORDER: 1502,
		ORDER_STARTER_UPDATE_ORDER: 1503,
		ORDER_STARTER_UPDATE_RECIPIENT: 1504,
		ORDER_STARTER_PAY: 1505,
		ORDER_STARTER_CHECKOUT_FETCH_ACCOUNT_CARD: 1506,
		ORDER_STARTER_ERROR_SET_PAYMENT_METHOD: 1507,
		ORDER_UPDATE_PLAN_ORDER: 1508,
		INVITATION_CHECK: 1600,
		USER_FORM_SET_PAYMENT: 1700,
		USER_FORM_CREATE_CLIENT_SECRET: 1701,
		USER_FORM_UPDATE_USER_DATA: 1702,
		USER_FORM_LOAD_USER_UPDATED: 1703,
	},
	/**
	 * Visualizzare l'errore nella console
	 * @param {Number} code codice log errore
	 * @param {String} message messaggio
	 * @param {String} userIdentifier valore per identificare l'utente. Es: numero di telefono, email, id user (documento) etc...
	 */
	error: async (code, message = "", userIdentifier = "", exception = null) => {
		console.error(`message: ${message}`);
		const params = {
			code,
			type: "error",
			message,
			userIdentifier,
			page: window.location.href,
			deviceInfo: deviceDetect(),
			exception: exception
				? JSON.stringify(exception, Object.getOwnPropertyNames(exception))
				: null,
		};
		try {
			await saveLog(params);
		} catch (e) {
			console.error(`error save log: message: ${e.message}`);
		}
	},
	/**
	 * Visualizzare il messaggio nella console
	 * @param {String} message messaggio
	 * @param {Object} obj oggetto di cui fare il print
	 */
	info: (message, obj = {}) => {
		console.info(`message: ${message}`, obj);
	},
};
