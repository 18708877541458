import {
	getSubscriptionsQuery,
	getSubscriptions,
	getSubscription,
} from "firebaseManager";

import Logger from "utils/logger";
import Strings from "styles/strings";
import {
	REQUEST_FETCH_SUBSCRIPTIONS,
	RECEIVE_FETCH_SUBSCRIPTIONS,
	ERROR_FETCH_SUBSCRIPTIONS,
	RESET_SUBSCRIPTIONS,
	UPDATE_STORED_SUBSCRIPTIONS,
	SET_SELECTED_SUBSCRIPTION,
	CLEAR_SELECTED_SUBSCRIPTION,
} from "./types";

const requestFetchSubscriptions = () => ({
	type: REQUEST_FETCH_SUBSCRIPTIONS,
});

const receiveFetchSubscriptions = (subscriptions, selectedId, completeSubscriptions) => ({
	type: RECEIVE_FETCH_SUBSCRIPTIONS,
	subscriptions,
	completeSubscriptions,
	selectedId,
});

const errorFetchSubscriptions = (error) => ({
	type: ERROR_FETCH_SUBSCRIPTIONS,
	error,
});

export const resetSubscriptions = () => ({
	type: RESET_SUBSCRIPTIONS,
});

export const updateStoredSubscription = (subscription) => ({
	type: UPDATE_STORED_SUBSCRIPTIONS,
	subscription,
});

export const setSelectiondSubscription = (subscription) => ({
	type: SET_SELECTED_SUBSCRIPTION,
	subscription,
});

export const clearSelectiondSubscription = () => ({
	type: CLEAR_SELECTED_SUBSCRIPTION,
});

/**
 * Iscrizione al cambiamento della lista delle sottoscrizioni
 * @param {Object} userWrapper oggetto wrapper dell'utente
 * @param {Function} onNext funzione callback richiamata alla modifica del risultato della query
 * La funzione deve gestire una oggetto QuerySnapshot
 * @param {Function} onError funzione callback richiamata quando viene generato un errore
 * @return {Function} funzione di unsubscribe
 */
export const subscribeToFetchSubscriptionsAsync = async (
	userWrapper,
	onNext,
	onError = () => {}
) =>
	(
		await getSubscriptionsQuery(userWrapper.account.documentSnapshot)
	).onSnapshot(onNext, onError);

/**
 * Legge delle sottoscrizioni dell'account utente
 * salvando il risulatato sullo store di redux
 */
export const fetchSubscriptions = () => async (dispatch, getState) => {
	const { user, account } = getState().userData;
	dispatch(requestFetchSubscriptions());
	if (
		user === null ||
		user === undefined ||
		account === null ||
		account === undefined
	) {
		dispatch(errorFetchSubscriptions(Strings().messageInvalidAuth));
		return;
	}
	try {
		const fetchedSubscriptions = await getSubscriptions(account);
		if(fetchedSubscriptions.docs.length>0) {
			const completeSubscriptions = await Promise.all(
				fetchedSubscriptions.docs.map(async doc => {
					const subscriptionServices = await doc.ref
					.collection("subscriptionServices")
					.get();

					if(subscriptionServices && subscriptionServices.docs.length>0) {
						return {
							subscription: doc,
							services: subscriptionServices
						}
					}
					return {};
				
				})
			)
			dispatch(receiveFetchSubscriptions(fetchedSubscriptions.docs, null, completeSubscriptions));
		}
		else {
			dispatch(receiveFetchSubscriptions(fetchedSubscriptions.docs, null, []));
		}
	} catch (e) {
		Logger.error(
			Logger.code.SUBSCRIPTIONS_ACTIONS_FETCH_SUBSCRIPTIONS,
			e.message,
			user.id,
			e
		);
		dispatch(errorFetchSubscriptions(Strings().messageErrorFetchSubscriptions));
	}
};

/**
 * Legge le informazioni di una sottoscrizione
 * @param {Object} userWrapper oggetto wrapper dell'utente
 * @param {String} subscriptionId id sottoscrizione
 * @returns {Promise<DocumentSnapshot>} promise sottoscrizione
 */
export const fetchSubscription = (userWrapper, subscriptionId) =>
	getSubscription(userWrapper.account.documentSnapshot, subscriptionId);
