import React, { useState } from "react";
import { drawerItems } from "config/mobileAppBarr";
import { Link } from "react-router-dom";

import { AppBarButton, AppBarContainer, ButtonBarContainer } from "./style";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import Badge from "@material-ui/core/Badge";

const listToRender = (history, allActiveAlarms) => {
	const menu = drawerItems.map((item, index) => {
		return (
			<AppBarButton
				key={index}
				component={Link}
				label={item.text}
				icon={
					item.showBadge ? (
						<Badge badgeContent={allActiveAlarms} color="secondary">
							{item.icon}
						</Badge>
					) : (
						item.icon
					)
				}
				to={item.path}
				value={index}
				// onClick={() => {
				//   history.push(item.path);
				// }}
				classes={{
					root: "my-root-class",
					selected: "my-selected-class",
					label: "my-label-class",
				}}
			></AppBarButton>
		);
	});
	return menu;
};

const MobileAppBarComponent = (props) => {
	var [value, setValue] = useState(0);
	const history = useHistory();

	let location = useLocation();

	value = 3;
	if (location.pathname.match(/dashboard/gi)) value = 0;
	if (location.pathname.match(/alarms/gi)) value = 1;
	if (location.pathname.match(/resources\//gi)) value = 2;
	if (location.pathname.match(/resources/gi)) value = 2;
	if (location.pathname.match(/resources-edit/gi)) value = 3;

	// drawerItems.map((item, index) => {
	// 	if (location.pathname === item.path) value = index;
	// });
	// if (window.location.href.match(/orders/gi)) value true;
	var selected = value;

	return (
		<ButtonBarContainer>
			<AppBarContainer
				value={selected}
				onChange={(event, newValue) => {
					setValue(newValue);
				}}
				showLabels
			>
				{listToRender(history, props.allActiveAlarms)}
			</AppBarContainer>
		</ButtonBarContainer>
	);
};

export default MobileAppBarComponent;
