import styled from 'styled-components';
import LabelBase from 'components/Label';
import Button from 'components/Button';

import Dimens from 'styles/dimens';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${Dimens.paddingNormal};
`;

export const Label = styled(LabelBase)`
  margin-bottom: ${Dimens.marginNormal};
`;

export const ActionButton = styled(Button)``;
