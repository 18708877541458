export default {
	// Common
	paddingExtraSmall: "5px",
	paddingSmall: "10px",
	paddingNormal: "20px",
	paddingLarge: "30px",
	marginExtraSmall: "5px",
	marginSmall: "10px",
	marginNormal: "20px",
	marginLarge: "30px",
	marginExtraLarge: "40px",
	marginExtraExtraLarge: "60px",

	drawerWidth: "250px",
	drawerLogoHeight: "30px",
	drawerLogoWidth: "110px",

	appBarHeight: "65px",

	// Input
	inputTextSize: "1.0rem",
	labelTextSizeTitle: "1.6rem",
	labelTextSizeSmallTitle: "1.3rem",
	labelTextSizeLarge: "1.2rem",
	labelTextSize: "1.0rem",
	labelTextSizeSmall: "0.9rem",
	labelTextSizeExtraSmall: "0.8rem",
	labelTextSizeExtraXSmall: "0.75rem",

	labelTextChartSubtitle: "0.8rem",
	labelTextChartSubtitleLine: "0.9rem",
	labelTextChartTitle: "1.6rem",
	labelTextChartNumber: "1rem",
	gaugeRating: "2rem",
	gaugeRatingSmall: "1.6rem",

	tabulatorChartTitle: "3rem",
	tabulatorTitle: "3rem",
	tabulatorTitleLineHeight: "3.8rem",
	tabulatorDesc: "0.7rem",

	// Fields
	fieldMarginTop: "16px",
	fieldMarginBottom: "8px",

	// Button
	buttonHeight: "48px",
	buttonPadding: "0 30px",
	buttonTextSizeSmall: "0.7rem",
	// Button Switch
	buttonSwitchFontSize: "0.8rem",

	// Step
	stepsMaxWidth: "500px",
	stepPaddingVertical: "5px",
	stepPaddingHorizontal: "10px",
	stepTitleTextSize: "0.7rem",
	stepBorderRadius: "20px",

	// Phone Input
	phoneInputSelectOptionsHeight: 200,
	phoneInputSelectOptionsWidth: 300,
	phoneInputSelectOptionsItemHeight: 48,
	phoneInputSelectOptionFlagHeight: "20px",

	// Credit card
	creditCardBorderRadius: "4px",
	creditCardLogoWidth: "40px",
	creditCardLogoHeight: "40px",

	// Card
	cardBorderRadius: "4px",

	// Table
	tableCellPadding: "14px",

	// Auths Pages (login e registration)
	authFormMaxWidth: "300px",
	authLogoSize: "250px",
	authFormContainerMaxWidth: "400px",

	// Dialog
	dialogTitleTextSize: "1.2rem",
	dialogDescriptionTextSize: "1.0rem",

	// Order Starter - Wearable Device Carousel
	wearableDeviceCarouselSliderWidth: "300px",
	wearableDeviceCarouselSlideHeight: "205px",
	wearableDeviceCarouselSlideContentWidth: "300px",
	wearableDeviceCarouselImageHeightSelected: "200px",
	wearableDeviceCarouselImageHeight: `${200 * 0.8}px`,
	orderStarterWearableDeviceDescriptionMaxWidth: "600px",
	orderStarterContentActionsHeight: "70px",
	// Order Starter - Subscription
	orderStarterSubscriptionNameTextSize: "1.4rem",
	orderStarterSubscriptionPriceTextSize: "2rem",
	orderStarterSubscriptionDurationTextSize: "1.2rem",
	orderStarterSubscriptionContainerMaxHeight: "200px",
	orderStarterSubscriptionContainerMaxWidth: "200px",
	orderStarterSubscriptionContainerMinHeight: "200px",
	orderStarterSubscriptionContainerMinWidth: "200px",
	orderStarterSubscriptionContainerBorderRadius: "20px",
	// Order Starter - Recipient Info
	orderStarterRecipientInfoContainerMaxWidth: "800px",
	// Order Starter - Checkout
	orderStarterCheckoutOrderPreviewImageHeight: "80px",
	// Order Starter Preview
	orderPreviewWidth: "300px",
	orderPreviewProductImageHeight: "40px",

	// Color Indicator
	colorIndicatorSize: "15px",

	//devices
	mobileS: "320px",
	mobileM: "375px",
	mobileL: "425px",
	tablet: "768px",
	laptop: "1024px",
	laptopL: "1440px",
	desktop: "2560px",

	chartPaddingTop: "20",

	securityLevel1: "50",
	securityLevel2: "80",
};
