import {
	REQUEST_FETCH_CONFIGURATION,
	RECEIVE_FETCH_CONFIGURATION,
	ERROR_FETCH_CONFIGURATION,
	RESET_CONFIGURATION,
} from "../actions/types";

const initialState = {
	isFetching: false,
	completed: false,
	indicatorDefs: [], // Lista oggetti DocumentSnapshot
	products: [], // Lista oggetti DocumentSnapshot
	shopProducts: [],// Lista oggetti DocumentSnapshot
	error: null,
};

/**
 * Reducers con dati di configurazione o statici (letti da tabelle su firebase ma che non cambiano)
 */
const configuration = (state = initialState, action) => {
	switch (action.type) {
		case REQUEST_FETCH_CONFIGURATION:
			return {
				...state,
				isFetching: true,
				completed: false,
				error: null,
			};
		case RECEIVE_FETCH_CONFIGURATION:
			return {
				...state,
				isFetching: false,
				completed: true,
				indicatorDefs: action.indicatorDefs,
				products: action.products,
				shopProducts: action.shopProducts,
				error: null,
			};
		case ERROR_FETCH_CONFIGURATION:
			return {
				...state,
				isFetching: false,
				completed: false,
				indicatorDefs: [],
				products: [],
				shopProducts: [],
				error: action.error,
			};
		case RESET_CONFIGURATION:
			return initialState;
		default:
			return state;
	}
};

export default configuration;
