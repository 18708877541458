import React from 'react';
import PropTypes from 'prop-types';

import { Container, CircularProgress } from './style';

const CircularProgressComponent = ({ className, floating, ...props }) => (
  <Container
    className={`${className} ${floating ? 'floating' : ''}`}
    {...props}
  >
    <CircularProgress {...props} />
  </Container>
);

CircularProgressComponent.propTypes = {
  className: PropTypes.string,
  floating: PropTypes.bool,
};

CircularProgressComponent.defaultProps = {
  className: '',
  floating: false,
};

export default CircularProgressComponent;
