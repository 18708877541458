import styled from "styled-components";
import ButtonComponent from "components/Button";
import dimens from "styles/dimens";

export const Container = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
`;

export const ConfirmButton = styled(ButtonComponent)`
	&& {
		margin: ${dimens.marginSmall};
	}
`;

export const ContentProgress = styled.div`
	display: flex;
	width: 500px;
	height: 200px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
