import React from "react";
import { StylesProvider } from "@material-ui/styles";
import { BrowserRouter as Router } from "react-router-dom";

import ErrorBoundary from "components/ErrorBoundary";
import GlobalStyle from "styles/globalStyle";

import App from "pages/App";

const Root = () => (
	<StylesProvider injectFirst>
		<GlobalStyle />
		<Router>
			<ErrorBoundary>
				<App />
			</ErrorBoundary>
		</Router>
	</StylesProvider>
);

export default Root;
