import { REQUEST_FETCH_ALL_PROXIMITY } from "../actions/types";
import { RECEIVE_FETCH_ALL_PROXIMITY } from "../actions/types";
import { ERROR_FETCH_ALL_PROXIMITY } from "../actions/types";
import { CLEAR_ALL_PROXIMITY } from "../actions/types";

const initialState = {
	overallProximities: [],
	isFetching: false,
	error: null,
};

const proximity = (
	state = Object.assign({}, initialState, { error: false }),
	action
) => {
	switch (action.type) {
		case CLEAR_ALL_PROXIMITY:
			return initialState;
		case REQUEST_FETCH_ALL_PROXIMITY:
			return {
				...state,
				isFetching: true,
				error: null,
				overallProximities: [],
			};
		case RECEIVE_FETCH_ALL_PROXIMITY:
			return {
				...state,
				isFetching: false,
				error: null,
				overallProximities: action.overallProximities,
			};
		case ERROR_FETCH_ALL_PROXIMITY:
			return {
				...state,
				isFetching: false,
				error: action.error,
				overallProximities: null,
			};
		default:
			return state;
	}
};

export default proximity;
