import { REQUEST_FETCH_ALL_NOTIFICATIONS } from "../actions/types";
import { RECEIVE_FETCH_ALL_NOTIFICATIONS } from "../actions/types";
import { ERROR_FETCH_ALL_NOTIFICATIONS } from "../actions/types";
import { CLEAR_NOTIFICATION_SOUND } from "../actions/types";
import { CLEAR_ALL_NOTIFICATIONS } from "../actions/types";
import { SET_NOTIFICATION_SOUND } from "../actions/types";
import { CLEAR_NOTIFICATION_ALERT } from "../actions/types";
import { SET_NOTIFICATION_ALERT } from "../actions/types";
import { inArray } from "utils/common";
import moment from "moment";

const alarmSound = new Audio("assets/sounds/alarm.mp3");

const initialState = {
	notifications: [],
	sentNotifications: 0,
	alarmNotifications: [],
	isFetching: false,
	playNotification: false,
	showNotificationAlert: false,
	error: null,
	notifiedAlarms: [],
	startingDate: moment(),
};

const notifications = (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_ALL_NOTIFICATIONS: {
			return initialState;
		}
		case SET_NOTIFICATION_ALERT:
			return {
				...state,
				showNotificationAlert: true,
			};
		case CLEAR_NOTIFICATION_ALERT:
			return {
				...state,
				showNotificationAlert: false,
			};
		case SET_NOTIFICATION_SOUND:
			return {
				...state,
				playNotification: true,
			};
		case CLEAR_NOTIFICATION_SOUND:
			return {
				...state,
				playNotification: false,
			};
		case REQUEST_FETCH_ALL_NOTIFICATIONS:
			return {
				...state,
				notifications: [],
				alarmNotifications: [],
				isFetching: true,
				error: null,
			};
		case RECEIVE_FETCH_ALL_NOTIFICATIONS:
			var notifications = [];
			var alarmNotifications = [];
			var sentNotifications = 0;
			var playNotification = false;
			if (action.notifications.docs) {
				action.notifications.docs.forEach((element) => {
					notifications.push(element);
					if (element.data().status == "sent") {
						sentNotifications++;
						if (element.data().type == "alarm") {
							alarmNotifications.push(element);
							var alarmDate = moment.unix(element.data().createdAt.seconds);
							if (alarmDate > state.startingDate) {
								if (!inArray(element.id, state.notifiedAlarms)) {
									state.notifiedAlarms.push(element.id);
									playNotification = true;
								}
							}
						}
					}
				});
			}
			return {
				...state,
				notifications: notifications,
				sentNotifications: sentNotifications,
				alarmNotifications: alarmNotifications,
				playNotification: playNotification,
				isFetching: false,
				error: null,
			};
		case ERROR_FETCH_ALL_NOTIFICATIONS:
			return {
				...state,
				isFetching: false,
				error: action.error,
				notifications: [],
				alarmNotifications: [],
			};
		default:
			return state;
	}
};

export default notifications;
