import { REQUEST_FETCH_ALL_BEACON } from "../actions/types";
import { RECEIVE_FETCH_ALL_BEACON } from "../actions/types";
import { ERROR_FETCH_ALL_BEACON } from "../actions/types";
import { SET_SELECTED_BEACON } from "../actions/types";
import { CLEAR_SELECTED_BEACON } from "../actions/types";
import { CLEAR_ALL_BEACONS } from "../actions/types";

const initialState = {
	beacons: [],
	selectedBeacon: null,
	isFetching: false,
	error: null,
};

const beacons = (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_ALL_BEACONS: {
			return initialState;
		}
		case SET_SELECTED_BEACON: {
			return {
				...state,
				selectedBeacon: action.beacon,
			};
		}
		case CLEAR_SELECTED_BEACON: {
			return {
				...state,
				selectedBeacon: null,
			};
		}
		case REQUEST_FETCH_ALL_BEACON:
			return {
				...state,
				beacons: [],
				isFetching: true,
				error: null,
			};
		case RECEIVE_FETCH_ALL_BEACON:
			var selectedBeacon = state.selectedBeacon;
			if (state.selectedBeacon) {
				selectedBeacon = action.beacons.docs.find(
					(obj) => obj.id == state.selectedBeacon.id
				);
			}
			return {
				...state,
				selectedBeacon: selectedBeacon,
				beacons: action.beacons.docs,
				isFetching: false,
				error: null,
			};
		case ERROR_FETCH_ALL_BEACON:
			return {
				...state,
				isFetching: false,
				error: action.error,
				beacons: null,
			};
		default:
			return state;
	}
};

export default beacons;
