import React from "react";
import Proptypes from "prop-types";
import HiddenMUI from "@material-ui/core/Hidden";

import { drawerItems } from "config/drawerEndUser";
import Strings from "styles/strings";

import { HELP_URL, SITE_URL } from "config";

import logoSeremy from "assets/logo_seremy_full.png";
import { ReactComponent as HelpIcon } from "assets/ic_help.svg";
import { ReactComponent as ExpandIcon } from "assets/ic_expand.svg";
import { ReactComponent as CollapseIcon } from "assets/ic_collapse.svg";

import Item from "./Item";
import * as Icons from "components/Icons/style";
import { MENU } from "routes/paths";

import {
	Nav,
	Drawer,
	Group,
	Header,
	Footer,
	Username,
	Collapse,
	LogoLink,
	Logo,
} from "./style";

const DrawerComponent = ({
	onDrawerToggle,
	mobileOpen,
	userWrapper,
	onLogout,
}) => {
	const [open, setToggleSettings] = React.useState(false);

	function onAccountItemClick() {
		setToggleSettings(!open);
	}

	const accountSettingsToRender = () => {
		return (
			<Group>
				<Item
					type="button"
					text={Strings().drawerItemMenu}
					onClick={onAccountItemClick}
					iconSecondary={open ? CollapseIcon : ExpandIcon}
				/>
				<Collapse in={open} timeout="auto" unmountOnExit>
					<Item
						text={Strings().drawerItemMenu}
						icon={Icons.MenuIcon}
						to={MENU}
					/>
					<Item
						type="button"
						text={Strings().drawerAccountMenuLogout}
						icon={Icons.LogoutIcon}
						onClick={onLogout}
					/>
				</Collapse>
			</Group>
		);
	};

	const headerToRender = () => (
		<Header>
			<LogoLink href={SITE_URL}>
				<Logo src={logoSeremy} />
			</LogoLink>
			<Username>{userWrapper.name}</Username>
		</Header>
	);

	const footerToRender = () => (
		<Footer>
			<Item
				type="external_url"
				text={Strings().drawerHelp}
				icon={HelpIcon}
				to={HELP_URL}
				onClick={onDrawerToggle}
			/>
		</Footer>
	);

	const listToRender = () =>
		drawerItems.map((section, i) => (
			<Group key={`group-${i}`}>
				{section.map((item) => (
					<Item
						key={item.text}
						text={item.text}
						icon={item.icon}
						to={item.path}
						type={item.type}
						// activeOnlyWhenExact={item.exact}
						onClick={onDrawerToggle}
					/>
				))}
			</Group>
		));

	const contentToRender = () => (
		<>
			{headerToRender()}
			{accountSettingsToRender()}
			{listToRender()}
			{footerToRender()}
		</>
	);

	return (
		<Nav aria-label="Menu">
			<HiddenMUI mdUp implementation="js">
				<Drawer
					key="mobile-drawer"
					variant="temporary"
					anchor="left"
					open={mobileOpen}
					onClose={onDrawerToggle}
					ModalProps={{
						keepMounted: true,
					}}
				>
					{contentToRender()}
				</Drawer>
			</HiddenMUI>
			<HiddenMUI smDown implementation="js">
				<Drawer key="desktop-drawer" variant="permanent" open>
					{contentToRender()}
				</Drawer>
			</HiddenMUI>
		</Nav>
	);
};

DrawerComponent.propTypes = {
	onDrawerToggle: Proptypes.func.isRequired,
	mobileOpen: Proptypes.bool.isRequired,
	userWrapper: Proptypes.shape(),
	onLogout: Proptypes.func.isRequired,
};

DrawerComponent.defaultProps = {
	userWrapper: undefined,
};

export default DrawerComponent;
