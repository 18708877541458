import { REQUEST_FETCH_RESOURCE_ALARMS } from "../actions/types";
import { RECEIVE_FETCH_RESOURCE_ALARMS } from "../actions/types";
import { ERROR_FETCH_RESOURCE_ALARMS } from "../actions/types";
import { UPDATE_FETCH_RESOURCE_ALARMS } from "../actions/types";

import { REQUEST_FETCH_ALL_ALARMS } from "../actions/types";
import { RECEIVE_FETCH_ALL_ALARMS } from "../actions/types";
import { ERROR_FETCH_ALL_ALARMS } from "../actions/types";
import { UPDATE_FETCH_ALL_ALARMS } from "../actions/types";
import { RECEIVE_ALARMS_SNAPSHOT } from "../actions/types";
import { SET_EXTERNAL_SELECTED_ALARM } from "../actions/types";

const initialState = {
	selectedResourceAlarms: [],
	activeAlarms: 0,
	allActiveAlarms: 0,
	isFetching: false,
	allAlarms: [],
	allResourceAlarms: [],
	allAlarmsSnapShot: [],
	error: null,
	newAlarms: 0,
	firstDownload: false,
	externalSelectedAlarm: null,
};

const alarms = (state = initialState, action) => {
	switch (action.type) {
		case RECEIVE_ALARMS_SNAPSHOT:
			var allAlarmsSnapShot = state.allAlarmsSnapShot;
			var newAlarms = 0;
			var allActiveAlarms = 0;
			allAlarmsSnapShot.forEach((value, index) => {
				if (!value.data().cleared) {
					newAlarms = newAlarms + 1;
					allActiveAlarms = allActiveAlarms + 1;
				}
			});
			action.alarms.forEach((alarm) => {
				var found = false;
				allAlarmsSnapShot.forEach((value, index) => {
					if (value.id === alarm.id) {
						allAlarmsSnapShot[index] = alarm;
						found = true;
					}
				});
				if (!found) {
					allAlarmsSnapShot.push(alarm);
				}
			});
			const res = {
				...state,
				allAlarmsSnapShot: allAlarmsSnapShot,
				newAlarms: newAlarms,
				allActiveAlarms: allActiveAlarms,
			};
			return res;
		case REQUEST_FETCH_RESOURCE_ALARMS:
			return {
				...state,
				isFetching: true,
				error: null,
				activeAlarms: 0,
				selectedResourceAlarms: null,
			};
		case REQUEST_FETCH_ALL_ALARMS:
			return {
				...state,
				isFetching: true,
				error: null,
				allAlarms: [],
				allResourceAlarms: [],
				allActiveAlarms: 0,
			};
		case UPDATE_FETCH_RESOURCE_ALARMS:
			return {
				...state,
			};
		case UPDATE_FETCH_ALL_ALARMS:
			return {
				...state,
			};
		case RECEIVE_FETCH_RESOURCE_ALARMS:
			var activeAlarms = 0;
			action.alarms.forEach((alarm) => {
				var tmp = alarm.data();
				if (!tmp.cleared) activeAlarms++;
			});
			return {
				...state,
				isFetching: false,
				error: null,
				selectedResourceAlarms: action.alarms,
				activeAlarms: activeAlarms,
			};
		case RECEIVE_FETCH_ALL_ALARMS:
			var allActiveAlarms = 0;
			action.alarms.forEach((alarm) => {
				var tmp = alarm.data();
				if (!tmp.cleared) allActiveAlarms++;
			});
			return {
				...state,
				isFetching: false,
				error: null,
				allAlarms: action.alarms,
				allResourceAlarms: action.allResourceAlarms,
				allActiveAlarms: allActiveAlarms,
			};
		case ERROR_FETCH_RESOURCE_ALARMS:
			return {
				...state,
				isFetching: false,
				error: action.error,
				activeAlarms: 0,
				selectedResourceAlarms: null,
			};
		case ERROR_FETCH_ALL_ALARMS:
			return {
				...state,
				isFetching: false,
				error: action.error,
				allActiveAlarms: 0,
				allAlarms: [],
				allResourceAlarms: [],
			};
		case SET_EXTERNAL_SELECTED_ALARM:
			return {
				...state,
				externalSelectedAlarm: action.alarm,
			};

		default:
			return state;
	}
};

export default alarms;
