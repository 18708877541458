import * as colors from "./colors";
import Dimens from "./dimens";

export default {
	primaryColor: colors.primaryColor,
	secondaryColor: "#0f0",
	primaryTextColor: colors.primaryTextColor,
	secondaryTextColor: colors.secondaryTextColor,
	windowBackgroundColor: colors.windowBackgroundColor,
	appBarBackgroundColor: colors.appBarBackgroundColor,
	dividerBorder: `1px solid ${colors.dividerColor}`,
	mobileAppBarBorder: `1px solid ${colors.mobileAppBarBorderColor}`,
	// Labels Base
	labelTitle: `
    font-size: ${Dimens.labelTextSizeTitle};
    color: ${colors.primaryTextColor};
    font-weight: 700;
  `,
	labelPrimary: `
    font-size: ${Dimens.labelTextSize};
    color: ${colors.primaryTextColor};
  `,
	labelSecondary: `
    font-size: ${Dimens.labelTextSize};
    color: ${colors.secondaryTextColor};
  `,
	labelSecondarySmall: `
    font-size: ${Dimens.labelTextSizeSmall};
    color: ${colors.secondaryTextColor};
  `,
	// Button Base
	button: `
    font-family: 'Heebo', sans-serif;
    border-radius: 3px;
    border: 0;
    color: #000;
    height: ${Dimens.buttonHeight};
    padding: ${Dimens.buttonPadding};
    line-height: 1rem;
  `,
	buttonDisabled: `
    color: ${colors.buttonDisabledTextColor};
    background: ${colors.buttonDisabledBackgroundColor}
`,
	buttonPrimary: `
    color: ${colors.buttonPrimaryTextColor};
    background: ${colors.primaryColor};
`,
	buttonSecondary: `
    color: ${colors.buttonSecondaryTextColor};
    background: ${colors.buttonSecondaryBackgroundColor} !important;
  `,
	buttonGreen: `
    color: ${colors.buttonGreenTextColor};
    background: ${colors.buttonGreenBackgroundColor}
    
    &:hover {
      background: ${colors.buttonGreenHoverBackgroundColor};
    }
  `,
	buttonSimple: `
    color: ${colors.buttonSimpleTextColor};
    background: ${colors.buttonSimpleBackgroundColor}

    & svg {
      fill: ${colors.buttonSimpleTextColor};
    }
  `,
	buttonText: `
    color: ${colors.buttonSecondaryTextColor};
    background: ${colors.buttonSimpleBackgroundColor}

    & svg {
      fill: ${colors.buttonSimpleTextColor};
    }
  `,
  buttonGreenText: `
    color: ${colors.buttonGreenBackgroundColor};
    background: ${colors.buttonSimpleBackgroundColor}

    & svg {
      fill: ${colors.buttonSimpleTextColor};
    }
  `,
  buttonSimpleRound: `
    color: ${colors.buttonSecondaryTextColor};
    background: ${colors.buttonSimpleBackgroundColor};
    border-radius: 50%;
    font-size: x-large;
  `,
	textButton: `
    border-radius: 3px;
    border: 0;
    color: #000;
    height: ${Dimens.buttonHeight};
    padding: ${Dimens.buttonPadding};
  `,
	textButtonPrimary: `
    color: ${colors.textButtonPrimaryTextColor};
`,
	textButtonSecondary: `
    color: ${colors.textButtonSecondaryTextColor};
  `,
	// Dialog
	dialogTitle: `
    font-size: ${Dimens.dialogTitleTextSize};
    color: ${colors.primaryTextColor};
  `,
	dialogDescription: `
    font-size: ${Dimens.dialogDescriptionTextSize};
    color: ${colors.primaryTextColor};
  `,
	// Tabel
	tableCell: `
    padding: ${Dimens.tableCellPadding}
  `,
	chartSelector: `
    font-size: ${Dimens.labelTextSizeExtraSmall};
  `,

	mobileButtonBarBackground: `
    background-color: ${colors.mobileButtonBarBackgroundColor}
  `,
};
