import styled from "styled-components";
import DrawerMUI from "@material-ui/core/Drawer";
import CollapseMUI from "@material-ui/core/Collapse";

import Dimens from "styles/dimens";
import theme from "styles/theme";

export const Nav = styled.nav``;

export const Drawer = styled(DrawerMUI)`
	width: ${Dimens.drawerWidth};

	& .MuiDrawer-paper {
		width: ${Dimens.drawerWidth};
	}
`;

export const Group = styled.div`
	margin-bottom: 30px;
`;

export const LogoLink = styled.a``;

export const Logo = styled.img`
	height: ${Dimens.drawerLogoHeight};
`;

export const Header = styled.div`
	padding: ${Dimens.paddingNormal};
	padding-bottom: 0;
`;

export const Footer = styled.div`
	height: 100%;
	display: flex;
	align-items: flex-end;
`;

export const Username = styled.h3`
	${theme.labelTitle}
`;

export const Collapse = styled(CollapseMUI)``;
