import { REQUEST_FETCH_ALL_PROXIMITY } from "./types";
import { RECEIVE_FETCH_ALL_PROXIMITY } from "./types";
import { ERROR_FETCH_ALL_PROXIMITY } from "./types";
import { CLEAR_ALL_PROXIMITY } from "./types";
var moment = require("moment");

export const clearAllProximities = () => ({
	type: CLEAR_ALL_PROXIMITY,
});

const requestFetchOverallProximity = () => ({
	type: REQUEST_FETCH_ALL_PROXIMITY,
});

const receiveFetchOverallProximity = (overallProximities) => ({
	type: RECEIVE_FETCH_ALL_PROXIMITY,
	overallProximities,
});

const errorFetchOverallProximity = (error) => ({
	type: ERROR_FETCH_ALL_PROXIMITY,
	error,
});

async function getOverallProximity(dispatch, account) {
	const hardlimit = 7;
	account.ref
		.collection("overallProximitiesDaily")
		.where("day", ">=", moment().subtract(hardlimit, "days").toDate())
		.where("day", "<=", moment().toDate())
		.orderBy("day", "desc")
		.get()
		.then((data) => {
			dispatch(receiveFetchOverallProximity(data.docs));
		})
		.catch((error) => {
			dispatch(errorFetchOverallProximity(error));
		});
}

export const requestOverallProximity = (dispatch, account) => {
	getOverallProximity(dispatch, account);
	return requestFetchOverallProximity({});
};
