import styled from "styled-components";
import theme from "styles/theme";

export const ButtonBarContainer = styled.div`
	background-color: ${theme.appBarBackgroundColor};
	border-top: ${theme.dividerBorder};
	position: fixed;
	bottom: 0;
	width: 100%;
`;

export const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const AppContainer = styled.div`
	order: 1;
	flex: 1 1;
	padding-bottom: 78px;
`;
