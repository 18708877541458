import {
	registerUser,
	signinWithPhoneNumber,
	signInWithEmailAndPassword,
	linkUserWithEmailProviderAsync,
	onAuthStateChanged,
	currentUser,
	signOut,
	sendPasswordResetEmail,
} from "firebaseManager";

import {
	START_AUTHENTICATION,
	AUTHENTICATION_COMPLETED,
	CLEAR_ACCOUNT_PROFILE,
	REDIRECT_TO_REGISTER,
} from "./types";
import { resetUserData } from "./userDataActions";
import { resetResources } from "./resourceActions";
import { resetWearableDevices } from "./wearableDeviceActions";
import { resetSubscriptions } from "./subscriptionActions";
import { reserInvitations } from "./invitationsActions";
import { clearAllProximities } from "./proximityActions";
import { clearAllBeaconAction } from "./beaconsActions";
import { clearAllNotficationsAction } from "./notificationsActions";
import { clearAllPayments } from "./paymentsActions";
import {
	clearPendingReferral,
	clearApprovedReferral,
} from "./referralsActions";
import "whatwg-fetch";

export const startAuthentication = () => ({
	type: START_AUTHENTICATION,
});

export const authenticationCompleted = () => ({
	type: AUTHENTICATION_COMPLETED,
});

export const redirectToRegister = (value) => ({
	type: REDIRECT_TO_REGISTER,
	value: value,
});

export const clearAccountProfile = () => ({
	type: CLEAR_ACCOUNT_PROFILE,
});

/**
 * Richiama la function di firebase che permette di eseguire
 * una nuovoa registrazione dell'utente
 * @param {Object} userInfo oggetto cone le informazioni dell'utente
 */
export const register = (userInfo) =>
	registerUser({ ...userInfo, registeredFrom: "web" });

export const getCurrentUser = () => currentUser();

/**
 * Accesso tramite numero di telefono
 * @param {String} phoneNumber numero di telefono
 * @param {ApplicationVerifier} appVerifier verifica recaptcha
 * @returns oggetto result con cui è possibile richiamare la funzione "confirm("codiceVerifica")"
 * per eseguire la verifica del codice inviato tramite sms
 */
export const signinWithPhone = (phoneNumber, appVerifier) =>
	signinWithPhoneNumber(phoneNumber, appVerifier);

/**
 * Accesso tramite email e password
 * @param {String} email email
 * @param {String} password password
 * @returns {Promise<UserCredential>} promise signin con informazioni utente firebase
 */
export const signinWithEmail = (email, password) =>
	signInWithEmailAndPassword(email, password);

/**
 * Dopo che è stato eseguito il signin tramite numero di telefono,
 * esegue il link del provider di autenticazione con mail con quello di email
 * con i parametri di accesso indicati.
 * Se l'operazione di link avviene con successo viene inviata una mail di conferma
 * email all'utente
 * @param {String} email email utente
 * @param {String} password password
 * @returns {Object} oggetto utente firebase
 */
export const linkUserWithEmailAsync = async (email, password) => {
	const userCreated = await linkUserWithEmailProviderAsync(
		getCurrentUser(),
		email,
		password
	);
	return userCreated.user;
};

export const recoveryPassword = (email) => sendPasswordResetEmail(email);

/**
 * Iscrizione all'evento di cambiameto dello stato di autorizzazione
 * @param {Function} listener metodo callback richiamato quando viene rilevata una modifica
 * @returns funzione di unsubscribe
 */
export const subscribeToAuthStateChange = (listener) =>
	onAuthStateChanged(listener);

/**
 * Esegue il signout
 */
export const signOutAsync = async () => {
	try {
		await signOut();
	} catch (e) {
		// ignora l'errore e continuo con il processo di logout
	}
};

/**
 * Esegue il signout dell'utente e rimuove tutti i dati salvati
 * Al completamente della procedura viene visualizzata la schermata
 * di signin
 */
export const signOutAndClearData = () => async (dispatch) => {
	dispatch(resetResources());
	dispatch(resetWearableDevices());
	dispatch(resetSubscriptions());
	dispatch(startAuthentication());
	dispatch(clearAccountProfile());
	dispatch(clearAllProximities());
	dispatch(clearAllBeaconAction());
	dispatch(clearAllNotficationsAction());
	dispatch(clearAllPayments());
	dispatch(reserInvitations());
	dispatch(resetUserData());
	await signOutAsync();
};

export const clearData = () => async (dispatch) => {
	dispatch(resetUserData());
	dispatch(resetResources());
	dispatch(resetWearableDevices());
	dispatch(resetSubscriptions());
	dispatch(clearAccountProfile());
	dispatch(clearAllProximities());
	dispatch(clearAllBeaconAction());
	dispatch(clearAllPayments());
	dispatch(reserInvitations());
	dispatch(clearPendingReferral());
	dispatch(clearApprovedReferral());
	dispatch(clearAllNotficationsAction());
};
