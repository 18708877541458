import styled from 'styled-components';
import ListItemMUI from '@material-ui/core/ListItem';
import ListItemTextMUI from '@material-ui/core/ListItemText';
import ListItemIconMUI from '@material-ui/core/ListItemIcon';
import LinkMUI from '@material-ui/core/Link';

import { drawerItemColor, drawerItemColorSelected } from 'styles/colors';

export const Item = styled(ListItemMUI)``;

export const Link = styled(LinkMUI)`
  display: block;
  width: 100%;

  &:hover {
    text-decoration: none;
  }
`;

export const Text = styled(ListItemTextMUI)`
  color: ${drawerItemColor};
  text-transform: uppercase;
  margin-left: 20px;

  & span {
    font-weight: 700;
    font-size: 0.9rem;
  }

  ${Item}.selected &.overrideselectedcolor {
    color: ${drawerItemColorSelected};
  }
`;

export const Icon = styled(ListItemIconMUI)`
  min-width: 26px !important;
  & svg {
    fill: ${drawerItemColor};
  }

  ${Item}.selected &.overrideselectedcolor svg {
    fill: ${drawerItemColorSelected};
  }
`;

export const IconSecondary = styled(Icon)`
  min-width: auto;
`;
