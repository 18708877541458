import { REQUEST_FETCH_ALL_USERS } from "../actions/types";
import { RECEIVE_FETCH_ALL_USERS } from "../actions/types";
import { ERROR_FETCH_ALL_USERS } from "../actions/types";
import { SET_SELECTED_USERS } from "../actions/types";
import { CLEAR_SELECTED_USERS } from "../actions/types";
import { CLEAR_ALL_USERS } from "../actions/types";
import { SET_SELECTED_USER_VISIBILITY } from "../actions/types";
import { REQUEST_USER_VISIBILITY } from "../actions/types";

const initialState = {
	users: [],
	selectedUser: null,
	selectedUserResource: null,
	userResourceFetching: false,
	isFetching: false,
	error: null,
};

const users = (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_ALL_USERS: {
			return initialState;
		}
		case REQUEST_USER_VISIBILITY: {
			return {
				...state,
				userResourceFetching: true,
			};
		}
		case SET_SELECTED_USER_VISIBILITY: {
			return {
				...state,
				userResourceFetching: false,
				selectedUserResource: action.resouces.docs,
			};
		}
		case SET_SELECTED_USERS: {
			return {
				...state,
				selectedUser: action.user,
				selectedUserResource: action.resouces.docs,
			};
		}
		case CLEAR_SELECTED_USERS: {
			return {
				...state,
				selectedUser: null,
			};
		}
		case REQUEST_FETCH_ALL_USERS:
			return {
				...state,
				users: [],
				isFetching: true,
				error: null,
			};
		case RECEIVE_FETCH_ALL_USERS:
			var selectedUser = state.selectedUser;
			if (state.selectedUser) {
				selectedUser = action.users.docs.find(
					(obj) => obj.id == state.selectedUser.id
				);
			}
			return {
				...state,
				selectedUser: selectedUser,
				users: action.users.docs,
				isFetching: false,
				error: null,
			};
		case ERROR_FETCH_ALL_USERS:
			return {
				...state,
				isFetching: false,
				error: action.error,
				users: null,
			};
		default:
			return state;
	}
};

export default users;
