import {
	START_AUTHENTICATION,
	REDIRECT_TO_REGISTER,
	AUTHENTICATION_COMPLETED,
} from "actions/types";

const initialState = {
	isAuthenticating: false,
	redirectToRegister: false,
};

const auth = (state = initialState, action) => {
	switch (action.type) {
		case START_AUTHENTICATION:
			return {
				...state,
				isAuthenticating: true,
			};
		case AUTHENTICATION_COMPLETED:
			return {
				...state,
				isAuthenticating: false,
			};
		case REDIRECT_TO_REGISTER:
			return {
				...state,
				redirectToRegister: action.value,
			};
		default:
			return state;
	}
};

export default auth;
