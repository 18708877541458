import {
	currentUser,
	getUserByRef,
	updateUser,
	getAccount,
	fetchCustomerCard,
	saveCustomerCard,
	differentGetUser,
	removeGuestUser,
} from "firebaseManager";
import firebase from "firebase/app";
import Logger from "utils/logger";
import { isNullOrUndefined } from "utils/common";
import Strings from "styles/strings";
import {
	REQUEST_FETCH_USER_DATA,
	RECEIVE_FETCH_USER_DATA,
	ERROR_FETCH_USER_DATA,
	RESET_USER_DATA,
	UPDATE_STORED_USER_DATA,
} from "./types";

import { fetchConfiguration } from "./configurationActions";
import { fetchResources, getResourcesSnapshot } from "./resourceActions";
import { fetchWearableDevices } from "./wearableDeviceActions";
import { fetchSubscriptions } from "./subscriptionActions";
import { setAccountProfileAction, getWebStringsAction } from "./commonActions";
import { checkPendingInvitation } from "./invitationsActions";
import { getPendingReferral } from "./referralsActions";
import { requestNotifications } from "./notificationsActions";
import { signOutAsync, redirectToRegister } from "./authActions";
import { requestProvinces } from "./commonActions";
import { ejectUser } from "../firebaseManager/user";

export { isGuest } from "firebaseManager"

const requestFetchUserData = () => ({
	type: REQUEST_FETCH_USER_DATA,
});

export const receiveFetchUserData = (user, account) => ({
	type: RECEIVE_FETCH_USER_DATA,
	user,
	account,
});

const errorFetchUserData = (error) => ({
	type: ERROR_FETCH_USER_DATA,
	error,
});

export const resetUserData = () => ({
	type: RESET_USER_DATA,
});

export const updateStoredUserData = (
	user = undefined,
	account = undefined
) => ({
	type: UPDATE_STORED_USER_DATA,
	user,
	account,
});

/**
 * Legge le informazioni dell'utente
 * se nello stato è sono già presenti le informazioni dell'utente
 * la procedura viene saltata in caso contrario vengono lette le informazioni
 * di autenticazione di firebase e ricercato il relativo utente
 * @param startFetchConfiguration se true dopo che sono state lette con successo le informazioni
 * dell'utente viene lanciato la procedura di lettura dei dati di configurazione
 */

export const fetchUserData = (startFetchConfiguration = true) => async (
	dispatch,
	getState
) => {
	try {
		const { user } = getState().userData;
		let userChecked = user;
		dispatch(requestFetchUserData());
		const firebaseUser = currentUser();
		if (isNullOrUndefined(firebaseUser)) {
			Logger.error(
				Logger.code.USER_DATA_ACTIONS_GET_USER,
				"ERROR firebase user non found",
				0
			);
			dispatch(errorFetchUserData(""));
			return;
		}
		if (isNullOrUndefined(userChecked)) {
			try {
				console.log(firebaseUser);
				const fetchedUser = await differentGetUser(firebaseUser);
				// const fetchedUser = await getUser(firebaseUser);
				console.log(firebaseUser.uid);
				if (fetchedUser.empty) {
					signOutAsync();
					console.log("passo da qui");
					dispatch(redirectToRegister(true));
					dispatch(errorFetchUserData(Strings().messageErrorUserNotFound));
					return;
				}
				console.log("fetchedUser ok");
				// eslint-disable-next-line
				userChecked = fetchedUser.docs[0];
			} catch (e) {
				Logger.error(
					Logger.code.USER_DATA_ACTIONS_GET_USER,
					e.message,
					firebaseUser.uid,
					e
				);
				dispatch(errorFetchUserData(Strings().messageErrorFetchUserData));
				return;
			}
		}

		// Lettura informazioni account collegato
		try {
			const userData = userChecked.data();
			const account = await getAccount(userChecked).catch(async (e)=>
			 	await firebase.firestore().collection("account").doc(userData.account.id).get()
			);
			if (account.exists) {
				dispatch(receiveFetchUserData(userChecked, account));
				dispatch(setAccountProfileAction(dispatch, account));
				dispatch(requestNotifications(dispatch, account));
				getResourcesSnapshot(dispatch, account, getState);
				getPendingReferral(dispatch, account);
				getWebStringsAction(dispatch);
				requestProvinces(dispatch);
			} else {
				Logger.error(
					Logger.code.USER_DATA_ACTIONS_GET_ACCOUNT,
					"Account doesn't exists",
					userChecked.id
				);
				dispatch(errorFetchUserData(Strings().messageErrorFetchAccount));
				return;
			}
		} catch (e) {
			Logger.error(
				Logger.code.USER_DATA_ACTIONS_GET_ACCOUNT,
				e.message,
				userChecked.id,
				e
			);
			dispatch(errorFetchUserData(Strings().messageErrorFetchAccount));
			return;
		}

		if (startFetchConfiguration) {
			dispatch(fetchConfiguration());
		}

		try {
			checkPendingInvitation(dispatch, userChecked);
		} catch (e) {
			Logger.error(Logger.code.INVITATION_CHECK, e.message, userChecked.id, e);
		}

		dispatch(fetchResources());
		dispatch(fetchWearableDevices());
		dispatch(fetchSubscriptions());
	} catch (e) {
		const firebaseUser = currentUser();
		Logger.error(
			Logger.code.USER_DATA_ACTIONS_WHOLE_FETCH,
			e.message,
			firebaseUser ? firebaseUser.uid : 0,
			e
		);
	}
};

/**
 * Aggiorna le informazioni dell'account legato all'utente
 */
export const refreshAccount = () => async (dispatch, getState) => {
	const { user } = getState().userData;
	try {
		const account = await getAccount(user);
		dispatch(receiveFetchUserData(user, account));
	} catch (e) {
		Logger.error(
			Logger.code.USER_DATA_ACTIONS_REFRESH_ACCOUNT,
			e.message,
			user.id,
			e
		);
	}
};

/**
 * Aggiorna le informazioni dell'utente
 * @param {Object} userWrapper oggetto wrapper dell'utente
 * @param {Object} userInfo oggetto con le informazioni dell'utente
 * @returns {Promise<DocumentSnapshot>} promise dell'utente aggiornato
 */
export const updateUserDataAsync = async (userWrapper, userInfo) => {
	await updateUser(userWrapper.documentSnapshot, userInfo);
	return getUserByRef(userWrapper.documentSnapshot);
};

/**
 * Esegue la lettura della carta per i pagamenti dell'account collegato
 * all'utente
 * @param {Object} userWrapper oggetto wrapper dell'utente
 * @returns {Object} oggetto con il risulato della lettura dei dati
 */
export const fetchAccountCardAsync = async (userWrapper) => {
	const result = await fetchCustomerCard(userWrapper.account.customerId);
	return { result: result.data };
};

/**
 * Esegue la sostituzione della carta utilizzata per i pagamenti legata
 * all'account dell'utente
 * @param {Object} userWrapper oggetto wrapper dell'utente
 * @param {Object} paymentMethodId id metodo di pagamento inserito
 * @returns {Object} oggetto con il risultato della lettura dei dati
 */
export const saveAccountCardAsync = async (userWrapper, paymentMethodId) => {
	const result = await saveCustomerCard(
		userWrapper.account.id,
		paymentMethodId
	);
	return { result: result.data };
};

/**
 * Rimuove un utente guest da un account facendolo ritornare owner di quello originario
 * @param {DocumentSnapshot} user utente di cui leggere le informazioni
 * @returns {Promise<Void>}
 */
export const removeGuestUserAsync = async (userWrapper) => {
	//return await removeGuestUser(userWrapper);
	return await ejectUser({path: userWrapper.ref.path}).catch((e)=>{console.log("ERROR",e)})
};