import styled from "styled-components";
import CircularProgressMUI from "@material-ui/core/CircularProgress";
import theme from "styles/theme";

export const Container = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;

	&.floating {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 9999;
		background-color: rgba(255, 255, 255, 0.5);
	}
`;

export const CircularProgress = styled(CircularProgressMUI)`
	color: ${theme.primaryColor};
`;
