import Dimens from "./dimens";

export const device = {
  mobileS: `(min-width: ${Dimens.mobileS})`,
  mobileM: `(min-width: ${Dimens.mobileM})`,
  mobileL: `(min-width: ${Dimens.mobileL})`,
  tablet: `(min-width: ${Dimens.tablet})`,
  laptop: `(min-width: ${Dimens.laptop})`,
  laptopL: `(min-width: ${Dimens.laptopL})`,
  desktop: `(min-width: ${Dimens.desktop})`,
  desktopL: `(min-width: ${Dimens.desktop})`
};
