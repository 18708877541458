import styled from "styled-components";
import ButtonMUI from "@material-ui/core/Button";
import theme from "styles/theme";

export const Button = styled(ButtonMUI)`
	${theme.button}
	${(props) => (props.fullWidth ? "width: 100%" : "")}

  &.primary {
		${(props) => (props.disabled ? theme.buttonDisabled : theme.buttonPrimary)}
	}

	&.secondary {
		${(props) =>
			props.disabled ? theme.buttonDisabled : theme.buttonSecondary}
	}

	&.green {
		${(props) => (props.disabled ? theme.buttonDisabled : theme.buttonGreen)}
	}

	&.simple {
		${(props) => (props.disabled ? theme.buttonDisabled : theme.buttonSimple)}
	}

	&.text {
		${theme.buttonText}
	}

	&.textAgree {
		${theme.buttonGreenText}
	}

	&.simpleRound {
		${theme.buttonSimpleRound}
	}
`;
