import {
	CLEAR_REFERRALS,
	REQUEST_FETCH_ALL_REFERRALS,
	RECEIVE_FETCH_ALL_REFERRALS,
	ERROR_FETCH_ALL_REFERRALS,
	CLEAR_PENDING_REFERRAL,
	SET_PENDING_REFERRAL,
	CLEAR_APPROVED_REFERRAL,
	SET_APPROVED_REFERRAL,
} from "../actions/types";

import { models } from "seremy-firebase-data";

export const { Status } = models.referral;

const initialState = {
	referrals: [],
	isFetching: false,
	error: null,
	earning: 0,
	pendingReferral: null,
	approvedReferral: null,
};

const referrals = (state = initialState, action) => {
	switch (action.type) {
		case SET_APPROVED_REFERRAL:
			return {
				...state,
				approvedReferral: action.referral,
			};
		case CLEAR_APPROVED_REFERRAL:
			return {
				...state,
				approvedReferral: null,
			};
		case SET_PENDING_REFERRAL:
			return {
				...state,
				pendingReferral: action.referral,
			};
		case CLEAR_PENDING_REFERRAL:
			return {
				...state,
				pendingReferral: null,
			};
		case CLEAR_REFERRALS:
			return {
				referrals: [],
				isFetching: false,
				error: null,
				earning: 0,
				pendingReferral: null,
			};
		case REQUEST_FETCH_ALL_REFERRALS:
			return {
				...state,
				referrals: [],
				isFetching: true,
				earning: 0,
			};
		case RECEIVE_FETCH_ALL_REFERRALS:
			var earning = 0;
			if (action.referrals.length > 0) {
				for (var i = 0; i < action.referrals.length; i++) {
					if (
						action.referrals[i].data().status == Status.closed &&
						action.referrals[i].data().referring_earning
					) {
						earning += action.referrals[i].data().referring_earning;
					}
				}
			}

			return {
				...state,
				referrals: action.referrals,
				isFetching: false,
				error: null,
				earning: earning,
			};
		case ERROR_FETCH_ALL_REFERRALS:
			return {
				...state,
				isFetching: false,
				error: action.error,
				referrals: [],
				earning: 0,
			};
		default:
			return state;
	}
};

export default referrals;
