import React from "react";
import strings from "styles/strings";

import { ReactComponent as DashboardIcon } from "assets/icons/dashboard.svg";
import { ReactComponent as AccountIcon } from "assets/icons/account.svg";
import { ReactComponent as MenuIcon } from "assets/icons/menu.svg";
import { ReactComponent as AlarmIcon } from "assets/icons/bell.svg";
import { ReactComponent as ResourcesIcon } from "assets/icons/resources.svg";

import * as paths from "routes/paths";

import styled from "styled-components";
import dimens from "styles/dimens";

export const MyLabel = styled.span`
	font-size: ${dimens.labelTextSizeSmall};
`;
export const drawerItems = [
	{
		text: <MyLabel>{strings().drawerItemDashboard}</MyLabel>,
		icon: <DashboardIcon />,
		path: paths.DASHBOARD,
		exact: true,
	},
	{
		text: <MyLabel>{strings().drawerItemAlarms}</MyLabel>,
		icon: <AlarmIcon />,
		path: paths.ALARMS,
		exact: true,
		showBadge: true,
	},
	{
		text: <MyLabel>{strings().drawerItemGuests}</MyLabel>,
		icon: <ResourcesIcon />,
		path: paths.RESOURCE,
		exact: true,
	},
	{
		text: <MyLabel>{strings().drawerItemMenu}</MyLabel>,
		icon: <MenuIcon />,
		path: paths.MENU,
		exact: true,
	},
];
