import React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from 'components/CircularProgress';
import Message from 'components/Message';
import Strings from 'styles/strings';

import { Container } from './style';

const LoadingComponent = ({
  error, timedOut, pastDelay, message, retry,
}) => {
  let contentToRender = null;
  if (error) {
    contentToRender = (
      <Message
        message={message}
        onActionClick={retry}
        actionText={Strings().buttonRetry}
      />
    );
  }
  if (timedOut) {
    contentToRender = (
      <Message
        message={Strings().messageErrorTimeout}
        onActionClick={retry}
        actionText={Strings().buttonRetry}
      />
    );
  }
  if (pastDelay) {
    contentToRender = <CircularProgress />;
  }
  return <Container>{contentToRender}</Container>;
};

LoadingComponent.propTypes = {
  error: PropTypes.shape(),
  message: PropTypes.string,
  pastDelay: PropTypes.bool,
  timedOut: PropTypes.bool,
  retry: PropTypes.func,
};

LoadingComponent.defaultProps = {
  error: undefined,
  message: Strings().messageErrorLoading,
  timedOut: false,
  pastDelay: true,
  retry: undefined,
};

export default LoadingComponent;
