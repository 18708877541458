/**
 * verifica se il valore è una email
 * @param {String} value valore
 * @returns {Boolean} true se emal valida
 */
export const isEmail = (value) =>
	/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
		value
	);

/**
 * Verifica se un valore è vuoto
 * @param {String} value valore
 * @returns {Boolean} true se valore vuoto
 */
export const isEmpty = (value) =>
	value === undefined || value === null || value === "";

export const isSet = (value) => value !== undefined && value !== null;

/**
 * Verifica se il valore di un numero di telefono è vuoto
 * tenendo in cosiderazione la lunghezza del provisso
 * @param {String} value valore
 * @returns {Boolean} true se valore vuoto
 */
export const isPhoneNumberEmpty = (value) =>
	value === undefined || value === null || value === "" || value.length <= 3;

/**
 * Verifica se un numero di telefono ha il prefisso
 * @param {String} phoneNumber numero di telefono
 * @returns {Boolean} true se numero di telefono ha il previsso
 */
export const hasPrefix = (phoneNumber) => /^\+(?:[\d]{2})/.test(phoneNumber);

/**
 * Verifica se il valore suppera la lunghezza massima consentita
 * @param {String} value valore
 * @returns {Boolean} true se il valore supera la lunghezza massima
 */
export const maxLength = (value, max) => value.length >= max;
