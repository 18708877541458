import styled from "styled-components";
import Button from "components/Button";
import LinkMUI from "@material-ui/core/Link";

import Dimens from "styles/dimens";
import theme from "styles/theme";
import { device } from "styles/device";

export const Topbar = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	padding: ${Dimens.paddingNormal};
`;

export const HelpLink = styled(LinkMUI)`
	display: inline;
	font-size: ${Dimens.labelTextSizeSmall};
	color: ${theme.primaryColor};
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	min-height: 100vh;
`;

export const ContentFields = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
`;

export const DrawerContainer = styled.div`
	display: flex;
	heigth: 100%;
	width: 100%;
`;

export const PayloadContainer = styled.div`
	flex: 1;
	display: none;
	@media ${device.tablet} {
		display: flex;
	}
`;

export const LoginFormContainer = styled.div`
	flex: 2;
	@media ${device.tablet} {
		max-width: ${Dimens.authFormContainerMaxWidth};
	}
	padding: ${Dimens.paddingNormal} * 3;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
`;

export const ContentForm = styled.div`
	display: flex;
	flex-direction: column;
	width: ${Dimens.authFormMaxWidth};
	padding: ${Dimens.paddingNormal};
`;

export const LogoLink = styled.a`
	margin-bottom: ${Dimens.marginLarge};
`;

export const Logo = styled.img`
	width: ${Dimens.authLogoSize};
	align-self: center;
`;

export const NextButton = styled(Button)`
	margin-top: ${Dimens.marginLarge} !important;
`;
