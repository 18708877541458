import styled from "styled-components";
import { secondaryTextColor, drawerItemColor } from "styles/colors";
import dimens from "styles/dimens";

import { ReactComponent as AccountProfileIconComponent } from "assets/icons/account_profile.svg";
import { ReactComponent as AccountOrdersIconComponent } from "assets/icons/account_orders.svg";
import { ReactComponent as AccountDeviceIconComponent } from "assets/icons/deviceTab.svg";
import { ReactComponent as AccountBeaconIconComponent } from "assets/icons/beacon.svg";
import { ReactComponent as AccountResourcesIconComponent } from "assets/icons/resources.svg";
import { ReactComponent as AccountSubscriptionsIconComponent } from "assets/icons/account_subscriptions.svg";
import { ReactComponent as ChevronRightIconComponent } from "assets/icons/chevron-right.svg";
import { ReactComponent as AccountPaymentIconComponent } from "assets/icons/payment.svg";
import { ReactComponent as SettingsIconComponent } from "assets/icons/settings.svg";
import { ReactComponent as LogoutIconComponent } from "assets/ic_logout.svg";
import { ReactComponent as AccountCircleIconComponent } from "assets/ic_account.svg";
import { ReactComponent as AccountAdministrationIconComponent } from "assets/icons/account_administration.svg";
import { ReactComponent as HelpIconComponent } from "assets/icons/help.svg";
import { ReactComponent as PlusIconComponent } from "assets/icons/plus.svg";
import { ReactComponent as SaveIconComponent } from "assets/icons/save.svg";
import { ReactComponent as CancelIconComponent } from "assets/icons/cancel.svg";
import { ReactComponent as InvitationIconComponent } from "assets/icons/invitation.svg";
import { ReactComponent as ResendIconComponent } from "assets/icons/resend.svg";
import { ReactComponent as OtherSectionIconComponent } from "assets/icons/other_section.svg";
import { ReactComponent as ReferralIconComponent } from "assets/icons/referral.svg";
import { ReactComponent as AlarmIconComponent } from "assets/icons/bell.svg";
import { ReactComponent as UserIconComponent } from "assets/icons/users.svg";
import { ReactComponent as SingleUserIconComponent } from "assets/icons/user.svg";
import { ReactComponent as SearchIconComponent } from "assets/icons/search.svg";
import { ReactComponent as ReportIconComponent } from "assets/icons/report.svg";
import { ReactComponent as MenuIconComponent } from "assets/icons/menu.svg";
import { ReactComponent as CartIconComponent } from "assets/icons/cart.svg";
import { ReactComponent as DevicesIconComponent } from "assets/ic_devices.svg";

const iconStyle = `flex: 1 1; width: 24px; height:24px; fill: ${drawerItemColor} ;`;

export const SaveIcon = styled(SaveIconComponent)`
	margin-left: ${dimens.marginNormal};
	width: 32px;
	height: 32px;
	fill: ${drawerItemColor};
	color: ${drawerItemColor};
`;

export const CancelIcon = styled(CancelIconComponent)`
	margin-left: ${dimens.marginNormal};
	width: 32px;
	height: 32px;
	fill: ${drawerItemColor};
	color: ${drawerItemColor};
`;

export const CartIcon = styled(CartIconComponent)`
	${iconStyle};
	color: ${drawerItemColor};
`;

export const MultiDevicesIcon = styled(DevicesIconComponent)`
	${iconStyle};
	color: ${drawerItemColor};
`;

export const MenuIcon = styled(MenuIconComponent)`
	${iconStyle};
	color: ${drawerItemColor};
`;

export const ReportIcon = styled(ReportIconComponent)`
	${iconStyle};
	color: ${drawerItemColor};
`;

export const SearchIcon = styled(SearchIconComponent)`
	${iconStyle};
	color: ${drawerItemColor};
`;
export const ReferralIcon = styled(ReferralIconComponent)`
	${iconStyle};
	color: ${drawerItemColor};
`;
export const AlarmIcon = styled(AlarmIconComponent)`
	${iconStyle};
	color: ${drawerItemColor};
`;
export const ResendIcon = styled(ResendIconComponent)`
	${iconStyle};
	color: ${drawerItemColor};
`;
export const OtherSectionIcon = styled(OtherSectionIconComponent)`
	${iconStyle};
	color: ${drawerItemColor};
`;

export const SaveIconAction = styled(SaveIconComponent)`
	${iconStyle};
	color: ${drawerItemColor};
`;

export const InvitationIcon = styled(InvitationIconComponent)`
	${iconStyle};
	color: ${drawerItemColor};
`;

export const CancelIconAction = styled(CancelIconComponent)`
	margin-left: ${dimens.marginNormal};
	margin-right: ${dimens.marginSmall};
	${iconStyle};
	color: ${drawerItemColor};
`;

export const AccountProfileIcon = styled(AccountProfileIconComponent)`
	${iconStyle};
	color: ${drawerItemColor};
`;
export const AccountCircleIcon = styled(AccountCircleIconComponent)`
	${iconStyle};
	color: ${drawerItemColor};
`;
export const AccountAdministrationIcon = styled(
	AccountAdministrationIconComponent
)`
	${iconStyle};
	color: ${drawerItemColor};
`;
export const LogoutIcon = styled(LogoutIconComponent)`
	${iconStyle};
	color: ${drawerItemColor};
`;
export const PlusIcon = styled(PlusIconComponent)`
	${iconStyle};
	color: ${drawerItemColor};
`;
export const HelpIcon = styled(HelpIconComponent)`
	${iconStyle};
	color: ${drawerItemColor};
`;
export const SettingsIcon = styled(SettingsIconComponent)`
	${iconStyle};
	color: ${drawerItemColor};
`;
export const AccountOrdersIcon = styled(AccountOrdersIconComponent)`
	${iconStyle};
	color: ${drawerItemColor};
`;
export const AccountDeviceIcon = styled(AccountDeviceIconComponent)`
	${iconStyle};
	color: ${drawerItemColor};
`;
export const AccountBeaconIcon = styled(AccountBeaconIconComponent)`
	${iconStyle};
	color: ${drawerItemColor};
`;
export const AccountResourcesIcon = styled(AccountResourcesIconComponent)`
	${iconStyle};
	color: ${drawerItemColor};
`;
export const UserIcon = styled(UserIconComponent)`
	${iconStyle};
	color: ${drawerItemColor};
`;
export const SingleUserIcon = styled(SingleUserIconComponent)`
	${iconStyle};
	color: ${drawerItemColor};
`;
export const AccountPaymentIcon = styled(AccountPaymentIconComponent)`
	${iconStyle};
	color: ${drawerItemColor};
`;

export const AccountSubscriptionsIcon = styled(
	AccountSubscriptionsIconComponent
)`
	${iconStyle};
	color: ${drawerItemColor};
`;

const iconStyle32 = `flex: 1 1; width: 32px; height:32px; fill: ${secondaryTextColor} ;`;
export const ChevronRightIcon = styled(ChevronRightIconComponent)`
	${iconStyle32};
	color: ${secondaryTextColor};
`;
