import {
	UPDATE_FETCH_RESOURCE_ALARMS,
	REQUEST_FETCH_RESOURCE_ALARMS,
	RECEIVE_FETCH_RESOURCE_ALARMS,
	ERROR_FETCH_RESOURCE_ALARMS,
	REQUEST_FETCH_ALL_ALARMS,
	RECEIVE_FETCH_ALL_ALARMS,
	ERROR_FETCH_ALL_ALARMS,
	UPDATE_FETCH_ALL_ALARMS,
	RECEIVE_ALARMS_SNAPSHOT,
	SET_EXTERNAL_SELECTED_ALARM,
} from "./types";

var moment = require("moment");

export const setExternalSelectedAlarm = (alarm) => ({
	type: SET_EXTERNAL_SELECTED_ALARM,
	alarm: alarm,
});

const requestFetchResourceAlarms = () => ({
	type: REQUEST_FETCH_RESOURCE_ALARMS,
});

const requestFetchAllAlarms = () => ({
	type: REQUEST_FETCH_ALL_ALARMS,
});

const updateFetchResourceAlarms = () => ({
	type: UPDATE_FETCH_RESOURCE_ALARMS,
});
const updateFetchAllAlarms = () => ({
	type: UPDATE_FETCH_ALL_ALARMS,
});

const receiveFetchResourceAlarms = (alarms) => ({
	type: RECEIVE_FETCH_RESOURCE_ALARMS,
	alarms,
});
const receiveFetchAllAlarms = (alarms, allResourceAlarms) => ({
	type: RECEIVE_FETCH_ALL_ALARMS,
	alarms,
	allResourceAlarms,
});

const errorFetchUserResourceAlarms = (error) => ({
	type: ERROR_FETCH_RESOURCE_ALARMS,
	error,
});
const errorFetchAllAlarms = (error) => ({
	type: ERROR_FETCH_ALL_ALARMS,
	error,
});

async function getResourceAlarmsFirebase(resource, limit) {
	const res = await resource.ref
		.collection("alarms")
		.where("cleared", "==", false)
		.where("createdAt", ">=", moment().subtract(limit, "days").toDate())
		.orderBy("createdAt", "desc")
		.get();
	return res;
}

async function retrieveAllAlarms(dispatch, resources, limit) {
	var alarms = [];
	var resourcesAlarms = []; //salvo direttamente i dati json aggiungendo il resourceId
	const promises = resources.map(async (resource) => {
		const tmp = await getResourceAlarmsFirebase(resource, 7);
		if (tmp && tmp.docs && tmp.docs.length > 0) {
			tmp.docs.forEach((element) => {
				var data = element.data();
				data.resourceId = resource.id;
				resourcesAlarms.push(data);
			});
			alarms = alarms.concat(tmp.docs);
		}
	});
	const tmp = await Promise.all(promises);
	dispatch(receiveFetchAllAlarms(alarms, resourcesAlarms));
}

function retrieveAlarms(dispatch, resource, limit) {
	const hardlimit = 7;
	resource.ref
		.collection("alarms")
		.where("cleared", "==", false)
		.where("createdAt", ">=", moment().subtract(hardlimit, "days").toDate())
		.orderBy("createdAt", "desc")
		.get()
		.then((data) => {
			var firstData = data.docs;

			resource.ref
				.collection("alarms")
				.where("createdAt", ">=", moment().subtract(hardlimit, "days").toDate())
				.where("cleared", "==", true)
				.orderBy("createdAt", "desc")
				.get()
				.then((data) => {
					firstData = firstData.concat(data.docs);
					dispatch(receiveFetchResourceAlarms(firstData));
				});
		})
		.catch((error) => {
			dispatch(errorFetchUserResourceAlarms(error));
		});
}

export const getAlarmsSnapshot = (dispatch, resources) => {
	const promises = resources.map(async (resource) => {
		const limit = 7;
		const res = await resource.ref
			.collection("alarms")
			.where("createdAt", ">=", moment().subtract(limit, "days").toDate())
			.orderBy("createdAt", "desc")
			.onSnapshot(
				(querySnapshot) => {
					dispatch({
						type: RECEIVE_ALARMS_SNAPSHOT,
						resourceId: resource.id,
						alarms: querySnapshot,
					});
				},
				function (error) {
					//...
				}
			);
	});
};

export const getResourceAlarms = (dispatch, resource, limit = 1) => {
	retrieveAlarms(dispatch, resource, limit);
	return requestFetchResourceAlarms({});
};

export const getAllAlarms = (dispatch, resources, limit = 1) => {
	retrieveAllAlarms(dispatch, resources, limit);
	return requestFetchAllAlarms({});
};

export const updateResourceAlarms = (dispatch, resource, limit = 1) => {
	retrieveAlarms(dispatch, resource, limit);
	return updateFetchResourceAlarms({});
};

export const setAlarmAsCleared = (dispatch, resource, alarm, user, notes) => {
	try {
		alarm.ref.update({
			cleared: true,
			clearedAt: moment().toDate(),
			clearedByName: user.data().name,
			clearedById: user.id,
			clearedNotes: notes
		});
	} catch (error) {
		console.log(error);
		// dispatch(errorFetchUserResourceAlarms(error));
	}
	return updateResourceAlarms(dispatch, resource);
};
