import React from 'react';
import PropTypes from 'prop-types';

import { Label } from './style';

const PRIMARY = 'primary';
const SECONDARY = 'secondary';

const LabelComponent = ({
  children, clickable, type, className, ...props
}) => (
  <Label
    type={type}
    className={`${clickable ? 'clickable' : ''} ${className}`}
    {...props}
  >
    {children}
  </Label>
);

LabelComponent.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf([PRIMARY, SECONDARY]),
  clickable: PropTypes.bool,
};

LabelComponent.defaultProps = {
  children: '',
  className: '',
  type: PRIMARY,
  clickable: false,
};

export default LabelComponent;
