import React, { Component } from "react";
import { Container, ConfirmButton, ContentProgress } from "./style";
import { connect } from "react-redux";
import strings from "styles/strings";
import { withRouter } from "react-router-dom";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "components/CircularProgress";

import {
	acceptInvitationAsync,
	refuseInvitationAction,
} from "actions/invitationsActions";

class PendingInvitationPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openDialog: true,
		};
		this.handleClickOpenDialog = this.handleClickOpenDialog.bind(this);
		this.handleClickCloseDialog = this.handleClickCloseDialog.bind(this);
		this.handleAccept = this.handleAccept.bind(this);
		this.handleReject = this.handleReject.bind(this);
	}

	handleClickOpenDialog = () => {
		this.setState({ openDialog: true });
	};

	handleClickCloseDialog = () => {
		this.setState({ openDialog: false });
	};

	handleAccept() {
		const {
			pendingInvitation,
			user,
			dispatchAcceptInvitationAction,
		} = this.props;
		dispatchAcceptInvitationAction(pendingInvitation, user);
	}

	handleReject() {
		const { pendingInvitation, dispatchRefuseInvitationAction } = this.props;
		dispatchRefuseInvitationAction(pendingInvitation);
	}

	render() {
		const { pendingInvitation, showLoader } = this.props;
		const description = pendingInvitation.accountObj
			? strings().pendingInvitationText.replace(
					"{accountName}",
					pendingInvitation.accountObj.data().name
			  )
			: "";
		return (
			<Container>
				<Dialog
					open={this.state.openDialog}
					disableBackdropClick={true}
					disableEscapeKeyDown={true}
					onClose={this.handleClickCloseDialog}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					{showLoader ? (
						<ContentProgress>
							<CircularProgress></CircularProgress>
						</ContentProgress>
					) : (
						<React.Fragment>
							<DialogTitle id="alert-dialog-title">
								{strings().pendingInvitationTitle}
							</DialogTitle>
							<DialogContent>
								<DialogContentText id="alert-dialog-description">
									{description}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<ConfirmButton
									type="secondary"
									onClick={() => this.handleReject()}
								>
									{strings().dialogUndo}
								</ConfirmButton>
								<ConfirmButton
									type="primary"
									onClick={() => {
										this.handleAccept();
									}}
								>
									{strings().dialogConfirm}
								</ConfirmButton>
							</DialogActions>
						</React.Fragment>
					)}
				</Dialog>
			</Container>
		);
	}
}

const mapStateToProps = (state) => ({
	invitations: state.invitations,
});

const mapDispatchToProps = (dispatch) => ({
	dispatchAcceptInvitationAction: (invitation, user) =>
		dispatch(acceptInvitationAsync(invitation, user)),
	dispatchRefuseInvitationAction: (invitation) =>
		refuseInvitationAction(dispatch, invitation),
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(PendingInvitationPage));
