// TODO: sostituire con path di default definitiva; impostato crezione ordine perchè unica funzionalità presente
export const SECTIONS = {
    SHOP: {
        MAIN_STEP: "shop",
        ADDITIONAL_SERVICES: "additional-services",
        BUNDLE_WEARABLE_DEVICES: "device",
        CHECKOUT: "checkout",
        DEVICE_ACCESSORIES: "device-accessories",
        DEVICES_SHOP: "devices",
        PLAN: "plan",
        PLAN_DURATION: "plan-duration",
        RECIPIENT_INFO: "recipientInfo"
    }
}

export const MAIN_PATH = "/dashboard";
export const MAIN_ENDUSER_PATH = "/new-order-starter-kit?creationStep=device";

export const LOGIN = "/login";
export const LOGIN_TEMPLATE = `${LOGIN}`;

export const REGISTER = "/register";
export const REGISTER_TEMPLATE = `${REGISTER}`;

export const PASSWORD_RECOVERY = "/password-recovery";
export const PASSWORD_RECOVERY_TEMPLATE = `${PASSWORD_RECOVERY}`;

export const DASHBOARD = "/dashboard";
export const DASHBOARD_TEMPLATE = `${DASHBOARD}`;

export const RESOURCES = "/resources";
export const RESOURCES_TEMPLATE = `${RESOURCES}`;

export const RESOURCE = "/resources";
export const RESOURCE_TEMPLATE = `${RESOURCE}/:resourceId`;

export const RESOURCE_TAB = "/resources";
export const RESOURCE_TAB_TEMPLATE = `${RESOURCE}/:resourceId/:tabulator`;

export const WEARABLE_DEVICES = "/wearable-devices";
export const WEARABLE_DEVICES_TEMPLATE = `${WEARABLE_DEVICES}`;

export const WEARABLE_DEVICE = "/wearable-devices";
export const WEARABLE_DEVICE_TEMPLATE = `${WEARABLE_DEVICE}/:deviceId`;

export const ORDERS = "/orders";
export const ORDERS_TEMPLATE = `${ORDERS}`;

export const ORDER = "/orders";
export const ORDER_TEMPLATE = `${ORDER}/:orderId`;

export const ORDERVIEW = "/orderview";
export const ORDERVIEW_TEMPLATE = `${ORDERVIEW}/:orderId`;

export const PAYMENTS = "/payments";
export const PAYMENTS_TEMPLATE = `${PAYMENTS}`;

export const NEW_ORDER_STARTER_KIT = "/new-order-starter-kit";
export const NEW_ORDER_STARTER_KIT_TEMPLATE = `${NEW_ORDER_STARTER_KIT}/:orderId?`;

export const ORDER_STARTER = "/new-order-starter-kit";
export const ORDER_STARTER_TEMPLATE= `${ORDER_STARTER}`;

export const ALARMS = "/alarms";
export const ALARMS_TEMPLATE = `${ALARMS}`;

export const GUESTS = "/guests";
export const GUESTS_TEMPLATE = `${GUESTS}`;

export const ACCOUNT = "/account";
export const ACCOUNT_TEMPLATE = `${ACCOUNT}`;

export const MENU = "/menu";
export const MENU_TEMPLATE = `${MENU}`;

export const PROFILE = "/profile";
export const PROFILE_TEMPLATE = `${PROFILE}`;

export const BEACONS = "/beacons";
export const BEACONS_TEMPLATE = `${BEACONS}`;

export const RESOURCES_EDIT = "/resources-edit";
export const RESOURCES_EDIT_TEMPLATE = `${RESOURCES_EDIT}`;

export const SUBSCRIPTIONS = "/subscriptions";
export const SUBSCRIPTIONS_TEMPLATE = `${SUBSCRIPTIONS}`;

export const DOCUMENTS = "/documents";
export const DOCUMENTS_TEMPLATE = `${DOCUMENTS}`;

export const INVITATIONS = "/invitations";
export const INVITATIONS_TEMPLATE = `${INVITATIONS}`;

export const REFERRALS = "/referrals";
export const REFERRALS_TEMPLATE = `${REFERRALS}`;
export const ACCESSORIES = "/accessories";
export const ACCESSORIES_TEMPLATE = `${ACCESSORIES}/:category?/:category2?`;

export const NOTIFICATIONS = "/notifications";
export const NOTIFICATIONS_TEMPLATE = `${NOTIFICATIONS}`;

export const USERS = "/users";
export const USERS_TEMPLATE = `${USERS}`;

export const SHOP = "/shop";
export const SHOP_TEMPLATE = `${SHOP}`;

export const NEW_ORDER_BUNDLE = "/new-order-bundle";
export const NEW_ORDER_BUNDLE_TEMPLATE = `${NEW_ORDER_BUNDLE}/:orderId?`;

export const ADDITIONAL_SERVICES = "/shop/"+SECTIONS.SHOP.ADDITIONAL_SERVICES
export const ADDITIONAL_SERVICES_TEMPLATE = `${ADDITIONAL_SERVICES}/:orderId?`;

export const DEVICES_SHOP = "/shop/"+SECTIONS.SHOP.DEVICES_SHOP;
export const DEVICES_SHOP_TEMPLATE = `${DEVICES_SHOP}/:orderId?/`;

export const DEVICE_ACCESSORIES = "/shop/"+SECTIONS.SHOP.DEVICE_ACCESSORIES;
export const DEVICE_ACCESSORIES_TEMPLATE = `${SHOP}/:section/:orderId?`;

export const WEARABLE_DEVICES_BUNDLE = "/wearable-devices-bundle";
export const WEARABLE_DEVICES_BUNDLE_TEMPLATE = `${WEARABLE_DEVICES_BUNDLE}/:orderId?`;

export const REPORTS = "/reports";
export const REPORTS_TEMPLATE = `${REPORTS}`;
