import {
	REQUEST_FETCH_ALL_PAYMENTS,
	RECEIVE_FETCH_ALL_PAYMENTS,
	ERROR_FETCH_ALL_PAYMENTS,
	SET_SELECTED_PAYMENTS,
	CLEAR_SELECTED_PAYMENTS,
	CLEAR_ALL_PAYMENTS,
} from "actions/types";

export const setSelectedPayment = (payment) => ({
	type: SET_SELECTED_PAYMENTS,
	payment,
});

export const clearSelectedPayment = () => ({
	type: CLEAR_SELECTED_PAYMENTS,
});

export const clearAllPayments = () => ({
	type: CLEAR_ALL_PAYMENTS,
});

const requestFetchPayments = () => ({
	type: REQUEST_FETCH_ALL_PAYMENTS,
});

export const receiveFetchPayments = (payments) => ({
	type: RECEIVE_FETCH_ALL_PAYMENTS,
	payments,
});

export const errorFetchPayments = (error) => ({
	type: ERROR_FETCH_ALL_PAYMENTS,
	error,
});

async function getPayments(dispatch, account) {
	account.ref.collection("payments").onSnapshot(
		(querySnapshot) => {
			dispatch(receiveFetchPayments(querySnapshot));
		},
		function (error) {
			//...
		}
	);
}

export const requestPayments = (dispatch, account) => {
	getPayments(dispatch, account);
	return requestFetchPayments({});
};
