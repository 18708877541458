import seremyFire from "seremy-firebase-data";
import firebase from "firebase/app";
import { provinces } from "utils/provinces";

import {
	SET_RESOURCE_TABULATOR,
	SET_PREV_TABULATOR,
	SET_ACCOUNT_PROFILE,
	SET_WEB_STRINGS,
	REQUEST_FETCH_PROVINCES,
	RECEIVE_FETCH_PROVINCES,
} from "actions/types";

const setSelectedTabulator = (index) => ({
	type: SET_RESOURCE_TABULATOR,
	value: index,
});
const setPrevTabulator = () => ({
	type: SET_PREV_TABULATOR,
});

const setAccountProfile = (account) => ({
	type: SET_ACCOUNT_PROFILE,
	account: account,
});

const setWebStrings = (webstrings) => ({
	type: SET_WEB_STRINGS,
	webstrings: webstrings,
});

const receiveFetchProvinces = (provinces) => ({
	type: RECEIVE_FETCH_PROVINCES,
	provinces: provinces.docs,
});

const requestFetchProvinces = () => ({
	type: REQUEST_FETCH_PROVINCES,
});

async function getProvinces(dispatch) {
	firebase
		.firestore()
		.collection("provinces")
		.onSnapshot(
			(querySnapshot) => {
				dispatch(receiveFetchProvinces(querySnapshot));
			},
			function (error) {
				console.log(error);
				//...
			}
		);
}
async function setProvinces(dispatch) {
	for (var i = 0; i < provinces.length; i++) {
		console.log("RICERCA" + provinces[i].sigla);
		var doc = await firebase
			.firestore()
			.collection("provinces")
			.where("code", "==", provinces[i].sigla)
			.get();
		console.log(doc.key);
		if (!doc.key) {
			console.log("NON TROVATO");
			const res = await firebase.firestore().collection("provinces").add({
				code: provinces[i].sigla,
				name: provinces[i].nome,
				region: provinces[i].regione,
			});
			console.log(res);
		}
	}
	console.log(provinces);
}

export const requestProvinces = (dispatch) => {
	getProvinces(dispatch);
	// setProvinces(dispatch);
	return requestFetchProvinces();
};

export const setSelectedTabulatorAction = (dispatch, index) => {
	// dispatch(setSelectedTabulator(index));
	return dispatch(setSelectedTabulator(index));
};

export const setPrevTabulatorAction = (dispatch) => {
	// dispatch(setSelectedTabulator(index));
	return dispatch(setPrevTabulator());
};

export const setAccountProfileAction = (dispatch, account) => {
	return dispatch(setAccountProfile(account));
};

export const getWebStringsAction = async (dispatch, language = "IT") => {
	const webstrings = await seremyFire.firebase
		.firestore()
		.collection("webStrings")
		.where("language", "==", language)
		.get();
	var strings = {};
	if (!webstrings.empty) {
		for (var i = 0; i < webstrings.docs.length; i++) {
			strings[webstrings.docs[i].data().label] = webstrings.docs[
				i
			].data().value;
		}
	}
	dispatch(setWebStrings(strings));
};
