import styled from "styled-components";
import theme from "styles/theme";
import dimens from "styles/dimens";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { drawerItemColor, accentColor } from "styles/colors";
import { mobileAppBarBackgroundColor } from "styles/colors";

export const AppBarButton = styled(BottomNavigationAction)`
	&.my-root-class {
		color: ${drawerItemColor};
		fill: ${drawerItemColor};
		padding: ${dimens.marginSmall} 0;
		padding-top: 18px !important;
	}
	&.my-selected-class {
		color: ${accentColor};
		fill: ${accentColor};
		padding: ${dimens.marginSmall} 0;
		padding-top: 18px !important;
	}
`;

export const AppBarContainer = styled(BottomNavigation)`
	height: 100%;
	width: 100%;
	border-top: ${theme.mobileAppBarBorder};
	background-color: ${mobileAppBarBackgroundColor};
`;
export const ButtonBarContainer = styled.div`
	background-color: ${theme.appBarBackgroundColor};
	width: 100%;
`;
