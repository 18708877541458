import {
	SET_RESOURCE_TABULATOR,
	SET_PREV_TABULATOR,
	SET_ACCOUNT_PROFILE,
	CLEAR_ACCOUNT_PROFILE,
	SET_WEB_STRINGS,
	RECEIVE_FETCH_PROVINCES,
	REQUEST_FETCH_PROVINCES,
} from "actions/types";

const commonInitialState = {
	accountProfile: "",
	selectedTabulator: 0,
	prevTabulator: 0,
	showPosition: false,
	showAlarms: false,
	showAlarmsDashboard: false,
	showActivity: false,
	showCardio: false,
	showBodyTemp: false,
	showSpO2: false,
	showSleep: false,
	showBeacon: false,
	showContactTracing: false,
	showSecurity: false,
	showSecurityDashboard: false,
	showDeviceInfo: false,
	showReports: false,
	defaultResourceTabulator: 0,
	webstrings: null,
	provinces: [],
	provincesLoading: false,
};

const selectedTabulator = (
	state = Object.assign({}, commonInitialState, { error: false }),
	action
) => {
	switch (action.type) {
		case RECEIVE_FETCH_PROVINCES:
			return {
				...state,
				provincesLoading: false,
				provinces: action.provinces,
			};
		case REQUEST_FETCH_PROVINCES:
			return {
				...state,
				provincesLoading: true,
				provinces: [],
			};
		case SET_WEB_STRINGS:
			return {
				...state,
				webstrings: action.webstrings,
			};
		case SET_RESOURCE_TABULATOR:
			if (action.value == state.selectedTabulator) return state;
			return {
				...state,
				selectedTabulator: action.value,
				prevTabulator: state.selectedTabulator,
			};
		case SET_PREV_TABULATOR:
			return {
				...state,
				prevTabulator: state.prevTabulator,
				selectedTabulator: state.prevTabulator,
			};
		case SET_ACCOUNT_PROFILE:
			var currentState = state;
			if (action.account && action.account.data().profile) {
				var visibility = getVisibility(action.account.data().profile);
				for (var key in visibility) {
					currentState[key] = visibility[key];
				}
			}
			return currentState;
		case CLEAR_ACCOUNT_PROFILE:
			return Object.assign({}, commonInitialState, { error: false });
		default:
			return state;
	}
};

const getVisibility = (profile) => {
	var visibility = {};

	switch (profile) {
		case "B2B":
			visibility = {
				accountProfile: profile,
				showPosition: true,
				showAlarms: true,
				showAlarmsDashboard: true,
				showActivity: true,
				showCardio: true,
				showBodyTemp: true,
				showSpO2: true,
				showSleep: true,
				showBeacon: true,
				showContactTracing: false,
				showSecurity: false,
				showSecurityDashboard: false,
				showDeviceInfo: true,
				showReports: true,
				defaultResourceTabulator: 0,
			};
			break;
		case "WORK":
			visibility = {
				accountProfile: profile,
				showPosition: false,
				showAlarms: true,
				showAlarmsDashboard: false,
				showActivity: false,
				showCardio: false,
				showBodyTemp: false,
				showSpO2: false,
				showSleep: false,
				showBeacon: true,
				showContactTracing: true,
				showSecurity: true,
				showSecurityDashboard: true,
				showDeviceInfo: true,
				showReports: false,
				defaultResourceTabulator: 1,
			};
			break;
		default:
			visibility = {
				accountProfile: "",
				showPosition: false,
				showAlarms: false,
				showAlarmsDashboard: false,
				showActivity: false,
				showCardio: false,
				showBodyTemp: false,
				showSpO2: false,
				showSleep: false,
				showBeacon: false,
				showContactTracing: false,
				showSecurity: false,
				showSecurityDashboard: false,
				showDeviceInfo: false,
				showReports: true,
				defaultResourceTabulator: 0,
			};
	}

	return visibility;
};

export default selectedTabulator;
