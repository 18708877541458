import React from "react";
import PropTypes from "prop-types";

import { Button } from "./style";

const PRIMARY = "primary";
const SECONDARY = "secondary";
const GREEN = "green";
const SIMPLE = "simple";
const TEXT = "text";
const TEXT_AGREE= "textAgree";
const SIMPLE_ROUND = "simple-round";

const ButtonComponent = React.forwardRef(
	({ children, className, type, ...props }, ref) => (
		<Button ref={ref} className={`${className} ${type}`} {...props}>
			{children}
		</Button>
	)
);

ButtonComponent.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	type: PropTypes.oneOf([PRIMARY, SECONDARY, GREEN, SIMPLE, TEXT, TEXT_AGREE, SIMPLE_ROUND]),
	fullWidth: PropTypes.bool,
};

ButtonComponent.defaultProps = {
	children: "",
	type: PRIMARY,
	className: "",
	fullWidth: false,
};

export default ButtonComponent;
