import * as validations from "utils/validations";
import Strings from "styles/strings";

export default class RecipientModel {
	constructor(recipient) {
		if (recipient) {
			this.address = { value: recipient.address, error: false };
			this.address2 = { value: recipient.address2 || "", error: false };
			this.city = { value: recipient.city, error: false };
			this.email = { value: recipient.email, error: false };
			this.name = { value: recipient.name, error: false };
			this.phoneNumber = { value: recipient.phoneNumber, error: false };
			this.postalCode = { value: recipient.postalCode, error: false };
			this.province = { value: recipient.province, error: false };
		} else {
			this.address = { value: "", error: false };
			this.address2 = { value: "", error: false };
			this.city = { value: "", error: false };
			this.email = { value: "", error: false };
			this.name = { value: "", error: false };
			this.phoneNumber = { value: "", error: false };
			this.postalCode = { value: "", error: false };
			this.province = { value: "", error: false };
		}
		this.hasError = false;
		this.errorMessages = [];
	}

	values() {
		return {
			address: this.address.value,
			address2: this.address2.value,
			city: this.city.value,
			email: this.email.value,
			name: this.name.value,
			phoneNumber: this.phoneNumber.value,
			postalCode: this.postalCode.value,
			province: this.province.value,
		};
	}

	asArray() {
		return [
			this.name.value,
			this.email.value,
			this.phoneNumber.value,
			this.address.value,
			this.address2.value,
			this.city.value,
			this.province.value,
			this.postalCode.value,
		];
	}

	validate() {
		this.hasError = false;
		this.errorMessages = [];

		// L'ordine dei campi rispecchia quello della form, così i messaggi di errore saranno in ordine
		if (!this.name.value) {
			this.name.error = true;
			this.hasError = true;
			this.errorMessages.push(Strings().messageResourceNameRequired);
		}

		if (!this.phoneNumber.value) {
			this.phoneNumber.error = true;
			this.hasError = true;
			this.errorMessages.push(Strings().messageResourcePhoneNumberRequired);
		}

		if (!validations.hasPrefix(this.phoneNumber.value)) {
			this.phoneNumber.error = true;
			this.hasError = true;
			this.errorMessages.push(Strings().messagePhoneNumberNeedPrefix);
		}

		if (!this.email.value) {
			this.email.error = true;
			this.hasError = true;
			this.errorMessages.push(Strings().messageEmailRequired);
		}

		if (!validations.isEmail(this.email.value)) {
			this.email.error = true;
			this.hasError = true;
			this.errorMessages.push(Strings().messageEmailInvalid);
		}

		if (!this.address.value) {
			this.address.error = true;
			this.hasError = true;
			this.errorMessages.push(Strings().messageResourceAddressRequired);
		}

		if (!this.city.value) {
			this.city.error = true;
			this.hasError = true;
			this.errorMessages.push(Strings().messageCityRequired);
		}

		if (!this.province.value) {
			this.province.error = true;
			this.hasError = true;
			this.errorMessages.push(Strings().messageProvinceRequired);
		}

		if (!this.postalCode.value) {
			this.postalCode.error = true;
			this.hasError = true;
			this.errorMessages.push(Strings().messagePostalCodeRequired);
		}
	}
}
