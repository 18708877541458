import React from "react";
import {
	Container,
	TitleLabel,
	DescriptionLabel,
	GoToMainPageButton,
} from "./style";
import Strings from "../../styles/strings";

const Page404 = () => (
	<Container>
		<TitleLabel>404</TitleLabel>
		<DescriptionLabel type="secondary">
			{Strings().labelPageNotFound}
		</DescriptionLabel>
		<GoToMainPageButton to="/">
			{Strings().buttonBackToMainPage}
		</GoToMainPageButton>
	</Container>
);

export default Page404;
