import { SET_PENDING_ORDER, CLEAR_PENDING_ORDER } from "actions/types";
import RecipientModel from "components/Accessories/AddressForm/RecipientModel";

const initialState = null;

const pendingOrder = (state = initialState, action) => {
	switch (action.type) {
		case SET_PENDING_ORDER:
			return {
				cart: action.cart,
				orderWrapper: action.orderWrapper,
				recipient: new RecipientModel(action.recipient.values()),
			};

		case CLEAR_PENDING_ORDER:
			return null;

		default:
			return state;
	}
};
export default pendingOrder;
