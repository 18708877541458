import firebase from "firebase/app";
import {
	getWearableDevices,
	assignWearableToResource,
	unassignWearableFromResource,
} from "firebaseManager";
import Strings from "styles/strings";
import Logger from "utils/logger";
import {
	REQUEST_FETCH_WEARABLE_DEVICES,
	RECEIVE_FETCH_WEARABLE_DEVICES,
	ERROR_FETCH_WEARABLE_DEVICES,
	RESET_WEARABLE_DEVICES,
	UPDATE_STORED_WERABLE_DEVICES,
	RECEIVE_FETCH_WEARABLE_DEVICES_SNAPSHOT,
} from "./types";

const requestFetchWearableDevices = () => ({
	type: REQUEST_FETCH_WEARABLE_DEVICES,
});

const receiveFetchWearableDevices = (wearableDevices) => ({
	type: RECEIVE_FETCH_WEARABLE_DEVICES,
	wearableDevices,
});

const errorFetchWearableDevices = (error) => ({
	type: ERROR_FETCH_WEARABLE_DEVICES,
	error,
});

export const resetWearableDevices = () => ({
	type: RESET_WEARABLE_DEVICES,
});

export const updateStoredWearableDevices = (wearableDevices) => ({
	type: UPDATE_STORED_WERABLE_DEVICES,
	wearableDevices,
});

export const getWearableDevicesSnapshot = async (dispatch, account) => {
	const res = await firebase
		.firestore()
		.collection("wearableDevices")
		.where("account", "==", account.ref)
		.onSnapshot(
			(querySnapshot) => {
				dispatch({
					type: RECEIVE_FETCH_WEARABLE_DEVICES_SNAPSHOT,
					wearableDevices: querySnapshot.docs,
				});
			},
			function (error) {
				//...
			}
		);
};

/**
 * Legge la lista dei dispositivi associate all'account dell'utente
 * salvando il risultato nello store di redux
 */
export const fetchWearableDevices = () => async (dispatch, getState) => {
	const { user, account } = getState().userData;
	dispatch(requestFetchWearableDevices());
	if (
		user === null ||
		user === undefined ||
		account === null ||
		account === undefined
	) {
		dispatch(errorFetchWearableDevices(Strings().messageInvalidAuth));
		return;
	}
	try {
		const fetchedWearableDevices = await getWearableDevices(account);
		dispatch(receiveFetchWearableDevices(fetchedWearableDevices.docs));
	} catch (e) {
		Logger.error(
			Logger.code.WEARABLE_DEVICE_ACTIONS_FETCH_WEARABLE_DEVICES,
			e.message,
			user.id,
			e
		);
		dispatch(
			errorFetchWearableDevices(Strings().messageErrorFetchWearableDevices)
		);
	}
};

/**
 * Associa un dispositivo ad una risorsa e nel caso rimuove l'associazione
 * con il precedente dispositivo. Dopo che è stata eseguira l'associazione
 * ritorna i dispositivi aggiornati
 * @param {Object} resourceWrapper oggetto wrapper della risorsa
 * @param {Object} newWearableDeviceWrapper oggetto wrapper del dispositivo da assegnare
 * @param {*} prevWearableDeviceWrapper oggetto wrapper del precedente dispositivo assegnato.
 * Default undefined, nel caso in cui la risorsa non abbia un dispositivo assegnato
 * @returns {Promise<Array<DocumentSnapshot>>} promise con dispositivi aggiornati
 */
export const assignWearableToResourceAsync = async (
	resourceWrapper,
	newWearableDeviceWrapper,
	prevWearableDeviceWrapper = undefined
) => {
	await assignWearableToResource(
		resourceWrapper.documentSnapshot.ref,
		newWearableDeviceWrapper.documentSnapshot,
		prevWearableDeviceWrapper !== undefined &&
			prevWearableDeviceWrapper !== null
			? prevWearableDeviceWrapper.documentSnapshot
			: undefined
	);
	const fetchList = [newWearableDeviceWrapper.documentSnapshot.ref.get()];
	if (
		prevWearableDeviceWrapper !== undefined &&
		prevWearableDeviceWrapper !== null
	) {
		fetchList.push(prevWearableDeviceWrapper.documentSnapshot.ref.get());
	}
	return Promise.all(fetchList);
};

/**
 * Rimuove l'assegnazione di un dispositivo a una risorsa
 * @param {Object} wearableDeviceWrapper oggetto wrapper del dispositivo
 * @returns {Promise<DocumentSnapshot>} promise del dispositivo aggiornato
 */
export const unassignWearableFromResourceAsync = async (
	wearableDeviceWrapper
) => {
	await unassignWearableFromResource(wearableDeviceWrapper.documentSnapshot);
	return wearableDeviceWrapper.documentSnapshot.ref.get();
};
