import seremyFire from "seremy-firebase-data";

export const getBundles = async() => {
		return await seremyFire.firebase
		.firestore()
		.collection("configurations/SHOP/shopProducts")
		.where("type", "==", "bundle")
		.get();
}

export const getProducts = async() => {
	return await seremyFire.firebase
	.firestore()
	.collection("products")
	.get();
}

export const getShopProduct = async(shopProductRef) => {
	return await seremyFire.firebase
	.firestore().shopProductRef.get();
}

export const getShopProducts = async() => {
	return await seremyFire.firebase
	.firestore()
	.collection("configurations/SHOP/shopProducts")
	.get();
}

export const getShopLanguages = async(lang) => {
	return await seremyFire.firebase
	.firestore()
	.collection(`languages`) ///${lang}/namespaces/shop
	.doc(lang)
	.collection("namespaces")
	.doc("shop")
	.get();
}