import {
	getIndicatorDefs,
	getLatestIndicatorValues,
	getDayStepsPerHour,
	getStepsPerDay,
	getBpmsPerDay,
	getSleepTimePerDay,
} from "firebaseManager";

import { NUM_LAST_INDICATORS_TO_FETCH } from "models/indicators";

import {
	REQUEST_FETCH_INDICATORS,
	RECEIVE_FETCH_INDICATORS,
	ERROR_FETCH_RESOURCES,
} from "./types";

var moment = require("moment");

/**
 * Ricerca in una lista di definizione di indicatori l'oggetto corrispondente al tipo indicato
 * @param {Array<DocumentSnapshot>} indicatorDefs lista di indicators defs
 * @param {String} type tipo indicator {@link ../constants/indicators}
 * @return {DocumentSnapshot} definizione indicatore corrispondente al tipo indicato
 */
export const getIndicatorDefWithType = (indicatorDefs) => (type) =>
	indicatorDefs.find((indicatorDef) => indicatorDef.id === type);

/**
 * Legge gli ultimi valori degli indicatori di una risorsa
 * @param {DocuementSnapshot} resource resource di cui leggere l'indicator value
 * @returns {Promise<QuerySnapshot>} promise lista degli ultimi indicator values
 */
export const fetchLatestIndicatorValuesAsync = async (resource) =>
	getLatestIndicatorValues(resource, NUM_LAST_INDICATORS_TO_FETCH);

/**
 * Legge la lista di definizioi di indicatori
 * @returns {Promise<QuerySnapshot>} promise lista di definizioi di indicatori
 */
export const fetchIndicatorDefs = () => getIndicatorDefs();

/**
 * Legge i passi orari di un giorno
 * @param {DocumentSnapshot} stepsPerDay oggetto passi giornaleri
 * @return {Promise<QuerySnapshot>} promise lista passi orari
 */
export const fetchStepsPerHour = (stepsPerDay) =>
	getDayStepsPerHour(stepsPerDay);

/**
 * Legge gli ultimi valori dei passi giornaglieri
 * @param {Object} resourceWrapper oggetto wrapper risorsa
 * @return {Promise<QuerySnapshot>} promise lista steps gionalieri
 */
export const fetchLatestStepsPerDayAsync = async (resourceWrapper) =>
	getStepsPerDay(
		resourceWrapper.documentSnapshot,
		NUM_LAST_INDICATORS_TO_FETCH
	);

/**
 * Legge gli ultimi valori dei battiti giornalieri
 * @param {Object} resourceWrapper oggetto wrapper risorsa
 * @return {Promise<QuerySnapshot>} promise lista battiti gionalieri
 */
export const fetchLatestBpmsPerDayAsync = async (resourceWrapper) =>
	getBpmsPerDay(resourceWrapper.documentSnapshot, NUM_LAST_INDICATORS_TO_FETCH);

/**
 * Legge gli ultimi valori delle ore di sonno al giorno di una risorsa
 * @param {Object} resourceWrapper oggetto wrapper risorsa
 * @return {Promise<QuerySnapshot>} promise lista ore di sono al giorno
 */
export const fetchLatestSleepTimePerDayAsync = async (resourceWrapper) =>
	getSleepTimePerDay(
		resourceWrapper.documentSnapshot,
		NUM_LAST_INDICATORS_TO_FETCH
	);

const requestFetchUserDataIndicators = () => ({
	type: REQUEST_FETCH_INDICATORS,
});

const receiveFetchUserDataIndicators = (
	indicators,
	steps,
	bpms,
	bodyTemp,
	spO2,
	sleepTime,
	deviceBattery,
	deviceStatusChangeEvents,
	proximitiesDaily,
	proximitiesHourly
) => ({
	type: RECEIVE_FETCH_INDICATORS,
	indicators: indicators,
	steps: steps,
	bpms: bpms,
	bodyTemp: bodyTemp,
	spO2: spO2,
	sleepTime: sleepTime,
	deviceStatusChangeEvents: deviceStatusChangeEvents,
	deviceBattery: deviceBattery,
	proximitiesDaily: proximitiesDaily,
	proximitiesHourly: proximitiesHourly,
});

const errorFetchUserDataIndicators = (error) => ({
	type: ERROR_FETCH_RESOURCES,
	error,
});

export const getIndicatorValues = (dispatch, resource, limit = 30) => {
	limit = 30;
	resource.ref
		.collection("indicatorValues")
		.where("day", ">=", moment().subtract(limit, "days").toDate())
		.orderBy("day", "desc")
		.get()
		.then((data) => {
			const indicators = data.docs;
			resource.ref
				.collection("stepsDaily")
				.where("day", ">=", moment().subtract(limit, "days").toDate())
				.orderBy("day", "desc")
				.get()
				.then((data) => {
					const steps = data.docs;
					resource.ref
						.collection("bpmsDaily")
						.where("day", ">=", moment().subtract(limit, "days").toDate())
						.orderBy("day", "desc")
						.get()
						.then((data) => {
							const bpms = data.docs;
							resource.ref
								.collection("bodyTempDaily")
								.where("day", ">=", moment().subtract(limit, "days").toDate())
								.orderBy("day", "desc")
								.get()
								.then((data) => {
									const bodyTemp = data.docs;
									resource.ref
										.collection("spO2Daily")
										.where("day", ">=", moment().subtract(limit, "days").toDate())
										.orderBy("day", "desc")
										.get()
										.then((data) => {
											const spO2 = data.docs;
											resource.ref
												.collection("sleepTimeDaily")
												.where("day", ">=", moment().subtract(limit, "days").toDate())
												.orderBy("day", "desc")
												.get()
												.then((data) => {
													const sleepTime = data.docs;
													resource.ref
														.collection("deviceBatteryStatus")
														.where(
															"timestamp",
															">=",
															moment().subtract(limit, "days").toDate()
														)
														.orderBy("timestamp", "desc")
														.get()
														.then((data) => {
															const deviceBattery = data.docs;
															resource.ref
																.collection("deviceStatusChangeEvents")
																.where(
																	"createdAt",
																	">=",
																	moment().subtract(limit, "days").toDate()
																)
																.orderBy("createdAt", "desc")
																.limit(10)
																.get()
																.then((data) => {
																	const deviceStatusChangeEvents = data.docs;
																	resource.ref
																		.collection("proximitiesDaily")
																		.where(
																			"day",
																			">=",
																			moment().subtract(limit, "days").toDate()
																		)
																		.where("day", "<=", moment().toDate())
																		.orderBy("day", "desc")
																		.limit(10)
																		.get()
																		.then((data) => {
																			const proximitiesDaily = data.docs;
																			if (proximitiesDaily && proximitiesDaily[0]) {
																				proximitiesDaily[0].ref
																					.collection("proximitiesHourly")
																					.orderBy("startHour", "asc")
																					.get()
																					.then((data) => {
																						const proximitiesHourly = data.docs;
																						dispatch(
																							receiveFetchUserDataIndicators(
																								indicators,
																								steps,
																								bpms,
																								bodyTemp,
																								spO2,
																								sleepTime,
																								deviceBattery,
																								deviceStatusChangeEvents,
																								proximitiesDaily,
																								proximitiesHourly
																							)
																						);
																					})
																					.catch((error) => {
																						dispatch(
																							errorFetchUserDataIndicators(error)
																						);
																					});
																			} else {
																				dispatch(
																					receiveFetchUserDataIndicators(
																						indicators,
																						steps,
																						bpms,
																						bodyTemp,
																						spO2,
																						sleepTime,
																						deviceBattery,
																						deviceStatusChangeEvents,
																						proximitiesDaily,
																						null
																					)
																				);
																			}
																		})
																		.catch((error) => {
																			dispatch(
																				errorFetchUserDataIndicators(error)
																			);
																		});
																})
																.catch((error) => {
																	dispatch(errorFetchUserDataIndicators(error));
																});
														})
														.catch((error) => {
															dispatch(errorFetchUserDataIndicators(error));
														});
												})
												.catch((error) => {
													dispatch(errorFetchUserDataIndicators(error));
												});
										})
										.catch((error) => {
											dispatch(errorFetchUserDataIndicators(error));
										});
								})
								.catch((error) => {
									dispatch(errorFetchUserDataIndicators(error));
								});
						})
						.catch((error) => {
							dispatch(errorFetchUserDataIndicators(error));
						});
				})
				.catch((error) => {
					dispatch(errorFetchUserDataIndicators(error));
				});
		})
		.catch((error) => {
			dispatch(errorFetchUserDataIndicators(error));
		});

	return requestFetchUserDataIndicators({});
};
