import firebase from "firebase/app";
import seremyFire from "seremy-firebase-data";
import {
	CLEAR_REFERRALS,
	REQUEST_FETCH_ALL_REFERRALS,
	RECEIVE_FETCH_ALL_REFERRALS,
	ERROR_FETCH_ALL_REFERRALS,
	CLEAR_PENDING_REFERRAL,
	SET_PENDING_REFERRAL,
	CLEAR_APPROVED_REFERRAL,
	SET_APPROVED_REFERRAL,
} from "actions/types";

export const clearReferrarls = () => ({
	type: CLEAR_REFERRALS,
});

export const clearPendingReferral = () => ({
	type: CLEAR_PENDING_REFERRAL,
});

export const setPendingReferral = (referral) => ({
	type: SET_PENDING_REFERRAL,
	referral: referral,
});

export const clearApprovedReferral = () => ({
	type: CLEAR_APPROVED_REFERRAL,
});

export const setApprovedReferral = (referral) => ({
	type: SET_APPROVED_REFERRAL,
	referral: referral,
});

export const requestFetchReferrals = () => ({
	type: REQUEST_FETCH_ALL_REFERRALS,
});

export const receiveFetchReferrals = (referrals) => ({
	type: RECEIVE_FETCH_ALL_REFERRALS,
	referrals,
});

export const errorFetchReferrals = (error) => ({
	type: ERROR_FETCH_ALL_REFERRALS,
	error,
});

async function getReferrals(dispatch, account) {
	firebase
		.firestore()
		.collection("referrals")
		.where("sender_id", "==", account.ref)
		.orderBy("createdAt", "desc")
		.onSnapshot(
			(querySnapshot) => {
				dispatch(receiveFetchReferrals(querySnapshot.docs));
			},
			function (error) {
				dispatch(errorFetchReferrals(error));
			}
		);
}

export const resendReferralAction = (referral) => {
	seremyFire.referral.resendReferralAction(referral);
};

export const acceptReferralAction = async (dispatch, referral) => {
	await seremyFire.referral.acceptReferralAction(referral);
	dispatch(clearPendingReferral());
};

export const refuseReferralAction = async (dispatch, referral) => {
	await seremyFire.referral.refuseReferralAction(referral);
	dispatch(clearPendingReferral());
};

export const requestReferrals = (dispatch, account) => {
	getReferrals(dispatch, account);
	return requestFetchReferrals();
};

export const getPendingReferral = async (dispatch, account) => {
	const pendingReferral = await seremyFire.referral.getPendingReferral(account);
	if (pendingReferral) {
		dispatch(setPendingReferral(pendingReferral));
	} else return false;
};

export const getApprovedReferral = async (dispatch, account) => {
	const approvedReferral = await seremyFire.referral.getApprovedReferral(
		account
	);
	if (approvedReferral) {
		dispatch(setApprovedReferral(approvedReferral));
	} else return false;
};

export const updateReferredValue = async (referral, total) => {
	const check = await seremyFire.referral.updateReferredValue(referral, total);
	return check;
};

export const checkCode = async (code, account, price) => {
	const check = await seremyFire.referral.checkCode(code, account, price);
	return check;
};
