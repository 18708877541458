import React from "react";
import Proptypes, { element } from "prop-types";
import HiddenMUI from "@material-ui/core/Hidden";

import { drawerItems } from "config/drawer";
import Strings from "styles/strings";

import { HELP_URL, SITE_URL } from "config";

import logoSeremy from "assets/logo_seremy_full.png";
import { ReactComponent as HelpIcon } from "assets/ic_help.svg";

import Item from "./Item";

import { Nav, Drawer, Group, Header, Footer, LogoLink, Logo } from "./style";

const DrawerComponent = ({
	onDrawerToggle,
	mobileOpen,
	notifications,
	allActiveAlarms,
	common,
}) => {
	const headerToRender = () => (
		<Header>
			<LogoLink href={SITE_URL}>
				<Logo src={logoSeremy} />
			</LogoLink>
		</Header>
	);

	const footerToRender = () => (
		<Footer>
			<Item
				type="external_url"
				text={Strings().drawerHelp}
				icon={HelpIcon}
				to={HELP_URL}
				onClick={onDrawerToggle}
			/>
		</Footer>
	);

	const listToRender = (common) => {
		var items = drawerItems;
		return items.map((section, i) => (
			<Group key={`group-${i}`}>
				{section.map((item) => (
					<Item
						key={item.text}
						text={item.text}
						icon={item.icon}
						to={item.path}
						showBadge={item.showBadge}
						// activeOnlyWhenExact={item.exact}
						onClick={onDrawerToggle}
						notifications={notifications}
						allActiveAlarms={allActiveAlarms}
						badgeValue={
							item.badgeValue == "allActiveAlarms"
								? allActiveAlarms
								: notifications.sentNotifications
						}
					/>
				))}
			</Group>
		));
	};

	const contentToRender = (common) => (
		<>
			{headerToRender()}
			{listToRender(common)}
			{footerToRender()}
		</>
	);

	return (
		<Nav aria-label="Menu">
			<HiddenMUI mdUp implementation="js">
				<Drawer
					key="mobile-drawer"
					variant="temporary"
					anchor="left"
					open={mobileOpen}
					onClose={onDrawerToggle}
					ModalProps={{
						keepMounted: true,
					}}
				>
					{contentToRender(common)}
				</Drawer>
			</HiddenMUI>
			<HiddenMUI smDown implementation="js">
				<Drawer key="desktop-drawer" variant="permanent" open>
					{contentToRender(common)}
				</Drawer>
			</HiddenMUI>
		</Nav>
	);
};

DrawerComponent.propTypes = {
	onDrawerToggle: Proptypes.func.isRequired,
	mobileOpen: Proptypes.bool.isRequired,
	onLogout: Proptypes.func.isRequired,
};

export default DrawerComponent;
