import { SHOP } from 'routes/paths';
import Loadable from "react-loadable";
import Loading from "components/Loading";
import { appendAdditionalParamsIfFound } from "routes/utils";

export const AdditionalServices = Loadable({
	loader: () => import("./AdditionalServices"),
	loading: Loading,
	timeout: 10000,
});

export const DevicesShop = Loadable({
	loader: () => import("./DevicesShop"),
	loading: Loading,
	timeout: 10000,
});

export const DeviceAccessoriesShop = Loadable({
	loader: () => import("./DeviceAccessoriesShop"),
	loading: Loading,
	timeout: 10000,
});

export const URL_CREATION_STEP_PARAM_NAME = "creationStep";
export const URL_PARTNERSHIP_PARAM_NAME = "partnership";
export const URL_PROMO_CODE_PARAM_NAME = "promocode";

export const orderStarterAdditionalParams = [
	URL_PARTNERSHIP_PARAM_NAME,
	URL_PROMO_CODE_PARAM_NAME,
];
/**
 * Crea un path relativo alla visualizzazione del dettaglio di un ordine
 * @param {Object} shopWrapper oggetto wrapper ordine
 * @returns {String} path dettaglio ordine
 */
export const getShopPath = (shopWrapper) => {
	return `${SHOP}/${shopWrapper.id}`};

/**
 * Legge il valore del parametro creationStep dal path corrente
 * @param {Object} location oggetto location react-router
 * @returns {String} valore del creationStep
 */
export const getCreationStepFromPath = (location) => {
	const params = new URLSearchParams(location.search);
	return params.get(URL_CREATION_STEP_PARAM_NAME);
};

export const getNewOrderStarterPath = (location) => {
	//const path = SHOP;
	const path = `${location.pathname}${location.search}`;
	return appendAdditionalParamsIfFound(
		location,
		path,
		orderStarterAdditionalParams
	);
};

/**
 * Crea un path relativo al modifica di un ordine in bozza
 * @param {Object} orderWrapper oggetto wrapper ordine
 * @returns {String} path modifica ordine
 */
export const getOrderStarterPath = (orderWrapper) => {
	return `${SHOP}/${orderWrapper.id}`;
}

    /**
 * Crea un path relativo al modifica di un ordine
 * @param {Object} location oggetto location react-router
 * @param {Object} orderWrapper oggetto wrapper ordine
 * @returns {String} path modifica ordine
 */
export const getOrderPath = (location, orderWrapper) => {
	const path = `${location.pathname}/${orderWrapper.id}?${URL_CREATION_STEP_PARAM_NAME}=${orderWrapper.creationStep}`;
	return appendAdditionalParamsIfFound(
		location,
		path,
		orderStarterAdditionalParams
	);
};

/**
 * Modifica nel path corrente il valore del parametro creationStep
 * @param {Object} location oggetto location react-router
 * @param {String} creationStep step da impostare nel parametro del path
 * @returns {String} path modificata
 */
export const getPath = (location, creationStep) => {
	console.log(location.pathname+" -> "+ creationStep);
	const path = `${location.pathname}?${URL_CREATION_STEP_PARAM_NAME}=${creationStep}`;
	console.log("PATH",path);
	return appendAdditionalParamsIfFound(
		location,
		path,
		orderStarterAdditionalParams
	);
};
