import styled from 'styled-components';

import {
  labelPrimaryTextColorHover,
  labelSecondaryTextColorHover,
} from 'styles/colors';

import theme from 'styles/theme';

export const Label = styled.p`
  ${(props) => (props.type === 'primary' ? theme.labelPrimary : theme.labelSecondary)}
  overflow: hidden;
  text-overflow: ellipsis;

  &.clickable {
    text-decoration: underline;
    cursor: pointer;
  }

  &.clickable:hover {
    color: ${(props) => (props.type === 'primary'
    ? labelPrimaryTextColorHover
    : labelSecondaryTextColorHover)};
  }
`;
