import seremyFire, { models } from 'seremy-firebase-data';

export const { GetStartedTutorialStates, AccountBaseWrapper } = models.account;

/**
 * Crea un oggetto wrapper dell0oggetto document snapshot
 * @param {DocumentSnapshot} account oggetto document snapshot dell'account
 * @returns oggetto accountWrapper
 */
export const getAccountWrapperFromDocumentSnapshot = (account) => models.account.getAccountWrapperFromDocumentSnapshot(seremyFire, account);
