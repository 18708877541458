import {
	REQUEST_FETCH_ALL_INVITATIONS,
	RECEIVE_FETCH_ALL_INVITATIONS,
	ERROR_FETCH_ALL_INVITATIONS,
	SET_PENDING_INVITATION,
	CLEAR_PENDING_INVITATION,
	SET_PROCESSED_INVITATION,
	RESET_INVITATIONS,
	HIDE_INVITATION_LOADER,
	SHOW_INVITATION_LOADER,
} from "../actions/types";

const initialState = {
	invitations: [],
	isFetching: false,
	error: null,
	pendingInvitation: null,
	processedPendingInvitation: null,
	showLoader: false,
};

const beacons = (state = initialState, action) => {
	switch (action.type) {
		case RESET_INVITATIONS:
			return initialState;
		case HIDE_INVITATION_LOADER:
			return {
				...state,
				showLoader: false,
			};
		case SHOW_INVITATION_LOADER:
			return {
				...state,
				showLoader: true,
			};
		case SET_PROCESSED_INVITATION:
			return {
				...state,
				processedPendingInvitation: true,
			};
		case CLEAR_PENDING_INVITATION:
			return {
				...state,
				pendingInvitation: null,
			};
		case SET_PENDING_INVITATION:
			return {
				...state,
				pendingInvitation: action.invitation,
			};
		case REQUEST_FETCH_ALL_INVITATIONS:
			return {
				...state,
				invitations: [],
				isFetching: true,
				error: null,
			};
		case RECEIVE_FETCH_ALL_INVITATIONS:
			return {
				...state,
				invitations: action.invitations,
				isFetching: false,
				error: null,
			};
		case ERROR_FETCH_ALL_INVITATIONS:
			return {
				...state,
				isFetching: false,
				error: action.error,
				invitations: [],
			};
		default:
			return state;
	}
};

export default beacons;
