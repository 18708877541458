import React from "react";
import MobileAppBarComponent from "components/MobileAppBar";
import { AppContainer, ButtonBarContainer, MainContainer } from "./style";

const hideButtonBar = () => {
	// console.log(window.location.href);
	if (window.location.href.match(/new-order-starter-kit/gi)) return true;
	if (window.location.href.match(/login/gi)) return true;
	if (window.location.href.match(/register/gi)) return true;
	return false;
};
const MobileAppContainerComponent = (props) => {
	return (
		<MainContainer>
			<AppContainer>{props.children}</AppContainer>
			{!hideButtonBar() && (
				<ButtonBarContainer>
					<MobileAppBarComponent allActiveAlarms={props.allActiveAlarms} />
				</ButtonBarContainer>
			)}
		</MainContainer>
	);
};

export default MobileAppContainerComponent;
