import firebase from "firebase/app";

export const signupUser = (email, firstName, lastName, phoneNumber) => {
	const httpsCallable = firebase.app()
    .functions("europe-west1")
    .httpsCallable('user-signup');
  let params = {
    email, firstName, from: "web", lastName, phoneNumber
  }
  return httpsCallable(params);
  };

  export const ejectUser = (user) => {
    const httpsCallable = firebase.app()
      .functions("europe-west1")
      .httpsCallable('user-eject');
    let params = {
      user //{ user: ref(user) }
    }
    return httpsCallable(params);
    };