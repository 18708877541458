import { combineReducers } from "redux";
import auth from "./auth";
import userData from "./userData";
import configuration from "./configuration";
import resources from "./resources";
import subscriptions from "./subscriptions";
import wearableDevices from "./wearableDevices";
import userDataIndicators from "./indicators";
import common from "./common";
import alarms from "./alarms";
import proximity from "./proximity";
import beacons from "./beacons";
import payments from "./payments";
import invitations from "./invitations";
import referrals from "./referrals";
import pendingOrder from "./pendingOrder";
import notifications from "./notifications";
import users from "./users";

export default combineReducers({
	auth,
	userData,
	configuration,
	resources,
	subscriptions,
	wearableDevices,
	userDataIndicators,
	common,
	alarms,
	proximity,
	beacons,
	payments,
	invitations,
	referrals,
	pendingOrder,
	notifications,
	users,
});
