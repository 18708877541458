// import "babel-polyfill";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "url-search-params-polyfill";
import "react-app-polyfill/ie11";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import reducers from "reducers";

import Root from "pages/Root";
import "./constants.css";

const composeEnhancers =
	typeof window === "object" &&
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
	process.env.NODE_ENV === "development"
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
		: compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

export const store = createStore(reducers, enhancer);

ReactDOM.render(
	<Provider store={store}>
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<Root />
		</MuiPickersUtilsProvider>
	</Provider>,
	document.getElementById("root")
);
