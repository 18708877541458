import styled from 'styled-components';
import Button from 'components/Button';

export const ButtonLink = styled(Button)`
  text-align: center;
`;

export const Link = styled.a`
  text-align: center;
`;
