import seremyFire from "seremy-firebase-data";

/**
 * Esegue il salvataggio di un log
 * @param {Object} log oggetto con le informazioni da salvare
 * @returns {Promise<DocumentReference>} promise salvataggio log
 */
export const saveLog = (log) => {
	return seremyFire.firebase
		.firestore()
		.collection("logsWeb")
		.add({
			...log,
			createdAt: seremyFire.firebase.firestore.Timestamp.fromDate(new Date()),
		});
};
