import styled from "styled-components";
import Dimens from "styles/dimens";
import LinkButton from "components/LinkButton";
import { ReactComponent as HelpIconBase } from "assets/ic_help.svg";
import { primaryColor } from "styles/colors";

export {
	Topbar,
	HelpLink,
	Container,
	ContentFields,
	ContentForm,
	LogoLink,
	Logo,
	NextButton,
	DrawerContainer,
	PayloadContainer,
	LoginFormContainer,
} from "../../pages/Auth/commonStyles";

export const HekpButton = styled(LinkButton)`
	&& {
		margin-bottom: 70px;
	}
`;

export const HelpIcon = styled(HelpIconBase)`
	margin-right: ${Dimens.marginNormal};
`;
