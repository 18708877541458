/* eslint no-restricted-globals: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import Logger from 'utils/logger';
import Strings from 'styles/strings';

import Message from 'components/Message';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    Logger.error(
      Logger.code.ERROR_BOUNDARY_CATCH,
      `error: ${JSON.stringify(error)} - info: ${JSON.stringify(info)}`,
    );
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return (
        <Message
          message={Strings().messageErrorScreenCatch}
          onActionClick={() => location.reload({ forceGet: true })}
          actionText={Strings().buttonReloadPage}
        />
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
