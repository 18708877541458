export const primaryColor = "#FC385B";
export const accentColor = "#F00B51";
export const accentLightColor = "#FEEEF3";
export const primaryTextColor = "#2E294E";
export const secondaryTextColor = "#8D99AE";
export const whiteTextColor = "#FFFFFF";
export const blackTextColor = "#000000";
export const windowBackgroundColor = "#fff";
export const errorColor = "#F93B37";

export const dividerColor = "#EDF2F4";

export const appBarBackgroundColor = "#fff";
export const mobileAppBarBackgroundColor = "#edf2f4";
export const mobileAppBarBorderColor = "#D8DCDE";

// Drawer
export const drawerItemColor = "#767BA0";
export const drawerItemColorSelected = primaryColor;

// Buttons
export const buttonDisabledBackgroundColor = "#E8E8E8";
export const buttonDisabledTextColor = "#ACACAC";
export const buttonPrimaryBackgroundColorStart = "#DD186A";
export const buttonPrimaryBackgroundColorEnd = "#8558E5";
export const buttonPrimaryTextColor = "#fff";
export const buttonSecondaryBackgroundColor = "#f8fbfd";
export const buttonSecondaryTextColor = primaryColor;
export const buttonGreenBackgroundColor = "#55BABE";
export const buttonGreenHoverBackgroundColor = "#147065";
export const buttonGreenTextColor = "#fff";
export const buttonSimpleBackgroundColor = "#fff";
export const buttonSimpleTextColor = "#767BA0";
// Text Buttons
export const textButtonPrimaryTextColor = primaryColor;
export const textButtonSecondaryTextColor = primaryTextColor;

// Label
export const labelPrimaryTextColorHover = "#0079DB";
export const labelSecondaryTextColorHover = "#4B575A";

// Steps
export const stepBackgroundColor = "#D7E8F5";
export const stepBackgroundColorCompleted = "#55BABE";
export const stepBackgroundColorCurrent = primaryColor;

// Credit card
export const creditCardBackgroundColor = "#f8fbfd";
export const creditCardBorderColor = "#e8e8fb";

// Card
export const cardBackgroundColor = "#fff";
export const cardBorderColor = "#e8e8fb";
export const cardBorderContainerColor = "#c4c4c4";

// Caption
export const captionBackgroundColor = "#f8fbfd";

// Order Status
export const orderStatusDraftColor = "#575757";
export const orderStatusPaymentInProgressColor = "#03a9f4";
export const orderStatusPaidColor = "#2196f3";
export const orderStatusCloseColor = "#9e9e9e";
export const orderStatusAbortedColor = "#f44336";
export const orderStatusCompletedColor = "#4caf50";

export const mobileButtonBarBackgroundColor = "#ffffff";
export const mobileButtonBarBackgroundColorText = "#8D99AE";

export const chartBarBackground = "#f8fbfd";

export const tabulatorAlarmColor = "#ff7043";
export const tabulatorActivityColor = "#6F8BFC";
export const tabulatorCardioColor = "#f06292";
export const tabulatorBodyTempColor = "#f39c12";
export const tabulatorSpO2Color = "#1abc9c";
export const tabulatorSleepColor = "#ffb74d";
export const tabulatorSecurityColor = "#f06292";
export const tabulatorProximityColor = "#ffb74d";

export const beaconInRange = "#44BF2D";
export const beaconOutRange = "#8D99AE";
export const beaconObsolete = "#FFB74D";

export const contactTracing = "#FF6D1F";
export const range1Color = "#f44336";
export const range2Color = "#ffb74d";

export const activityLevelChartColor = "#6F8BFC";
export const dailyStepsColor = "#5166B8";
export const weeklyStepsColor = "#A9B9FC";
export const alarmsListTitle = "#FF6D1F";

export const cardioLevelChartColor = "#f06292";
export const avgBpmChartColor = "#C55178";
export const minBpmChartColor = "#D4809C";
export const maxBpmChartColor = "#993F5D";

export const bodyTempLevelChartColor = "#f39c12";
export const avgBodyTempChartColor = "#d77408";
export const minBodyTempChartColor = "#f9bb26";
export const maxBodyTempChartColor = "#b2510b";

export const spO2LevelChartColor = "#1abc9c";
export const avgSpO2ChartColor = "#11907a";
export const minSpO2ChartColor = "#32cfad";
export const maxSpO2ChartColor = "#127362";

export const securityIndexChartColor = "#f06292";
export const weekProximityLevelChartColor = "#C55178";
export const dayProximityLevelChartColor = "#D4809C";

export const weeklySleepChartColor = "#ffb74d";
export const batteryColor = "#EAEAEA";
export const batteryChargeColor = "#2196F3";

export const deviceStatusUnknown = "#607d8b";
export const deviceStatusBooted = "#2196f3";
export const deviceStatusWristOn = "#44BF2D";
export const deviceStatusWristOff = "#f44336";
export const deviceStatusChargeOn = "#FDD835";
export const deviceStatusChargeOff = "#9195B2";

export const batteryChartGraph = "#A59DFF";

export const semaphoreGreen = "#2dc937";
export const semaphoreYellow = "#e7b416";
export const semaphoreRed = "#cc3232";

export const pieColors = [
	"#6F8BFC",
	"#ffb74d",
	"#f06292",
	"#1976d2",
	"#4caf50",
	"#ffca28",
	"#1B998B",
	"#F44336",
	"#03a9f4",
	"#81c784",
	"#fb8c00",
	"#2196f3",
	"#ffe564",
	"#7366FF",
	"#F00B51",
	"#f4511e",
	"#9bbbff",
	"#ffe97d",
	"#ff94c2",
	"#63a4ff",
	"#80e27e",
	"#fffd61",
	"#5bcabb",
	"#ff7961",
	"#67daff",
	"#b2fab4",
	"#ffbd45",
	"#6ec6ff",
	"#ffff96",
	"#aa94ff",
	"#ff5b7d",
	"#ff844c",
];

export const timeProgressGreen = "#4caf50";
export const timeProgressYellow = "#ffca28";
export const timeProgressOrange = "#fb8c00";
export const timeProgressRed = "#F44336";

export const mapCircleStrokeColor = "rgba(100,151,235,0.95)";
export const mapCircleFillColor = "rgba(100,151,235,0.8)";

export const invitationStatusAccepted = "#4caf50";
export const invitationStatusRejected = "#f44336";
export const invitationStatusPending = "#fb8c00";
export const invitationStatusToSend = "#9e9e9e";

export const referralStatusClosed = "#4caf50";
export const referralStatusRejected = "#f44336";
export const referralStatusOpen = "#fb8c00";
export const referralStatusNew = "#9e9e9e";
export const referralStatusApproved = "#fb8c00";

// export const mapCircleStrokeColor = "#F44336";
// export const mapCircleFillColor = "#F44336";
