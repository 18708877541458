import seremyFire from "seremy-firebase-data";
import "whatwg-fetch";
import Logger from "utils/logger";

/**
 * Richiama la function di firebase che esegue la creazione dell'account
 * e dell'utente
 * @param {Object} userInfo oggetto con le informazioni dell'utente
 * @returns {Promise<Object>} promise chiamata function che ritorna il risultato dell'operazione
 */
export const registerUser = (userInfo) => {
	const httpsCallable = seremyFire.firebase
		.app()
		.functions("europe-west1")
		.httpsCallable("registerUser");
	return httpsCallable(userInfo);
};

/**
 * Esegue la registrazione con numero di telefono
 * @param {String} phoneNumber numero di telefono
 * @param {ApplicationVerifier} appVerifier verifica recaptcha
 * @returns {Promise<ConfirmationResult>} promise risultato signin
 */
export const signinWithPhoneNumber = (phoneNumber, appVerifier) =>
	seremyFire.firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier);

/**
 * Esegue il login con email e password
 * @param {String} email email
 * @param {Strign} password password
 * @returns {Promise<FirebaseUser>} promise login con info utente
 */
export const signInWithEmailAndPassword = (email, password) =>
	seremyFire.firebase.auth().signInWithEmailAndPassword(email, password);

export const differentGetUser = async (firebaseUser) => {
	const userCollection = seremyFire.firebase.firestore().collection("users");
	const firebaseUserUid = firebaseUser.uid;
	if (!firebaseUserUid) {
		Logger.error(
			Logger.code.USER_DATA_ACTIONS_GET_USER,
			"Ricerca firebase user: non trovato UID",
			firebaseUser.uid
		);
	}
	const user = await userCollection
		.where("authId", "==", firebaseUserUid)
		.orderBy("createdAt", "desc")
		.get();
	if (user.empty) {
		console.log("empty");
		Logger.error(
			Logger.code.USER_DATA_ACTIONS_GET_USER,
			"Ricerca firebase user: non trovato",
			firebaseUser.uid
		);
	}
	return user;
};
