export const REQUEST_FETCH_USER_DATA = "REQUEST_FETCH_USER_DATA";
export const RECEIVE_FETCH_USER_DATA = "RECEIVE_FETCH_USER_DATA";
export const ERROR_FETCH_USER_DATA = "ERROR_FETCH_USER_DATA";
export const RESET_USER_DATA = "RESET_USER_DATA";
export const UPDATE_STORED_USER_DATA = "UPDATE_STORED_USER_DATA";

export const REQUEST_FETCH_RESOURCES = "REQUEST_FETCH_RESOURCES";
export const RECEIVE_FETCH_RESOURCES = "RECEIVE_FETCH_RESOURCES";
export const ERROR_FETCH_RESOURCES = "ERROR_FETCH_RESOURCES";
export const RESET_RESOURCES = "RESET_RESOURCES";
export const SET_SELECTED_RESOURCE = "SET_SELECTED_RESOURCE";
export const UPDATE_STORED_RESOURCE = "UPDATE_STORED_RESOURCE";
export const ADD_STORED_RESOURCE = "ADD_STORED_RESOURCE";
export const DELETE_STORED_RESOURCE = "DELETE_STORED_RESOURCE";

export const REQUEST_FETCH_CONFIGURATION = "REQUEST_FETCH_CONFIGURATION";
export const RECEIVE_FETCH_CONFIGURATION = "RECEIVE_FETCH_CONFIGURATION";
export const ERROR_FETCH_CONFIGURATION = "ERROR_FETCH_CONFIGURATION";
export const RESET_CONFIGURATION = "RESET_CONFIGURATION";

export const REQUEST_FETCH_WEARABLE_DEVICES = "REQUEST_FETCH_WEARABLE_DEVICES";
export const RECEIVE_FETCH_WEARABLE_DEVICES = "RECEIVE_FETCH_WEARABLE_DEVICES";
export const ERROR_FETCH_WEARABLE_DEVICES = "ERROR_FETCH_WEARABLE_DEVICES";
export const RESET_WEARABLE_DEVICES = "RESET_WEARABLE_DEVICES";
export const UPDATE_STORED_WERABLE_DEVICES = "UPDATE_STORED_WERABLE_DEVICES";

export const REQUEST_FETCH_SUBSCRIPTIONS = "REQUEST_FETCH_SUBSCRIPTIONS";
export const RECEIVE_FETCH_SUBSCRIPTIONS = "RECEIVE_FETCH_SUBSCRIPTIONS";
export const ERROR_FETCH_SUBSCRIPTIONS = "ERROR_FETCH_SUBSCRIPTIONS";
export const RESET_SUBSCRIPTIONS = "RESET_SUBSCRIPTIONS";
export const UPDATE_STORED_SUBSCRIPTIONS = "UPDATE_STORED_SUBSCRIPTIONS";

export const START_AUTHENTICATION = "START_AUTHENTICATION";
export const AUTHENTICATION_COMPLETED = "AUTHENTICATION_COMPLETED";

export const REQUEST_FETCH_INDICATORS = "REQUEST_FETCH_INDICATORS";
export const RECEIVE_FETCH_INDICATORS = "RECEIVE_FETCH_INDICATORS";
export const ERROR_FETCH_INDICATORS = "ERROR_FETCH_INDICATORS";

export const SET_RESOURCE_TABULATOR = "SET_RESOURCE_TABULATOR";
export const SET_PREV_TABULATOR = "SET_PREV_TABULATOR";

export const REQUEST_FETCH_RESOURCE_ALARMS = "REQUEST_FETCH_RESOURCE_ALARMS";
export const RECEIVE_FETCH_RESOURCE_ALARMS = "RECEIVE_FETCH_RESOURCE_ALARMS";
export const ERROR_FETCH_RESOURCE_ALARMS = "ERROR_FETCH_RESOURCE_ALARMS";
export const UPDATE_FETCH_RESOURCE_ALARMS = "UPDATE_FETCH_RESOURCE_ALARMS";

export const REQUEST_FETCH_ALL_ALARMS = "REQUEST_FETCH_ALL_ALARMS";
export const RECEIVE_FETCH_ALL_ALARMS = "RECEIVE_FETCH_ALL_ALARMS";
export const ERROR_FETCH_ALL_ALARMS = "ERROR_FETCH_ALL_ALARMS";
export const UPDATE_FETCH_ALL_ALARMS = "UPDATE_FETCH_ALL_ALARMS";

export const RECEIVE_ALARMS_SNAPSHOT = "RECEIVE_ALARMS_SNAPSHOT";
export const RECEIVE_FETCH_WEARABLE_DEVICES_SNAPSHOT =
	"RECEIVE_FETCH_WEARABLE_DEVICES_SNAPSHOT";

export const RECEIVE_RESOURCES_INDICATORS = "RECEIVE_RESOURCES_INDICATORS";
export const RECEIVE_DEVICE_BATTERY_STATUSES =
	"RECEIVE_DEVICE_BATTERY_STATUSES";

export const SET_ACCOUNT_PROFILE = "SET_ACCOUNT_PROFILE";
export const CLEAR_ACCOUNT_PROFILE = "CLEAR_ACCOUNT_PROFILE";

export const REQUEST_FETCH_ALL_PROXIMITY = "REQUEST_FETCH_ALL_PROXIMITY";
export const RECEIVE_FETCH_ALL_PROXIMITY = "RECEIVE_FETCH_ALL_PROXIMITY";
export const ERROR_FETCH_ALL_PROXIMITY = "ERROR_FETCH_ALL_PROXIMITY";
export const CLEAR_ALL_PROXIMITY = "CLEAR_ALL_PROXIMITY";

export const REQUEST_FETCH_ALL_BEACON = "REQUEST_FETCH_ALL_BEACON";
export const RECEIVE_FETCH_ALL_BEACON = "RECEIVE_FETCH_ALL_BEACON";
export const ERROR_FETCH_ALL_BEACON = "ERROR_FETCH_ALL_BEACON";
export const SET_SELECTED_BEACON = "SET_SELECTED_BEACON";
export const CLEAR_SELECTED_BEACON = "CLEAR_SELECTED_BEACON";
export const CLEAR_ALL_BEACONS = "CLEAR_ALL_BEACONS";

export const SET_SELECTED_EDIT_RESOURCE = "SET_SELECTED_EDIT_RESOURCE";
export const CLEAR_SELECTED_EDIT_RESOURCE = "CLEAR_SELECTED_EDIT_RESOURCE";

export const SET_SELECTED_SUBSCRIPTION = "SET_SELECTED_SUBSCRIPTION";
export const CLEAR_SELECTED_SUBSCRIPTION = "CLEAR_SELECTED_SUBSCRIPTION";

export const REQUEST_FETCH_ALL_PAYMENTS = "REQUEST_FETCH_ALL_PAYMENTS";
export const RECEIVE_FETCH_ALL_PAYMENTS = "RECEIVE_FETCH_ALL_PAYMENTS";
export const ERROR_FETCH_ALL_PAYMENTS = "ERROR_FETCH_ALL_PAYMENTS";
export const SET_SELECTED_PAYMENTS = "SET_SELECTED_PAYMENTS";
export const CLEAR_SELECTED_PAYMENTS = "CLEAR_SELECTED_PAYMENTS";
export const CLEAR_ALL_PAYMENTS = "CLEAR_ALL_PAYMENTS";

export const REQUEST_FETCH_ALL_INVITATIONS = "REQUEST_FETCH_ALL_INVITATIONS";
export const RECEIVE_FETCH_ALL_INVITATIONS = "RECEIVE_FETCH_ALL_INVITATIONS";
export const ERROR_FETCH_ALL_INVITATIONS = "ERROR_FETCH_ALL_INVITATIONS";
export const SET_PENDING_INVITATION = "SET_PENDING_INVITATION";
export const CLEAR_PENDING_INVITATION = "CLEAR_PENDING_INVITATION";
export const SET_PROCESSED_INVITATION = "SET_PROCESSED_INVITATION";
export const RESET_INVITATIONS = "RESET_INVITATIONS";
export const HIDE_INVITATION_LOADER = "HIDE_INVITATION_LOADER";
export const SHOW_INVITATION_LOADER = "SHOW_INVITATION_LOADER";

export const REQUEST_FETCH_ALL_REFERRALS = "REQUEST_FETCH_ALL_REFERRALS";
export const RECEIVE_FETCH_ALL_REFERRALS = "RECEIVE_FETCH_ALL_REFERRALS";
export const ERROR_FETCH_ALL_REFERRALS = "ERROR_FETCH_ALL_REFERRALS";
export const CLEAR_REFERRALS = "CLEAR_REFERRALS";
export const SET_PENDING_REFERRAL = "SET_PENDING_REFERRAL";
export const CLEAR_PENDING_REFERRAL = "CLEAR_PENDING_REFERRAL";
export const SET_APPROVED_REFERRAL = "SET_APPROVED_REFERRAL";
export const CLEAR_APPROVED_REFERRAL = "CLEAR_APPROVED_REFERRAL";
export const SET_PENDING_ORDER = "SET_PENDING_ORDER";
export const CLEAR_PENDING_ORDER = "CLEAR_PENDING_ORDER";

export const SET_WEB_STRINGS = "SET_WEB_STRINGS";
export const REDIRECT_TO_REGISTER = "REDIRECT_TO_REGISTER";

export const REQUEST_FETCH_ALL_NOTIFICATIONS =
	"REQUEST_FETCH_ALL_NOTIFICATIONS";
export const RECEIVE_FETCH_ALL_NOTIFICATIONS =
	"RECEIVE_FETCH_ALL_NOTIFICATIONS";
export const ERROR_FETCH_ALL_NOTIFICATIONS = "ERROR_FETCH_ALL_NOTIFICATIONS";
export const CLEAR_ALL_NOTIFICATIONS = "CLEAR_ALL_NOTIFICATIONS";

export const CLEAR_NOTIFICATION_SOUND = "CLEAR_NOTIFICATION_SOUND";
export const SET_NOTIFICATION_SOUND = "SET_NOTIFICATION_SOUND";

export const SET_NOTIFICATION_ALERT = "SET_NOTIFICATION_ALERT";
export const CLEAR_NOTIFICATION_ALERT = "CLEAR_NOTIFICATION_ALERT";

export const SET_EXTERNAL_SELECTED_ALARM = "SET_EXTERNAL_SELECTED_ALARM";

export const REQUEST_FETCH_ALL_USERS = "REQUEST_FETCH_ALL_USERS";
export const RECEIVE_FETCH_ALL_USERS = "RECEIVE_FETCH_ALL_USERS";
export const ERROR_FETCH_ALL_USERS = "ERROR_FETCH_ALL_USERS";
export const SET_SELECTED_USERS = "SET_SELECTED_USERS";
export const SET_SELECTED_USER_VISIBILITY = "SET_SELECTED_USER_VISIBILITY";
export const REQUEST_USER_VISIBILITY = "REQUEST_USER_VISIBILITY";
export const CLEAR_SELECTED_USERS = "CLEAR_SELECTED_USERS";
export const CLEAR_ALL_USERS = "CLEAR_ALL_USERS";

export const REQUEST_FETCH_PROVINCES = "REQUEST_FETCH_PROVINCES";
export const RECEIVE_FETCH_PROVINCES = "RECEIVE_FETCH_PROVINCES";
