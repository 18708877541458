import { isNullOrUndefined } from "utils/common";

export const isAuthenticatedAndDataFetched = (state) => {
	const response =
		!isNullOrUndefined(state.userData.user) &&
		!isNullOrUndefined(state.userData.account) &&
		state.configuration.completed &&
		isNullOrUndefined(state.userData.error) &&
		isNullOrUndefined(state.configuration.error);

	return response;
};

export const isFetchingAuthData = (state) =>
	(state.userData.isFetching ||
		state.resources.isFetching ||
		state.wearableDevices.isFetching ||
		state.subscriptions.isFetching ||
		state.configuration.isFetching) &&
	isNullOrUndefined(state.userData.error) &&
	isNullOrUndefined(state.resources.error) &&
	isNullOrUndefined(state.subscriptions.error) &&
	isNullOrUndefined(state.wearableDevices.error) &&
	isNullOrUndefined(state.configuration.error);
