import React, { PureComponent } from "react";
import PageContainer from "components/PageContainerLogin";
import { HELP_URL, SITE_URL } from "config";
import logoSeremy from "assets/logo_seremy_full.png";
import Strings from "styles/strings";
import {
	Topbar,
	HelpLink,
	Container,
	ContentFields,
	LogoLink,
	Logo,
	HekpButton,
	HelpIcon,
} from "./style";

class BrowserNotSupported extends PureComponent {
	render() {
		return (
			<PageContainer>
				{" "}
				<Container>
					<Topbar>
						<HelpLink href={HELP_URL} target="_blank" rel="noreferrer">
							{Strings().buttonNeedHelp}
						</HelpLink>
					</Topbar>
					<ContentFields>
						<LogoLink href={SITE_URL}>
							<Logo src={logoSeremy} />
						</LogoLink>
						<p>{Strings().browserNotSupported}</p>
						<p>{Strings().browserNotSupported_2}</p>
						<p>&nbsp;</p>
						<p>Microsoft Edge</p>
						<p>Google Chrome</p>
						<p>Apple Safari</p>
						<p>Mozilla Firefox</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<HekpButton type="simple" to={HELP_URL} external>
							<HelpIcon />
							{Strings().buttonHelp}
						</HekpButton>
					</ContentFields>
				</Container>
			</PageContainer>
		);
	}
}
export default BrowserNotSupported;
