import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import HiddenMUI from "@material-ui/core/Hidden";
import { isIE } from "react-device-detect";

import { getUserWrapperFromDocumentSnapshot } from "models/user";

import {
	startAuthentication,
	authenticationCompleted,
	subscribeToAuthStateChange,
	signOutAndClearData,
	clearData,
	redirectToRegister,
} from "actions/authActions";
import { fetchUserData } from "actions/userDataActions";
import {
	clearNotificationSound,
	clearNotificationAlert,
	setNotificationSound,
	setNotificationAlert,
} from "actions/notificationsActions";
import * as authSelectors from "selectors/authSelectors";

import DrawerComponent from "components/Drawer";
import PendingInvitationPage from "components/PendingInvitationPage";
import PendingReferralPage from "components/PendingReferralPage";
import DrawerV2Component from "components/DrawerV2";

import CircularProgress from "components/CircularProgress";
import MobileAppContainerComponent from "components/MobileAppContainer";
import BrowserNotSupported from "components/BrowserNotSupported";

import { STRIPE_API_PUBLIC_KEY } from "config";

import {
	routes,
	routesEndUser,
	notAuthRedirect,
	authRedirect,
	authRedirectEndUser,
} from "routes";
import { getPathWithRedirect } from "routes/utils";
import { isNullOrUndefined } from "utils/common";

import { fbTrack } from "utils/tracker";

import {
	AppContainer,
	FlexContainer,
	ContentProgress,
	ConfirmButtonComponent,
} from "./style";
import strings from "styles/strings";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import { LoadScript } from "@react-google-maps/api";
import { GOOGLE_PLACE_API_KEY } from "utils/tracker";
import { extractRedirectToPath } from "routes/utils";
import { simulateMouseClick } from "utils/common";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

// import UIfx from 'uifx'
// import alarmSfx from '../public/assets/sounds/alarm.mp3';
// import alarmSfx from './my-sounds/bell.mp3'

library.add(fas);

const initialState = {
	mobileOpen: false,
	isAuthenticated: null,
};

// const alarm = new UIfx(alarmSfx);
var alarm = new Audio("assets/sounds/alarm.mp3");

const LOAD_SCRIPT_LIBRARIES = ["places"];

const stripePromise = loadStripe(STRIPE_API_PUBLIC_KEY, {
	apiVersion: "2020-08-27",
});

class App extends PureComponent {
	constructor(props) {
		super(props);
		// Non chiamre this.setState() qui!
		this.handleClose = this.handleClose.bind(this);
	}
	state = initialState;
	notificationSound = new Audio("assets/sounds/alarm.mp3");
	// console.log("audio file " + file + "length:" + audioTrack.duration + "  sec.");

	unsubscribeAuthStateChange = null;

	handleClose() {
		const { dispatchClearNotificationAlert } = this.props;
		dispatchClearNotificationAlert();
	}

	addMaximumScaleToMetaViewport = () => {
		const el = document.querySelector('meta[name=viewport]');
	  
		if (el !== null) {
		  let content = el.getAttribute('content');
		  let re = /maximum\-scale=[0-9\.]+/g;
	  
		  if (re.test(content)) {
			  content = content.replace(re, 'maximum-scale=1.0');
		  } else {
			  content = [content, 'maximum-scale=1.0'].join(', ')
		  }
	  
		  el.setAttribute('content', content);
		}
	};

	checkIsIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

	componentDidMount() {
		fbTrack("PageView");
		this.subscribeToAuthChanges();
		if (this.checkIsIOS()) {
			this.addMaximumScaleToMetaViewport();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			isAuthenticating,
			processedInvitation,
			dispatchClearData,
			dispatchFetchUserData,
			history,
			location,
			newAlarms,
			notifications,
			dispatchClearNotificationSound,
			dispatchClearNotificationAlert,
			dispatchSetNotificationAlert,
		} = this.props;

		if (notifications.playNotification) {
			var promise = this.notificationSound.play();
			if (promise !== undefined) {
				promise
					.catch((error) => {
						if (!/Edge/.test(navigator.userAgent))
							dispatchSetNotificationAlert();
						console.log(error);
					})
					.then(() => {
						dispatchClearNotificationSound();
					});
			}
		}
		// Quando l'autenticazione è stata completata viene eseguito
		// il subscribe alle modifiche dell'utente di firebase
		if (prevProps.isAuthenticating && !isAuthenticating) {
			// MM-21052020
			// sposto la redirect qui
			const newPathRedirect = extractRedirectToPath(location);
			console.log("redirect to", newPathRedirect);
			this.props.history.push(newPathRedirect);
		}
		if (!isAuthenticating) {
			this.subscribeToAuthChanges();
		}
		if (processedInvitation) {
			dispatchClearData();
			dispatchFetchUserData();
			history.push("/");
		}
	}

	componentWillUnmount() {
		if (!isNullOrUndefined(this.unsubscribeAuthStateChange)) {
			this.unsubscribeAuthStateChange();
		}
	}

	subscribeToAuthChanges = () => {
		if (isNullOrUndefined(this.unsubscribeAuthStateChange)) {
			this.unsubscribeAuthStateChange = subscribeToAuthStateChange((user) => {
				const { dispatchAuthenticationCompleted, dispatchStartAuthenticating } =
					this.props;
				if (user) {
					console.log(user);
					this.setState({ isAuthenticated: true });
					const { dispatchFetchUserData } = this.props;
					// Impostata che non è neccessaria l'autenticazione
					dispatchAuthenticationCompleted();
					dispatchFetchUserData();
				} else {
					// Unsubscribe cambiamenti di stato utente firebase
					// fino a cquando l'autenticazione non è stata completata
					this.unsubscribeAuthStateChange();
					this.unsubscribeAuthStateChange = null;
					this.setState({ isAuthenticated: false });
					// Imposta neccessaria autenticazione
					dispatchStartAuthenticating();
				}
			});
		}
	};

	handleDrawerToggle = () => {
		this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
	};

	invitationToRender = () => {
		const { pendingInvitation, userData, invitationLoader } = this.props;

		return (
			<PendingInvitationPage
				pendingInvitation={pendingInvitation}
				showLoader={invitationLoader}
				user={userData.user}
			/>
		);
	};

	referralToRender = () => {
		const { pendingReferral, userData } = this.props;

		return (
			<PendingReferralPage
				pendingReferral={pendingReferral}
				user={userData.user}
			/>
		);
	};

	contentToRender = () => {
		const {
			isAuthenticating,
			isFetchingAuthData,
			isAuthenticatedAndDataFetched,
			userData,
			location,
			dispatchLogout,
			redirectToRegister,
			notifications,
			allActiveAlarms,
		} = this.props;
		const { mobileOpen, isAuthenticated } = this.state;
		let isAuthorized = false;

		if (isNullOrUndefined(isAuthenticated)) {
			return (
				<ContentProgress>
					<CircularProgress />
				</ContentProgress>
			);
		}

		// Se necessaria autenticazione continua in modo da visualizzare
		// le schermate di autenticazione (login/registrazione)
		if (!isAuthenticating) {
			if (isFetchingAuthData) {
				return (
					<ContentProgress>
						<CircularProgress />
					</ContentProgress>
				);
			}
			if (isAuthenticatedAndDataFetched) {
				isAuthorized = true;
			}
		}

		var defaultPage = authRedirectEndUser;
		var Drawer = DrawerComponent;
		var appRoutes = routesEndUser;
		if (this.props.common.accountProfile != "") {
			defaultPage = authRedirect;
			Drawer = DrawerV2Component;
			appRoutes = routes;
		}
		var nextPath = isAuthorized
			? defaultPage
			: getPathWithRedirect(location, notAuthRedirect);
		const userWrapper = getUserWrapperFromDocumentSnapshot(
			userData.user,
			userData.account
		);

		if (redirectToRegister) {
			// dispatchRedirectToRegister(false);
			nextPath = "/register";
		}

		// console.log(nextPath);

		return (
			<>
				<Route exact path="/" render={() => <Redirect to={nextPath} />} />
				<LoadScript
					id="script-loader"
					googleMapsApiKey={GOOGLE_PLACE_API_KEY}
					libraries={LOAD_SCRIPT_LIBRARIES}
				>
					<Switch>
						{appRoutes.map((route) => (
							<Route
								key={route.path}
								path={route.templatePath}
								exact={route.exact}
								strict={route.strict}
								render={(routeProps) =>
									isAuthorized === route.settings.needAuth ? (
										<>
											<HiddenMUI smDown implementation="js">
												{route.settings.withDrawer && (
													<Drawer
														onDrawerToggle={this.handleDrawerToggle}
														mobileOpen={mobileOpen}
														userWrapper={userWrapper}
														onLogout={dispatchLogout}
														notifications={notifications}
														allActiveAlarms={allActiveAlarms}
														common={this.props.common}
													/>
												)}
												<route.Component
													{...routeProps}
													onDrawerToggle={this.handleDrawerToggle}
													userWrapper={userWrapper}
												/>
											</HiddenMUI>
											<HiddenMUI mdUp implementation="js">
												{this.props.common.accountProfile != "" ? (
													<MobileAppContainerComponent
														allActiveAlarms={allActiveAlarms}
													>
														<Drawer
															onDrawerToggle={this.handleDrawerToggle}
															mobileOpen={mobileOpen}
															userWrapper={userWrapper}
															onLogout={dispatchLogout}
															notifications={notifications}
															allActiveAlarms={allActiveAlarms}
															common={this.props.common}
														/>
														<route.Component
															{...routeProps}
															onDrawerToggle={this.handleDrawerToggle}
														/>
													</MobileAppContainerComponent>
												) : (
													<React.Fragment>
														<Drawer
															onDrawerToggle={this.handleDrawerToggle}
															mobileOpen={mobileOpen}
															userWrapper={userWrapper}
															onLogout={dispatchLogout}
															common={this.props.common}
														/>
														<route.Component
															{...routeProps}
															onDrawerToggle={this.handleDrawerToggle}
															userWrapper={userWrapper}
														/>
													</React.Fragment>
												)}
											</HiddenMUI>
											<Dialog
												open={notifications.showNotificationAlert}
												onClose={this.handleClose}
												aria-labelledby="form-dialog-title"
											>
												<DialogTitle id="form-dialog-title">
													{strings().audioBlockedTitle}
												</DialogTitle>
												<DialogContent>
													<p>{strings().audioBlockedContent}</p>
													<p>{strings().audioBlockedContentSafari}</p>
												</DialogContent>
												<DialogActions>
													<ConfirmButtonComponent
														color="primary"
														onClick={this.handleClose}
													>
														{strings().dialogContinue}
													</ConfirmButtonComponent>
												</DialogActions>
											</Dialog>
										</>
									) : (
										<Redirect to={nextPath} />
									)
								}
							/>
						))}
					</Switch>
				</LoadScript>
			</>
		);
	};

	render() {
		const { pendingInvitation, pendingReferral } = this.props;

		if (isIE) {
			return (
				<AppContainer>
					<BrowserNotSupported />
				</AppContainer>
			);
		}
		return (
			<Elements stripe={stripePromise}>
				<AppContainer>
					{pendingInvitation && (
						<FlexContainer>{this.invitationToRender()}</FlexContainer>
					)}
					{pendingReferral && (
						<FlexContainer>{this.referralToRender()}</FlexContainer>
					)}
					{!pendingInvitation && !pendingReferral && (
						<FlexContainer>{this.contentToRender()}</FlexContainer>
					)}
				</AppContainer>
			</Elements>
		);
	}
}

App.propTypes = {
	isAuthenticating: PropTypes.bool.isRequired,
	userData: PropTypes.shape({
		user: PropTypes.shape(),
		account: PropTypes.shape(),
	}).isRequired,
	isFetchingAuthData: PropTypes.bool.isRequired,
	isAuthenticatedAndDataFetched: PropTypes.bool.isRequired,
	dispatchFetchUserData: PropTypes.func.isRequired,
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired,
		search: PropTypes.string.isRequired,
	}).isRequired,
	dispatchLogout: PropTypes.func.isRequired,
	dispatchStartAuthenticating: PropTypes.func.isRequired,
	dispatchAuthenticationCompleted: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	isAuthenticating: state.auth.isAuthenticating,
	redirectToRegister: state.auth.redirectToRegister,
	isFetchingAuthData: authSelectors.isFetchingAuthData(state),
	isAuthenticatedAndDataFetched:
		authSelectors.isAuthenticatedAndDataFetched(state),
	userData: state.userData,
	common: state.common,
	pendingInvitation: state.invitations
		? state.invitations.pendingInvitation
		: null,
	processedInvitation: state.invitations
		? state.invitations.processedPendingInvitation
		: false,
	pendingReferral: state.referrals ? state.referrals.pendingReferral : null,
	invitationLoader: state.invitations ? state.invitations.showLoader : false,
	newAlarms: state.alarms.newAlarms,
	allActiveAlarms: state.alarms.allActiveAlarms,
	notifications: state.notifications,
});

const mapDispatchToProps = (dispatch) => ({
	dispatchFetchUserData: () => dispatch(fetchUserData()),
	dispatchClearData: () => dispatch(clearData()),
	dispatchLogout: () => dispatch(signOutAndClearData()),
	dispatchStartAuthenticating: () => dispatch(startAuthentication()),
	dispatchAuthenticationCompleted: () => dispatch(authenticationCompleted()),
	dispatchClearNotificationSound: () => dispatch(clearNotificationSound()),
	dispatchSetNotificationAlert: () => dispatch(setNotificationAlert()),
	dispatchClearNotificationAlert: () => dispatch(clearNotificationAlert()),
	dispatchRedirectToRegister: (value) => dispatch(redirectToRegister(value)),
});

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter
)(App);
