import { isNullOrUndefined } from "./common";
import firebase from "firebase/app";

/**
 * Permette di tracciare un evento tramite Facebook Pixel
 * @param {String} event stringa che identifica l'evento
 * @param {Object} params oggetto con parametri dell'evento
 */
export const fbTrack = (event, params = undefined) => {
	if (process.env.REACT_APP_FIREBASE_PROJECT !== "pro") {
		return;
	}
	if (!isNullOrUndefined(window.fbq)) {
		if (!isNullOrUndefined(params)) {
			window.fbq("track", event, params);
			return;
		}
		window.fbq("track", event);
	}
};

export const gtmTrack = eventName => {
	if (process.env.REACT_APP_FIREBASE_PROJECT !== "pro") {
		return;
	}
	if (!isNullOrUndefined(window.dataLayer)) {
		window.dataLayer.push({ event: eventName });
	}
};

export const GOOGLE_PLACE_API_KEY = String(firebase.app().options.apiKey);
