import {
	REQUEST_FETCH_WEARABLE_DEVICES,
	RECEIVE_FETCH_WEARABLE_DEVICES,
	ERROR_FETCH_WEARABLE_DEVICES,
	RESET_WEARABLE_DEVICES,
	UPDATE_STORED_WERABLE_DEVICES,
	RECEIVE_FETCH_WEARABLE_DEVICES_SNAPSHOT,
} from "../actions/types";

const initialState = {
	isFetching: true,
	wearableDevices: [], // Lista oggetti DocumentSnapshot
	wearableDevicesSnapshot: [], // Lista oggetti DocumentSnapshot
	error: null,
};

const wearableDevices = (state = initialState, action) => {
	switch (action.type) {
		case RECEIVE_FETCH_WEARABLE_DEVICES_SNAPSHOT:
			var wearableDevicesSnapshot = state.wearableDevices;
			action.wearableDevices.forEach((device) => {
				var found = false;
				wearableDevicesSnapshot.forEach((value, index) => {
					if (value.id === device.id) {
						wearableDevicesSnapshot[index] = device;
						found = true;
					}
				});
				if (!found) wearableDevicesSnapshot.push(device);
			});
			const res = {
				...state,
				wearableDevicesSnapshot: wearableDevicesSnapshot,
			};
			return res;

		case REQUEST_FETCH_WEARABLE_DEVICES:
			return {
				...state,
				isFetching: true,
				error: null,
			};
		case RECEIVE_FETCH_WEARABLE_DEVICES:
			return {
				...state,
				isFetching: false,
				wearableDevices: action.wearableDevices,
				error: null,
			};
		case ERROR_FETCH_WEARABLE_DEVICES:
			return {
				...state,
				isFetching: false,
				wearableDevices: [],
				error: action.error,
			};
		case RESET_WEARABLE_DEVICES:
			return initialState;
		case UPDATE_STORED_WERABLE_DEVICES:
			return {
				...state,
				wearableDevices: state.wearableDevices.map((wearable) => {
					const updated = action.wearableDevices.find(
						(updatedWearable) => updatedWearable.id === wearable.id
					);
					if (updated === undefined) {
						return wearable;
					}
					return updated;
				}),
			};
		default:
			return state;
	}
};

export default wearableDevices;
