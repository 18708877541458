import styled from 'styled-components';
import LinkButton from 'components/LinkButton';
import Label from 'components/Label';

import Dimens from 'styles/dimens';

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const TitleLabel = styled(Label)`
  font-size: 6rem;
  color: #e6e6e6;
  font-weight: 700;
  margin-bottom: ${Dimens.marginExtraLarge};
`;

export const DescriptionLabel = styled(Label)`
  margin-bottom: ${Dimens.marginExtraLarge};
`;

export const GoToMainPageButton = styled(LinkButton)``;
