import strings from "styles/strings";

import { ReactComponent as NewOrderStarterIcon } from "assets/ic_new_order_starter.svg";
import { ReactComponent as OrdersIcon } from "assets/icons/orders.svg";
import { ReactComponent as AccessoryIcon } from "assets/ic_devices.svg";

import * as paths from "routes/paths";

export const drawerItems = [
	[
		{
			text: strings().drawerItemNewOrderStarter,
			icon: NewOrderStarterIcon,
			path: "https://store.seremy.it/",//"/new-order-starter-kit?creationStep=device",
			overrideSelectedColor: {
				icon: false,
				text: true,
			},
			exact: true,
			type: "external_url",
		},
		{
			text: strings().drawerItemOrders,
			icon: OrdersIcon,
			path: paths.ORDERS,
			overrideSelectedColor: {
				icon: true,
				text: true,
			},
			exact: true,
		},
		{
			text: strings().appBarTitleShop,
			icon: AccessoryIcon,
			path: "https://store.seremy.it/",//paths.SHOP,
			overrideSelectedColor: {
				icon: true,
				text: true,
			},
			exact: true,
			type: "external_url",
		},
	],
];
